import BaseGuard from "@/router/guards/base";
import AuthenticationManager from "@/utils/authenticationManager/AuthenticationManager";
import {USER_ROLES} from "@/utils/authenticationManager/AuthenticationManager";
import AuthDataHandler from "@/utils/storage/AuthDataHandler";

class ManagerGuard extends BaseGuard {
    constructor() {
        super();
    }

    async handleRouteEnter() {
        if (!AuthenticationManager.providerInstance || !this.checkUserType()) {
            return this.insufficientScope;
        }

        if (AuthenticationManager.isAuthenticated && this.checkUserType()) {
            return new Promise(resolve => resolve(true));
        }

        // The token needs to be refreshed.
        const refreshed = await AuthenticationManager.refreshTokens();
        return refreshed ? true : this.routeOnFailure;
    }

    checkUserType() {
        const activeUser = AuthDataHandler.getUserRole();
        return !!activeUser && (activeUser === USER_ROLES.ADMIN || activeUser === USER_ROLES.MANAGER);
    }

    static getInstance() {
        if (!this.instance) {
            this.instance = new ManagerGuard();
        }
        return this.instance;
    }
}

export default ManagerGuard.getInstance();
