export default class InlineObjectElement {

  /**
   * The id of the inline object.
   * @type {string}
   */
  inlineObjectId;

  /**
   * the text style of the inline object.
   * @type {TextStyle}
   */
  textStyle;

  constructor({inlineObjectId, textStyle}) {
    this.inlineObjectId = inlineObjectId;
    this.textStyle = textStyle;
  }


}
