<template>
<div class="wrapper">
  <p class="title">Geïmporteerde hoofdstukken</p>
  <div class="actions-wrapper">
    <div v-for="item in items" :key="item" class="text-wrapper">
      <img :src="item.icon" class="icon" alt="icon">
      <RouterLink :to="{name: item.route, params: {summaryId: summaryId}}">
        <p class="text">{{item.text}}</p>
      </RouterLink>
    </div>

      <RouterLink :to="{name: ROUTE_NAMES_SUMMARY.PREVIEW, params: summaryId}">
          <DButton button-text="Toon voorbeeld" class="white-button show-example-btn" button-type="button"/>
      </RouterLink>
  </div>
</div>
</template>

<script>
import DButton from "@/components/elements/DButton";
import {ROUTE_NAMES_SUMMARY} from "@/router/modules/summary";

export default {
  name: "SummaryChapterActions",
  props: {
    summaryId: {
      required: true
    }
  },
  components: {DButton},
  setup() {
    const items = [
      {
        icon: require('@/assets/icons/ic_question_orange.svg'),
        text: 'Vragenlijsten beheren',
        route: ROUTE_NAMES_SUMMARY.QUESTION_LIST
      },
      {
        icon: require('@/assets/icons/ic_supplements.svg'),
        text: 'Supplementen selecteren',
        route: ROUTE_NAMES_SUMMARY.SUPPLEMENTS_OVERVIEW
      },
      {
        icon: require('@/assets/icons/ic_fragments.svg'),
        text: 'Gratis fragmenten selecteren',
        route: ROUTE_NAMES_SUMMARY.FREE_FRAGMENTS_OVERVIEW
      }
    ]

    return {
      items,
      ROUTE_NAMES_SUMMARY
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper{

  .title {
    color: var(--main-font-color);
    font-size: rem(20);
    font-weight: bold;
    letter-spacing: 0;
    line-height: rem(25);
    margin: rem(40) 0 rem(9) 0;
  }

  .actions-wrapper {
    display: flex;

    .text-wrapper {
      display: flex;
      align-items: center;
      gap: rem(12);

      .text {
        @include hover-active-pointer;
        color: var(--orange-main);
        font-size: rem(16);
        font-weight: bold;
        letter-spacing: 0;
        line-height: rem(19);
        text-decoration: underline;
        margin: 0 rem(32) 0 0;
      }

      &:nth-child(3) {
        flex-grow: 1;
      }
    }
  }

}
</style>
