export default class ListProperties {


  /**
   * The nesting levels of a list.
   * @type {NestingLevel[]}
   */
  nestingLevels;

  constructor({nestingLevels}) {
    this.nestingLevels = nestingLevels;
  }


}
