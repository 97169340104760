export default class GDocDocument {

  /**
   * The id of the document
   * @type {string}
   */
  documentId;


  /**
   * The title of the document
   * @type {string}
   */
  title;

  /**
   * The body of the document
   * @type {Body}
   */
  body;

  /**
   * The headers of the document
   * @type {Map<String,Header>}
   */
  headers;

  /**
   * The footers of the document
   * @type {Map<String,Footer>}
   */
  footers;

  /**
   * The inline objects of the document
   * @type {Map<String,InlineObject>}
   */
  inlineObjects;


  /**
   * The lists of the document
   * @type {Map<String,List>}
   */
  lists;


  /**
   * The named styles of the document
   * @type {NamedStyle[]}
   */
  namedStyles;

  /**
   * The positioned Objects of the document
   * @type {Map<String,PositionedObject>}
   */
  positionedObjects;

  /**
   * The revision Id of the document#
   * @type {string}
   */
  revisionId;

  /**
   * The suggested view mode of the document
   * @type {string}
   */
  suggestedViewMode;

  constructor({
                documentId,
                title,
                body,
                headers,
                footers,
                inlineObjects,
                lists,
                namedStyles,
                positionedObjects,
                revisionId,
                suggestedViewMode
              }) {
    this.documentId = documentId;
    this.title = title;
    this.body = body;
    this.headers = headers;
    this.footers = footers;
    this.inlineObjects = inlineObjects;
    this.lists = lists;
    this.namedStyles = namedStyles;
    this.positionedObjects = positionedObjects;
    this.revisionId = revisionId;
    this.suggestedViewMode = suggestedViewMode;
  }
    
    /**
     * Get the properties of an object (image).
     * @param {String} objectId the id of the object.
     * @return {EmbeddedObject | undefined}
     */
    getObjectProperties(objectId){
        console.log("getting object properties");
        //Check inline objects first.
        if(this.inlineObjects[objectId]){
            return this.inlineObjects[objectId].inlineObjectProperties.embeddedObject;
        }
        else if(this.positionedObjects[objectId]){
            return this.positionedObjects[objectId].positionedObjectProperties.embeddedObject;
        }
    }
    
    
    /**
     * Runs through the document and sets the bullet points positions
     */
    setBulletPointsPositions(){
        let currentPositions = [0,0,0,0,0,0,0,0];
        let lastListId = null;

        this.body.content.forEach((element, index) => {
            // Loop over the elements in the body
            if (element.paragraph?.bullet) {
                const bullet = element.paragraph.bullet;
                let nestingLevel = bullet.nestingLevel;
                if(nestingLevel == null) nestingLevel = 0;
                // Increment the current position of the bullet
                currentPositions[nestingLevel] += 1;
                // Clear all nesting levels
                if(bullet.listId !== lastListId){
                    for(let i = 0; i < currentPositions.length; i++){
                        currentPositions[i] = 0;
                    }
                }
                lastListId = bullet.listId;            
                // Set the position of the bullet
                if(nestingLevel === 0) {
                    bullet.setPositionInList(currentPositions[nestingLevel] + 1);
                } else {
                    bullet.setPositionInList(currentPositions[nestingLevel]);
                }                
                // Clear all higher nesting levels;
                for(let i = nestingLevel + 1; i < currentPositions.length; i++){
                    currentPositions[i] = 0;
                }
            }
            // Also loop over all elements in cells of tables
            if(element.table?.tableRows) {
                element.table?.tableRows.forEach((row) => {
                    row?.tableCells.forEach((cell) => {
                        cell?.content.forEach((cellEl) => {
                            if(cellEl.paragraph?.bullet) {
                                const bullet = cellEl.paragraph.bullet;
                                let nestingLevel = bullet.nestingLevel;
                                if(nestingLevel == null) nestingLevel = 0;
                                // Increment the current position of the bullet
                                currentPositions[nestingLevel] += 1;
                                // Clear all nesting levels
                                if(bullet.listId !== lastListId){
                                    for(let i = 0; i < currentPositions.length; i++){
                                        currentPositions[i] = 0;
                                    }
                                }
                                lastListId = bullet.listId;            
                                // Set the position of the bullet
                                if(nestingLevel === 0) {
                                    bullet.setPositionInList(currentPositions[nestingLevel] + 1);
                                } else {
                                    bullet.setPositionInList(currentPositions[nestingLevel]);
                                }                
                                // Clear all higher nesting levels;
                                for(let i = nestingLevel + 1; i < currentPositions.length; i++){
                                    currentPositions[i] = 0;
                                }
                            }
                        });
                    });
                });
            }            
        });
    }
}
