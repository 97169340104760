export const KEYS_SORTING = {
    DAY: 'availableDate',
    PUBLICATION_STATUS: 'isPublished',
    BACHELOR_YEAR: 'bachelorYearNumber',
    PRODUCT_CODE: 'productCode',
    TITLE: 'title',
    CREATED_AT: 'createdAt',

    PRODUCT_CODE_FEEDBACK: 'summaryProductCode',
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    EMAIL: 'email',
    ROLE_ID: 'roleId'
};

export const SUMMARY_LIST_SORTING_KEYS = {
    PRODUCT_CODE: 'productCode',
    TITLE: 'title',
    BACHELOR_YEAR_NUMBER: 'bachelorYearNumber',
    AVAILABLE_DATE: 'availableDate',
    IS_PUBLISHED: 'isPublished',
}

export const TYPES_SORTING = {
    ASCENDING: 0,
    DESCENDING: 1,

    ASC: 'ASC',
    DESC: 'DESC'
};

export class SortingOption {
    constructor(fieldKey, direction) {
        this.fieldKey = fieldKey;
        this.direction = direction;
    }
    
    isAscending() {
        return this.direction === TYPES_SORTING.ASCENDING || this.direction === TYPES_SORTING.ASC;
    }
    
    isDescending() {
        return this.direction === TYPES_SORTING.DESCENDING || this.direction === TYPES_SORTING.DESC;
    }
    
    getQueryString(){
        return `&sortBy=${this.fieldKey}&sortOrder=${this.direction}`
    }
}

