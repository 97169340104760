<template>
  <div class="wrapper">
<!--    In case the questionItem has no chapterId-->
    <div v-if="defaultItem" class="question-list-item-wrapper">
      <div :draggable="isDraggable"
           @dragstart="isDraggable ? startDrag($event, defaultItem.id) : null"
           class="question-list">
        <img v-if="isDraggable" src="@/assets/icons/ic_drag_handle.svg" alt="drag-handle" class="drag-handle">
        <img alt="question" class="icon" src="@/assets/icons/ic_question.svg">
        <p class="question-list-title">{{ defaultItem.title }}</p>
      </div>
    </div>

    <div v-else class="question-list-item-wrapper">
      <div v-for="questionList in listQuestionLists"
           :key="questionList.id"
           :draggable="isDraggable"
           @dragstart="isDraggable ? startDrag($event, questionList.id) : null"
           class="question-list">
        <img v-if="isDraggable" src="@/assets/icons/ic_drag_handle.svg" alt="drag-handle" class="drag-handle">
        <img src="@/assets/icons/ic_question.svg" alt="question" class="icon">
        <p class="question-list-title">{{ questionList.title }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChapterListQuestionItem",
  props: {
    listQuestionLists: {
      required: false
    },
    defaultItem: {
      required: false,
      type: Object,
    },
    isDraggable: {
      required: false,
      type: Boolean,
      default: true
    }
  }, emits: ['start-drag', 'checkbox-selected'],
  setup(props, {emit}) {
    function startDrag(event, id) {
      emit('start-drag', event, id);
    }

    function checkboxChecked(id) {
      emit('checkbox-selected', id)
    }

    return {
      startDrag,
      checkboxChecked,
    }
  }
}
</script>

<style lang="scss" scoped>
.question-list-item-wrapper {
  .question-list {
    position: relative;
    @include hover-active-pointer;
    display: flex;
    align-items: center;
    height: rem(42);
    border-radius: rem(6);
    background-color: var(--white-main);
    box-shadow: 0 rem(2) rem(15) rem(-3) var(--white-box-shadow);
    margin-bottom: rem(12);

    .question-list-title {
      color: var(--main-font-color);
      font-size: rem(14);
      font-weight: 500;
      letter-spacing: 0;
      line-height: rem(20);
      margin: 0 0 0 rem(12);
      flex-grow: 1;
    }

    .icon {
      height: rem(16);
      width: rem(16);
      margin-left: rem(13);
    }

    .drag-handle {
      width: rem(18);
      position: absolute;
      left: rem(-26);
    }
  }
}

//CUSTOM STYLES
.cluster-item {
  .question-list {
    margin: 0 0 rem(12) rem(20);
  }
}

.cluster-item-nested {
  .question-list {
    margin: 0 0 rem(12) rem(44);
  }
}
</style>