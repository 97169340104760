<template>
 <CreateOrEditUser button-text="Maak gebruiker aan"
                   title="Nieuwe gebruiker"
                   is-create
                   @create="createUser"/>
</template>

<script>
import {useUserStore} from "@/store/user";
import {useRouter} from "vue-router";
import {ROUTE_NAMES_USERS} from "@/router/modules/users";
import CreateOrEditUser from "@/components/partials/user/CreateOrEditUser";

export default {
  name: "CreateUser",
  components: {
    CreateOrEditUser,
  },
  setup() {
    const userStore = useUserStore();
    const router = useRouter();

    function createUser(formData) {
      userStore.createUser(formData).then((response) => {
        if (response) {
          router.push({name: ROUTE_NAMES_USERS.OVERVIEW});
        }
      });
    }

    return {
      createUser
    }
  }
}
</script>

<style lang="scss" scoped>

</style>