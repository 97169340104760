
export default class TextRun{
  /**
   * The text of the text run.
   */
  content;

  /**
   * The style of the text run.
   */
  textStyle;


  constructor(content, textStyle) {
    this.content = content;
    this.textStyle = textStyle;
  }


}
