export default class EmbeddedObject {

  /**
   * The title of the embedded object.
   * @type {string}
   */
  title;

  /**
   * The description of the embedded object.
   * @type {string}
   */
  description;


  /**
   * The size of the embedded object.
   * @type {Size}
   */
  size;

  /**
   * The top margin of the embedded object.
   * @type {Dimension}
   */
  marginTop;


  /**
   * The bottom margin of the embedded object.
   * @type {Dimension}
   */
  marginBottom;

  /**
   * The left margin of the embedded object.
   * @type {Dimension}
   */
  marginLeft;


  /**
   * The right margin of the embedded object.
   * @type {Dimension}
   */
  marginRight;

  /**
   * The linked content properties of an embedded object.
   * @type {LinkedContentReference}
   */
  linkedContentReference;

  /**
   * The properties of an image.
   * @type {ImageProperties}
   */
  imageProperties;

  constructor({
                title,
                description,
                size,
                marginTop,
                marginBottom,
                marginLeft,
                marginRight,
                linkedContentReference,
                imageProperties
              }) {
    this.title = title;
    this.description = description;
    this.size = size;
    this.marginTop = marginTop;
    this.marginBottom = marginBottom;
    this.marginLeft = marginLeft;
    this.marginRight = marginRight;
    this.linkedContentReference = linkedContentReference;
    this.imageProperties = imageProperties;
  }
    
    
    convertToCssStyleString() {
        //width: ' + this.size.width.magnitude * 2 + 'px !important;
        const imageString = this.imageProperties.convertToStyle();
        const value =  imageString +  'max-height: 800px; aspect-ratio:' + this.size.width.magnitude + "/" + this.size.height.magnitude +  '; display: block !important;  height: ' + this.size.height.magnitude * 2 + 'px ; margin: 0 auto;';
        console.log("made string", value);
        return value;
    }

}
