/**
 * An element of a paragrpah from a google document.
 */
export default class ParagraphElement {


  /**
   * The start index of the element.
   * @type {number}
   */
  startIndex;

  /**
   * The end index of the element.
   * @type {number}
   */
  endIndex;

  /**
   * The text run of the element.
   * @type {TextRun}
   */
  textRun;


  /**
   * The page break of a pargraph.
   * @return {PageBreak}
   */
  pageBreak;

  /**
   * The inlineObjectElement of a paragraph.
   * @return {InlineObjectElement}
   */
  inlineObjectElement;


  /**
   * @type {Equation}
   */
  equation;

  constructor({startIndex, endIndex, textRun, pageBreak, inlineObjectElement, equation}) {
    this.startIndex = startIndex;
    this.endIndex = endIndex;
    this.textRun = textRun;
    this.pageBreak = pageBreak;
    this.inlineObjectElement = inlineObjectElement;
    this.equation = equation;
  }


  get fullText() {
    return this.textRun?.content ?? ''
  }


}
