<template>
  <CreateOrEditUser button-text="Wijzig gegevens"
                    title="Wijzig gegevens"
                    :selected-user="user"
                    has-button
                    additional-button-text="Verwijder gebruiker"
                    @edit="editUser"
                    @delete="openDeleteModal"/>

  <DeleteUserModal :is-visible="isModalVisible" @delete-user="deleteUser" @close="closeActiveModal"/>
</template>

<script>
import {useUserStore} from "@/store/user";
import {useRoute, useRouter} from "vue-router";
import CreateOrEditUser from "@/components/partials/user/CreateOrEditUser";
import {computed} from "vue";
import {ROUTE_NAMES_USERS} from "@/router/modules/users";
import {getModalHelpers} from "@/utils/helpers/ModalHelper";
import {MODAL_DETAILS} from "@/models/ModalNames";
import DeleteUserModal from "@/components/partials/user/DeleteUserModal";
import {useRootStore} from "@/store";

export default {
  name: "EditUser",
  components: {
    DeleteUserModal,
    CreateOrEditUser
    
  },
  setup() {
    const userStore = useUserStore();
    const rootStore = useRootStore();
    const router = useRouter();
    const route = useRoute();
    const userId = route.params.userId;

    const user = computed(() => userStore.getUser);
    getUser();

    function getUser() {
      userStore.getUserById(userId);
    }

    function editUser(formData) {
      userStore.editUser(formData, userId).then((response) => {
        if (response) {
          redirectToOverview();
        }
      })
    }

    /** Modal **/
    const {setActiveModal, closeActiveModal, isModalActive} = getModalHelpers();
    const isModalVisible = computed(() => isModalActive(MODAL_DETAILS.DELETE_USER.id));

    function openDeleteModal() {
      setActiveModal(MODAL_DETAILS.DELETE_USER.id);
    }

    function deleteUser() {
      userStore.deleteUser(userId).then((response) => {
        if (response) {
          closeActiveModal();
          redirectToOverview();
        }
      });
    }

    function redirectToOverview() {
      router.push({name: ROUTE_NAMES_USERS.OVERVIEW});
    }

    /** Breadcrumbs **/
    setBreadCrumbData();

    function setBreadCrumbData() {
      rootStore.setBreadcrumbName(ROUTE_NAMES_USERS.DETAILS);
      rootStore.setBreadcrumbParams({userId: userId});
    }

    return {
      user,
      editUser,
      openDeleteModal,
      isModalVisible,
      closeActiveModal,
      deleteUser
    }
  }
}
</script>

<style scoped lang="scss">

</style>