//A search result contains a paragraph, the first index and end index of where it is found.
export  default class  SearchResult {

  /**
   * The paragraph that contains the search result.
   * @type {StructuralElement} the paragraph that contains the search result.
   */
  paragraph;

  /**
   * The index of the first character of the search result.
   * @type {number} index of first character.
   */
  startIndex;

  /**
   * The index of the last character of the search result.
   * @type {number} index of last character.
   */
  endIndex;

  /**
   * The page that this search result is on.
   * @param paragraph {StructuralElement} the paragraph that contains the search result.
   * @param startIndex {number} the index of the first character of the search result.
   * @param endIndex {number} the index of the last character of the search result.
   */
  constructor(paragraph, startIndex, endIndex){
    this.paragraph = paragraph;
    this.startIndex = startIndex;
    this.endIndex = endIndex;
  }

}
