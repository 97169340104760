<template>
  <div v-if="chapter" class="cluster-item-wrapper">
    <div class="main-chapter" @drop="isDroppable ? onDrop($event, chapter) : null" @dragover.prevent @dragenter.prevent>
      <p class="main-chapter-title">{{ chapter.title }}</p>
      <Checkbox v-if="hasCheckbox" :select="autoSelectCheckbox" @checked="checkboxChecked(chapter)"/>

      <div v-else class="icons-wrapper">
        <img v-if="chapter.isSupplement" alt="icon" class="icon" src="@/assets/icons/ic_supplement_grey.svg"/>
        <img v-if="chapter.isFree" alt="icon" class="icon" src="@/assets/icons/ic_fragment_grey.svg"/>

        <DisplayedIcon :active-src="require('@/assets/icons/ic_lock.svg')"
                       :displayed-src="require('@/assets/icons/ic_unlock.svg')"
                       :is-active="chapter.isLocked === 1"
                       @click="$emit('lock-chapter', chapter.id)"
                       class="lock-icon"/>
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from "@/components/elements/Checkbox";
import {ref, watch} from "vue";
import DisplayedIcon from "@/components/elements/DisplayedIcon";

export default {
  name: "ChapterListItem",
  components: {DisplayedIcon, Checkbox},
  props: {
    chapter: {
      required: true,
      type: Object
    },
    isDroppable: {
      required: false,
      default: true,
      type: Boolean
    },
    hasCheckbox: {
      required: false,
      default: false,
      type: Boolean
    },
    isCheckboxChecked: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  emits: ['on-drop', 'checkbox-selected','lock-chapter'],
  setup(props, {emit}) {
    const autoSelectCheckbox = ref(false);

    function onDrop(event, chapter) {
      emit('on-drop', event, chapter);
    }

    function checkboxChecked(chapter) {
      emit('checkbox-selected', chapter.id)
    }

    function selectCheckbox() {
      autoSelectCheckbox.value = !autoSelectCheckbox.value;
    }

    watch(() => props.isCheckboxChecked, (newVal) => {
      selectCheckbox();
    });

    return {
      onDrop,
      checkboxChecked,
      selectCheckbox,
      autoSelectCheckbox,
    }
  }
}
</script>

<style lang="scss" scoped>
.cluster-item-wrapper {
  .main-chapter {
    display: flex;
    align-items: center;
    height: rem(42);
    border-radius: rem(6);
    background-color: var(--white-main);
    box-shadow: 0 rem(2) rem(15) rem(-3) var(--white-box-shadow);
    margin-bottom: rem(12);

    .main-chapter-title {
      margin: 0 0 0 rem(12);
      color: var(--main-font-color);
      font-size: rem(14);
      font-weight: 500;
      letter-spacing: 0;
      line-height: rem(20);
      flex-grow: 1;
    }

    .icons-wrapper {
      display: flex;
      align-items: center;

      .lock-icon {
        @include hover-active-pointer;
        margin: 0 rem(34) 0 rem(16);
      }

      .icon {
        margin-left: rem(20);
      }
    }
  }
}

//CUSTOM STYLES
.cluster-item {
  .main-chapter {
    margin: 0 0 rem(12) rem(20);

    .main-chapter-title {
      font-weight: initial;
    }
  }
}

.cluster-item-nested {
  .main-chapter {
    margin: 0 0 rem(12) rem(44);

    .main-chapter-title {
      font-style: italic;
      font-weight: initial;
    }
  }
}
</style>