/**
 * A trie node, changed to
 */
export default class TrieNode{
  // the "key" value will be the character in sequence
  key;



  // we keep a reference to parent
  parent = null;

  // we have hash of children
  children = {};

  // check to see if the node is at the end
  end = false;

  // The paragraphs which contain this word.
  paragraphs = [];

  constructor(key){
    this.key = key;
  }

  addParagraph(index){
    this.paragraphs.push(index);
  }

  getWord(){
    let output = [];
    let node = this;

    while (node !== null) {
      output.unshift(node.key);
      node = node.parent;
    }

    return output.join('');
  }
}
