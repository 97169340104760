export default class PageBreak {


  /**
   * The text style of the page break.
   * @type {TextStyle}
   */
  textStyle;


  constructor({textStyle}) {
    this.textStyle = textStyle;
  }


}

