import apiHandler from '@/utils/handlers/ApiHandler'
import errorHandler from "@/utils/handlers/errorHandler/ErrorHandler";
import AuthDataHandler from "@/utils/storage/AuthDataHandler";
import Cookies from "@/utils/storage/Cookies";


export const actions = {
  loginSuccess() {
    apiHandler.setApiType(true);
    this.isAuthenticated = true;
    this.authError = '';
  },
  loginFailure(error) {
    this.isAuthenticated = false;
    this.authError = errorHandler.getError(error.response.data);
  },
  logout() {
    Cookies.destroy();
    AuthDataHandler.clearLocalData();
    apiHandler.setApiType(false);
    this.isAuthenticated = false;
    this.authError = '';
  }
}
