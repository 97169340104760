export const getters = {
    /** Get users list **/
    getUsersList: (state) => {
        console.log("getting users", state.paginationDataResponse ?? []);
        return state.paginationDataResponse?.data ?? [];
    },

    /** Get selected user **/
    getUser: (state) => state.selectedUser,

    /** error **/
    getConflictEmailError: (state) => state.conflictEmailError,
    /** filter **/
    getCurrentUserFilter: (state) => state.currentUserFilter,
    getTotalItemCount: (state) => state.paginationDataResponse?.totalItemCount ?? 0,
    getPaginationDataResponse: (state) => state.paginationDataResponse,
    getUserList: (state) => {
        console.log("getting User", state.paginationDataResponse ?? []);
        return state.paginationDataResponse?.data ?? [];
    },
}