export default class PositionedObjectPositioning {


  /**
   * The layout of this positioned object.
   * @type {string}
   */
  layout;


  /**
   * The left offset of the positioned object.
   * @type {Dimension}
   */
  leftOffset;

  /**
   * The top offset of the positioned object.
   * @type {Dimension}
   */
  rightOffset;

  constructor({layout, leftOffset, rightOffset}) {
    this.layout = layout;
    this.leftOffset = leftOffset;
    this.rightOffset = rightOffset;
  }


}
