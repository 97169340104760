import {useRootStore} from "@/store";
import apiHandler from "@/utils/handlers/ApiHandler";
import {useFeedbackStore} from "@/store/feedback/index";
import PaginatedResponse from "@/models/PaginatedResponse";
import Feedback from "@/models/feedback/Feedback";

export const apiActions = {
    /** Feedback **/
    getFeedback() {
        const store = useFeedbackStore();
        const rootStore = useRootStore();
        
        const currentSearchFilter = store.currentFeedbackFilter;
        
        const page = currentSearchFilter.pageNumber ?? 1;
        const name = currentSearchFilter.searchQuery ?? '';
        
        let searchString = `page=${page}&searchTerm=${name}`;
    
        if (currentSearchFilter.sortingOption) {
            searchString += currentSearchFilter.sortingOption.getQueryString();
        }
    
    
        return apiHandler.get(`feedback/list?${searchString}`).then(response => {
            if (apiHandler.isSuccess(response.status)) {
                const data = PaginatedResponse.parseDataFromApiResponse(response.data,Feedback.parseDataFromObject);
                store.setDataResponse(data);
                return data;
            }
            return false;
        }).catch(error => {
            rootStore.setApiError(error);
            return false;
        });
    },
    getFeedbackById(id) {
        const rootStore = useRootStore();
        const feedbackStore = useFeedbackStore();

        return apiHandler.get(`feedback/${id}`).then(response => {
            if (apiHandler.isSuccess(response.status)) {
                feedbackStore.setSelectedFeedback(response.data);
                return true;
            }
            return false;
        }).catch(error => {
            rootStore.setApiError(error);
            return false;
        });
    },
    replyToFeedback(payload, id){
        const rootStore = useRootStore();
        return apiHandler.post(`feedback/close/${id}`,payload).then(response => {
            if (apiHandler.isSuccess(response.status)) {
                return true;
            }
            return false;
        }).catch(error => {
            rootStore.setApiError(error);
            return false;
        });
    }
}