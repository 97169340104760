import BaseGuard from "@/router/guards/base";
import AuthenticationManager from "@/utils/authenticationManager/AuthenticationManager";
import {USER_ROLES} from "@/utils/authenticationManager/AuthenticationManager";
import AuthDataHandler from "@/utils/storage/AuthDataHandler";

class AdminGuard extends BaseGuard {
  constructor() {
    super();
  }

  async handleRouteEnter() {
    if (!AuthenticationManager.providerInstance || !this.checkUserType()) {
      return this.insufficientScope;
    }

    // User is authenticated AND an admin.
    if (AuthenticationManager.isAuthenticated && this.checkUserType()) {
      return new Promise(resolve => resolve(true));
    }

    // The token needs to be refreshed.
    const refreshed = await AuthenticationManager.refreshTokens();
    return refreshed ? true : this.routeOnFailure;
  }

  checkUserType() {
    const activeUser = AuthDataHandler.getUserRole();
    return !!activeUser && activeUser === USER_ROLES.ADMIN;
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new AdminGuard();
    }
    return this.instance;
  }
}

export default AdminGuard.getInstance();
