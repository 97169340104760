export default class TableCellStyle {

  rowSpan;

  columnSpan;

  /**
   * The background color of the cell
   * @type {OptionalColor}
   */
  backgroundColor;


  /**
   * The left border of the cell
   * @type {Dimension}
   */
  borderLeft;

  /**
   * The right border of the cell
   * @type {Dimension}
   */
  borderRight;

  /**
   * The top border of the cell
   * @type {Dimension}
   */
  borderTop;

  /**
   * The bottom border of the cell
   * @type {Dimension}
   */
  borderBottom;

  /**
   * The left padding of the cell
   * @type {Dimension}
   */
  paddingLeft;

  /**
   * The right padding of the cell
   * @type {Dimension}
   */
  paddingRight;

  /**
   * The top padding of the cell
   * @type {Dimension}
   */
  paddingTop;

  /**
   * The bottom padding of the cell
   * @type {Dimension}
   */
  paddingBottom;

  /**
   * The alignment of the content in the cell
   * @type {String}
   */
  contentAlignment;

  constructor({
                rowSpan,
                columnSpan,
                backgroundColor,
                borderLeft,
                borderRight,
                borderTop,
                borderBottom,
                paddingLeft,
                paddingRight,
                paddingTop,
                paddingBottom,
                contentAlignment
              }) {
    this.rowSpan = rowSpan;
    this.columnSpan = columnSpan;
    this.backgroundColor = backgroundColor;
    this.borderLeft = borderLeft;
    this.borderRight = borderRight;
    this.borderTop = borderTop;
    this.borderBottom = borderBottom;
    this.paddingLeft = paddingLeft;
    this.paddingRight = paddingRight;
    this.paddingTop = paddingTop;
    this.paddingBottom = paddingBottom;
    this.contentAlignment = contentAlignment;
  }


}
