export const ANSWER_OPTIONS = Object.freeze(['A','B','C','D']);

export default class Answer {
  constructor({sortIndex, answerText, isCorrect, answerId}) {
    this.sortIndex = sortIndex;
    this.answerText = answerText;
    this.isCorrect = isCorrect;
    this.answerId = answerId;
  }

  setIsCorrect(value) {
    this.isCorrect = value;
  }

  setAnswerText(value) {
    this.answerText = value;
  }

  static parseDataFromObject(data) {
    return new Answer(data);
  }
}
