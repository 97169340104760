import Summary from "@/models/summary/Summary";
import University from "@/models/summary/University";
import {formatDateIntoDMYHM, formatDateIntoYFMD, formatDateTime} from "@/utils/helpers/Date";

export default class SummaryDetails extends Summary{
    constructor({title, description, university, collegeStartYear, bachelorYearNumber, periods, productCode,
                    googleDocsUrl, isPublished, availableDate, examDate, pdfUrl, syncedAt}) {
        super({id: null,productCode,title,bachelorYearNumber,availableDate,isPublished, periods});
        this.description = description;
        this.university = University.parseDataFromObject(university);
        this.collegeStartYear = collegeStartYear;
        this.periodsString = periods.join(', ')
        this.examDate = examDate;
        this.googleDocsUrl = googleDocsUrl;
        this.pdfUrl = pdfUrl;
        this.syncedAt = syncedAt ? formatDateIntoDMYHM(syncedAt) : '';
    }

    static parseDataFromObject(data) {
        return new SummaryDetails(data);
    }

    setIsPublished(value) {
        this.isPublished = value;
    }

    get getIsPublished() {
        return this.isPublished === 1;
    }
}
