<template>
  <div class="dropdown-wrapper-outer">
    <div class="menu-dropdown-wrapper">
      <!-- Mask - Used to close the panel, if the user clicks outside of it. -->
      <div v-if="isDropdownDisplayed" class="status-dropdown-mask" @click="toggleDropdown"/>

      <div class="menu-dropdown-wrapper-inner" @click="toggleDropdown">
        <DisplayedIcon :active-src="require('../../../assets/icons/ic_clear_active.svg')"
                       :displayed-src="require('../../../assets/icons/ic_more.svg')"
                       :is-active="isDropdownDisplayed"
                       class="filter-icon"/>
      </div>

      <div v-if="isDropdownDisplayed" class="menu-dropdown">
        <div class="dropdown-inner">
          <div v-for="action in actions" :key="action.id" @click="onClickAction(action)" class="action-wrapper">
            <div v-if="action.hasAccess" class="action">
              <img :src="action.imgSrc" alt="action" class="action-icon">
              <p class="action-text">{{ action.text }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, ref} from "vue";
import DisplayedIcon from "@/components/elements/DisplayedIcon";
import AuthenticationManager from "@/utils/authenticationManager/AuthenticationManager";
import {useSummaryStore} from "@/store/summary";

export default {
  name: "DetailActions",
  components: {
    DisplayedIcon
  },
  emits: ['on-click-dropdown-item'],
  setup(props, {emit}) {
    const isDropdownDisplayed = ref(false);
    const summaryStore = useSummaryStore();

    const actions = computed( () => [
      {
        id: 1,
        text: summaryStore.getSelectedSummary.getIsPublished ? 'Publicatie ongedaan maken' : 'Publiceer samenvatting',
        imgSrc: summaryStore.getSelectedSummary.getIsPublished ? require('../../../assets/icons/ic_unpublish.svg') : require('../../../assets/icons/ic_publish.svg'),
        modalName: summaryStore.getSelectedSummary.getIsPublished ? 'unPublishSummary' : 'publishSummary',
        hasAccess: !AuthenticationManager.isStudyHero()
      },
      {
        id: 2,
        text: 'Bekijk gegevens',
        imgSrc: require('../../../assets/icons/ic_search.svg'),
        isEdit: true,
        hasAccess: !AuthenticationManager.isStudyHero()
      },
      {
        id: 3,
        text: 'Download PDF',
        imgSrc: require('../../../assets/icons/ic_download.svg'),
        hasAccess: true
      },
      {
        id: 4,
        text: 'Verwijder samenvatting',
        imgSrc: require('../../../assets/icons/ic_delete.svg'),
        modalName: 'deleteSummary',
        hasAccess: !AuthenticationManager.isStudyHero() && AuthenticationManager.isAdmin()
      }
    ])

    /** Toggle filter dropdown **/
    function toggleDropdown() {
      isDropdownDisplayed.value = !isDropdownDisplayed.value
    }

    function onClickAction(action) {
      toggleDropdown();
      emit('on-click-dropdown-item', action);
    }

    return {
      /** Toggle filter dropdown **/
      isDropdownDisplayed,
      toggleDropdown,
      actions,
      onClickAction
    }
  }
}
</script>

<style lang="scss" scoped>
.dropdown-wrapper-outer {
  position: relative;

  .menu-dropdown-wrapper {

    .status-dropdown-mask {
      @include position-elevation(fixed, $top: 0, $right: 0, $bottom: 0, $left: 0, $elevation: z('dropdown', 'mask'));
      background-color: transparent;

      @include on-hover {
        cursor: default;
      }
    }

    .menu-dropdown-wrapper-inner {
      display: flex;
      align-items: center;
      justify-content: center;
      border: rem(2) solid var(--orange-main);
      height: rem(41);
      width: rem(41);
      border-radius: rem(8);
      background-color: white;

      .filter-icon {
        @include hover-active-pointer;
        width: rem(28);
        height: rem(28);
        z-index: z('dropdown', 'options');
      }
    }

    .menu-dropdown {
      @include position(absolute, $right: 0);
      z-index: z('dropdown', 'options');
      padding-top: rem(15);
      display: inline-block;

      .dropdown-inner {
        border-radius: rem(8);
        background-color: white;
        box-shadow: 0 10px 20px -6px rgba(51, 53, 68, 0.3);
        padding: 0 rem(16);
        width: rem(280);

        .action-wrapper {
          &:not(:last-child) {
            border-bottom: rem(1) solid var(--white-checkbox);
          }

          .action {
            display: flex;
            align-items: center;
            padding: rem(16) 0;
            @include hover-active-pointer;

            .action-text {
              color: var(--main-font-color);
              font-size: rem(16);
              letter-spacing: 0;
              line-height: rem(20);
              margin: 0;
            }

            .action-icon {
              width: rem(24);
              height: rem(24);
              margin-right: rem(16);
            }
          }
        }
        .action-wrapper:nth-child(4) {

          .action-text {
            color: var(--red-modal);
          }
        }
      }
    }
  }
}
</style>
