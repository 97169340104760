<template>
  <div class="text-area-wrapper">
    <p class="label">{{label}}</p>
    <textarea :id="fieldName"
              ref="textArea"
              v-model="input"
              :class="{'has-error': errorMessages.length || hasError}"
              :name="fieldName"
              :placeholder="placeholder"
              :disabled="isReadOnly"
              class="text-area"
              @input="handleInput" />
    <p v-if="customErrorMsg && hasError" class="error-message">{{customErrorMsg}}</p>
    <div v-else>
      <p v-for="errorMessage of errorMessages" :key="errorMessage" class="error-message">{{customErrorMsg ? customErrorMsg : errorMessage}}</p>
    </div>
  </div>
</template>

<script>
import Validator from "@/utils/yo-validator/YoValidator";
import {ref, watch} from "vue";

export default {
  name: "BasicTextField",
  props: {
    type: {
      default: 'text'
    },
    placeholder: {
      required: true,
      type: String
    },
    label: {
      required: false,
      default: '',
      type: String
    },
    fieldName: {
      type: String,
      required: true
    },
    rules: {
      type: String,
      default: ''
    },
    hasError: {
      type: Boolean,
      required: false,
      default: false
    },
    value: {
      required: false
    },
    customErrorMsg: {
      type: String,
      required: false
    },
    isReadOnly: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update-parent'],
  setup(props, {emit}) {
    const {handleInput, field, errorMessages,setFieldData} = Validator.register(props);
    const input = ref('');

    watch(() => props.value, (newVal) => {
      if (newVal) {
        input.value = newVal;
        setFieldData(newVal);
      }
    },{deep:true,immediate:true})

    watch(() => input.value, (newVal) => {
      emit('update-parent', newVal);
    });

    return {
      input,
      errorMessages,
      handleInput,
      field
    }
  }
}
</script>

<style lang="scss" scoped>
.text-area-wrapper {
  .text-area {
    width: 100%;
    height: rem(152);
    border: rem(1) solid var(--border-color-forms);
    border-radius: rem(10);
    background-color: var(--grey-light-02);
    padding: rem(14) rem(16) 0;
    color: var(--main-font-color);
  }

  .has-error {
    border: 1px solid var(--red-error)
  }

  .text-area::placeholder {
    opacity: 0.5;
    color: var(--main-font-color);
    font-size: rem(14);
    letter-spacing: rem(-0.47);
    line-height: rem(22);
  }
}
</style>
