import AccessToken from "@/utils/authenticationManager/tokens/AccessToken";
import RefreshToken from "@/utils/authenticationManager/tokens/RefreshToken";
import AuthenticationProvider from "@/utils/authenticationManager/providers/AuthenticationProvider";
import {useAuthStore} from "@/store/auth";
import Cookies from "@/utils/storage/Cookies";
import AuthDataHandler from "@/utils/storage/AuthDataHandler";
import apiHandler from "@/utils/handlers/ApiHandler";
import AuthenticationManager from "@/utils/authenticationManager/AuthenticationManager";

export class EmailPasswordProvider extends AuthenticationProvider {
  constructor() {
    super();
  }

  /**
   * @param formData - The form data, e.g. email, password.
   * @returns {Promise<boolean>} - Whether the user was successfully logged in or not.
   */
  async authenticate(formData) {
    const authStore = useAuthStore();
    const loggedIn = await authStore.sendLogin(formData);
    if (loggedIn) {
      await this.setTokens(loggedIn);
    }
    return new Promise((resolve) => {
      resolve(!!loggedIn);
    })
  }

  async signOut() {
    const authStore = useAuthStore();
    Cookies.destroy();
    AuthDataHandler.clearLocalData();
    authStore.logout();
  }

  async refreshAccessToken() {
    if (!RefreshToken.value) {
      return false;
    }

    let formData = new FormData();
    formData.append('grant_type', 'refresh_token');
    formData.append('refresh_token', RefreshToken.value);
    formData.append('client_id', process.env.VUE_APP_AUTH_CLIENT_ID);
    formData.append('client_secret', '');

    try {
      // use native fetch to get new tokens
      let response = await fetch(`${process.env.VUE_APP_API_URL}/api/auth/login`, {
        method: 'POST',
        withCredentials: true,
        body: new URLSearchParams(formData),
      });
      if (response) {
        const data = await response.json();
        await this.setTokens(data);
        apiHandler.setApiType(true);
        AuthenticationManager.setAuthenticated();

        return new Promise((resolve) => {
          setTimeout(() => resolve(true), 500);//  tiny delay for database token saving
        });
      }
      console.error(response);
    } catch (error) {
      console.error(error);
    }
  }

  setTokens(data) {
    return new Promise((resolve) => {
      const {access_token, refresh_token, expires_in} = data;
      AccessToken.set(access_token, expires_in);
      RefreshToken.set(refresh_token, 3600 * 24 * 14);
      resolve(true);
    })
  }

  get authenticatedUserType() {
    return '';
    // return authDataHandler.getUserRole();
  }
}
