export default class SummaryChapter {
    constructor({id, gDocsChapterId, isFree, isLocked, isSupplement, parentChapterId, sortIndex, subChapters, summaryId, title}) {
        this.id = id;
        this.gDocsChapterId = gDocsChapterId;
        this.isFree = isFree;
        this.isLocked = isLocked;
        this.isSupplement = isSupplement;
        this.parentChapterId = parentChapterId;
        this.sortIndex = sortIndex;
        this.subChapters = subChapters;
        this.summaryId = summaryId;
        this.title = title;
    }

    get hasCluster() {
        return this.subChapters.length > 0;
    }

    findDeepChapterById(id,includeSelf = true){
        if(includeSelf && this.id === id){
            return this;
        }

        for(let i = 0; i < this.subChapters.length; i++){
            const chapter = this.subChapters[i].findDeepChapterById(id,true);
            if(chapter){
                return chapter;
            }
        }
        return null;
    }


    static parseDataFromObject(data) {
        return new SummaryChapter(data);
    }
}
