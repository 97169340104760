<template>
  <p class="label">{{ label }}</p>
  <div class="basic-date-picker">
    <input :id="fieldName"
           ref="dateInput"
           v-model="input"
           :class="{'has-error':  errorMessages?.length}"
           :name="fieldName"
           :placeholder="placeholder"
           class="picker-input"
           readonly
           type="text"
           @focus="showDatePicker"/>
    <DisplayedIcon :active-src="require('../../assets/icons/ic_dropdown_up.svg')"
                   :displayed-src="require('../../assets/icons/ic_dropdown.svg')"
                   :is-active="datepicker.show"
                   class="icon"/>
    <p v-for="errorMessage of errorMessages" :key="errorMessage" class="error-message">{{ errorMessage }}</p>
    <div v-if="datepicker.show" class="picker__mask" @click="close"></div>
    <div v-if="datepicker.show" class="picker__frame">
      <div class="picker__warp">
        <div class="picker__box">
          <div class="picker__header">
            <div class="picker__month">
              {{ locale.months[datepicker.month - 1] }}
            </div>
            <div class="picker__year">
              {{ datepicker.year }}
            </div>
            <div class="picker__nav--prev" @click="prevMonth">
              <img alt="" src="../../assets/icons/ic_arrow_left.svg"/>
            </div>
            <div class="picker__nav--next" @click="nextMonth">
              <img alt="" src="../../assets/icons/ic_arrow_right.svg"/>
            </div>
          </div>
          <table class="picker__table">
            <thead>
            <tr>
              <th
                  v-for="(val, i) in locale.weekday"
                  :key="i"
                  :class="{ picker__weekend: i == 0 || i == 6 }"
                  class="picker__weekday"
              >
                {{ val }}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(row, i) in datepicker.days" :key="i">
              <td v-for="(val, j) in row" :key="j" role="presentation">
                <div
                    :class="{
                      'picker__day--outfocus': val.month != datepicker.month,
                      'picker__day--infocus': val.month == datepicker.month,
                      'picker__day--today': val.isToday,
                      'picker__day--selected picker__day--highlighted':
                        selectedValue == val.dateString,
                    }"
                    class="picker__day"
                    @click="select(val.dateString)"
                >
                  {{ val.day }}
                </div>
              </td>
            </tr>
            </tbody>
          </table>
          <div class="picker__footer">
            <button
                class="picker__button--today"
                type="button"
                @click="selectToday"
            >
              {{ locale.todayBtn }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref, reactive, computed, watch} from "vue";
import DisplayedIcon from "@/components/elements/DisplayedIcon";
import Validator from "@/utils/yo-validator/YoValidator";
import {formatDateIntoYFMD, formatDateIntoYMD} from "@/utils/helpers/Date";

export default {
  name: "BasicDatePicker",
  components: {DisplayedIcon},
  props: {
    placeholder: {
      type: String,
    },
    valueAttr: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      required: false
    },
    fieldName: {
      type: String,
      required: false
    },
    rules: {
      type: String,
      required: false,
      default: 'required'
    },
    locale: {
      type: Object,
      default: () => {
        return {
          format: "{1}-{2}-{3}",
          weekday: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
          months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
          todayBtn: "Today",
          clearBtn: "Clear",
          closeBtn: "Close",
        };
      },
    },
    isReadOnly: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const {field, errorMessages, setFieldData} = Validator.register(props);
    const input = ref('');

    let dateFormatRex = /^(0?[1-9]|[12][0-9]|3[01])[-](0?[1-9]|1[012])[-]\d{4}$/;
    const getDateString = (dateObj) => {
      let yyyy = dateObj.getFullYear();

      let mm = dateObj.getMonth() + 1;
      if (mm < 10) {
        mm = "0" + mm;
      }
      let dd = dateObj.getDate();
      if (dd < 10) {
        dd = "0" + dd;
      }

      return yyyy + "/" + mm + "/" + dd;
    };
    const selectedValue = ref("");
    const datepicker = reactive({
      show: false,
      year: 2023,
      years: computed(() => {
        let yearList = [];
        for (let i = datepicker.year - 10; i < datepicker.year + 10; i++) {
          yearList.push(i);
        }
        return yearList;
      }),
      month: 1,
      monthList: computed(() => {
        let result = [];
        for (let i = 1; i <= 12; i++) {
          result.push(i);
        }
        return result;
      }),
      days: computed(() => {
        let year = parseInt(datepicker.year);
        let month = datepicker.month;
        let startDate = new Date(year + "/" + month + "/1");
        let lastDate = new Date(year, month, 0);
        let startDateWeekday = startDate.getDay();
        let lastDateWeekday = lastDate.getDay();
        if (startDateWeekday != 0) {
          startDate.setDate(startDate.getDate() - startDateWeekday);
        }
        if (lastDateWeekday != 6) {
          let padding = 6 - lastDateWeekday;
          lastDate.setDate(lastDate.getDate() + padding);
        }
        let days = [];
        let row = [];
        let today = getDateString(new Date(), true);
        let isDisabled = false;
        while (startDate.getTime() - lastDate.getTime() <= 0) {
          isDisabled = false;
          let yyyy = parseInt(startDate.getFullYear());
          let mm = startDate.getMonth() + 1;
          let dd = startDate.getDate();

          let dateObj = {
            year: yyyy,
            month: mm,
            day: dd,
            weekday: startDate.getDay(),
            dateString: getDateString(startDate, true),
            isToday: getDateString(startDate, true) == today,
            isDisabled: isDisabled,
          };
          row.push(dateObj);
          if (row.length >= 7) {
            days.push(row);
            row = [];
          }
          startDate.setDate(startDate.getDate() + 1);
        }
        return days;
      }),
    });

    function formatIntoYMD(dt) {
      // Format the date into yyyy/mm/dd.
      const splitDate = dt.split("-");
      return new Date(`${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`);
    }

    const dateInput = ref(null);
    watch(selectedValue, (value, prevValue) => {
      if (value != "") {
        let result = "";
        // Format the date into yyyy/mm/dd.
        const formattedDate = formatIntoYMD(value);
        if (dateFormatRex.test(value)) {
          result = getDateString(formattedDate, false);
        } else {
          result = prevValue;
        }
        input.value = formatDateIntoYFMD(value);
        selectedValue.value = result;
      }
    });
    watch(() => datepicker.show, (state) => {
      if (state) {
        let thisMonth = new Date();
        if (selectedValue.value) {
          thisMonth = new Date(formatIntoYMD(selectedValue.value));
        }
        datepicker.year = thisMonth.getFullYear();
        datepicker.month = thisMonth.getMonth() + 1;
      }
    });

    const prevMonth = () => {
      let tempPrevYear = datepicker.month == 1 ? datepicker.year - 1 : datepicker.year;
      let tempPrevMonth = datepicker.month == 1 ? 12 : datepicker.month - 1;

      datepicker.year = tempPrevYear;
      datepicker.month = tempPrevMonth;
    };

    const nextMonth = () => {
      let tempNextYear = datepicker.month == 12 ? datepicker.year + 1 : datepicker.year;
      let tempNextMonth = datepicker.month == 12 ? 1 : datepicker.month + 1;

      datepicker.year = tempNextYear;
      datepicker.month = tempNextMonth;
    };
    const selectToday = () => {
      let today = new Date();
      let tempYear = parseInt(today.getFullYear());
      let tempMonth = today.getMonth() + 1;

      datepicker.year = tempYear;
      datepicker.month = tempMonth;
      datepicker.show = false;
      select(getDateString(today, true));
    };
    const clear = () => {
      selectedValue.value = "";
      datepicker.show = false;
    };
    const close = () => {
      datepicker.show = false;
    };
    const select = (value) => {
      selectedValue.value = value;
      datepicker.show = false;
      if (selectedValue.value !== '') {
        setFieldData(formatDateIntoYMD(selectedValue.value));
        input.value = formatDateIntoYFMD(value);
      }
    };

    function showDatePicker() {
      if (!props.isReadOnly) {
        datepicker.show = true;
      }
    }

    setValueOnCreate();

    function setValueOnCreate() {
      if (props.valueAttr){
        select(props.valueAttr);
      }
    }

    return {
      selectedValue,
      datepicker,
      prevMonth,
      nextMonth,
      selectToday,
      select,
      clear,
      close,
      dateInput,
      errorMessages,
      field,
      input,
      showDatePicker
    };
  },
}
</script>

<style lang="scss" scoped>
.basic-date-picker {
  position: relative;
  @include hover-active-pointer;
  font-family: var(--open-sans);

  .has-error {
    border: rem(1) solid var(--red-error)
  }

  .picker-input {
    @include hover-active-pointer;
    color: var(--main-font-color);
  }

  .icon {
    position: absolute;
    right: rem(12);
    top: rem(8);
    width: rem(30);
    height: rem(30);
  }

  .error-message {
    position: absolute;
  }
}

.picker__mask {
  width: 100%;
  height: 100%;
  position: fixed;
  background: #ffffff00;
  z-index: z('dropdown', 'mask');
  top: 0;
  left: 0;
}

.picker__frame {
  position: relative;
  z-index: z('dropdown', 'options');
  width: 100%;
  margin-top: rem(8);
}

.picker__warp {
  border: 1px solid #ABACAD;
  background-color: white;
  border-radius: 8px;
  box-shadow: 2px 4px 8px 0 rgba(70, 70, 70, 0.25);
}

.picker__box {
  padding: 20px;
}

.picker__header {
  position: relative;
  padding-bottom: 10px;
}

.picker__header,
.picker__table {
  text-align: center;
}

.picker__table {
  width: 100%;
}

.picker__footer {
  margin-top: 23px;
  text-align: end;
}

.picker__month,
.picker__year {
  font-weight: 500;
  display: inline-block;
  margin-left: 0.25em;
  margin-right: 0.25em;
}

.picker__nav--next,
.picker__nav--prev {
  position: absolute;
  padding: 0.5em 1.25em;
  box-sizing: content-box;
  top: -0.25em;

  img {
    width: 20px;
    height: 20px;
  }
}

.picker__nav--prev {
  left: -1em;
  padding-right: 1.25em;
}

.picker__nav--next {
  right: -1em;
  padding-left: 1.25em;
}

.picker__nav--next:before {
  border-right: 0;
  border-left: 0.75em solid #000;
}

.picker__nav--next:hover,
.picker__nav--prev:hover {
  cursor: pointer;
  background: #F4F6F8;
}

.picker__day--highlighted,
.picker__day--highlighted:hover,
.picker__day--infocus:hover,
.picker__day--outfocus:hover {
  color: white;
  cursor: pointer;
  background: #383F4F;
}

.picker__day--outfocus {
  color: #ddd;
}

.picker__day--selected,
.picker__day--selected,
.picker__day--selected:hover {
  border: 1px solid #E65541;
  border-radius: 2px;
  color: #E65541;
  font-weight: bold;
}

.picker__day--selected:hover {
  border: none;
  color: white;
}

.picker__day--highlighted.picker__day--disabled,
.picker__day--highlighted.picker__day--disabled:hover {
  background: #bbb;
}

// Weekday
.picker__weekday {
  color: #B3B3B3;
  font-size: 10px;
  font-weight: bold;
  padding-bottom: 0.25em;
  text-transform: uppercase;
  width: 14.285714286%;
}

.picker__button--today {
  background-color: white;
  border: 1px solid #E65541;
  border-radius: 4px;
  color: #E65541;
  height: 21px;
  font-size: 12px;
  font-weight: bold;
  display: inline-block;
  vertical-align: bottom;
  width: 58px;
}

.picker__button--today:hover {
  cursor: pointer;
  color: white;
  background: #FF6800;
  border-color: #FF6800;
}

.picker__button--today:focus {
  background: #FF6800;
  border-color: #FF6800;
  color: white;
  outline: 0;
}

.picker__button--today:before {
  position: relative;
  display: inline-block;
  height: 0;
}

.picker__button--today[disabled],
.picker__button--today[disabled]:hover {
  background: #f5f5f5;
  border-color: #f5f5f5;
  color: #ddd;
  cursor: default;
}

.picker__button--today[disabled]:before {
  border-top-color: #aaa;
}

.picker__day {
  padding: 2px 6px;
}

.picker__day--today {
  background: #383F4F;
  border-radius: 2px;
  color: #fff;
  font-weight: bold;
  position: relative;
}
</style>
