export default class WeightedFontFamily {

  /**
   * The font family of the text run.
   */
  fontFamily;

  /**
   * The weight of the font family.
   * @type {number}
   */
  weight;

  constructor(fontFamily, weight) {
    this.fontFamily = fontFamily;
    this.weight = weight;
  }


}
