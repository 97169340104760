export default class PositionedObject {

  objectId;

  /**
   * The position object properties of this object.
   * @type {PositionedObjectProperties}
   */

  positionedObjectProperties;

  constructor({objectId, positionedObjectProperties}) {
    this.objectId = objectId;
    this.positionedObjectProperties = positionedObjectProperties;
  }


}
