import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import {createPinia} from "pinia";
import apiHandler from "./utils/handlers/ApiHandler";

const app = createApp(App);
app.use(router).use(createPinia()).mount('#app');
/** Set up the api interceptors **/
apiHandler.setInterceptors();
