import Chapter from "@/reader/model/summary/content/Chapter";
import Tree from "@/reader/model/tree/tree";
import PageDivider from "@/reader/model/summary/content/PageDivider";
import Trie from "@/reader/model/trie/Trie";
import SearchResult from "@/reader/model/summary/search/SearchResult";
import CommandTag from "@/reader/model/summary/content/tags/CommandTag";
import CommandTagManager from "@/reader/model/summary/content/tags/CommandTagManager";


/**
 * An annotated document takes in a document object, and annotates it with the correct chapters/ pages/ etc.
 */
export default class AnnotatedDocument {
  /**
   *  @type {Chapter[]}
   */
  chapters = [];

  /**
   * A tree of the chapters
   * @type {Tree[]}
   */
  chapterTree = [];

  /**
   * the paragraphs divided into pages.
   * @type {PageDivider | null}
   */
  pageDivider = null;


  /**
   * The flattened structural elements of the document.
   * Removing need for recursive searching
   * @type {AnnotatedStructuralElement[]}
   */
  flattenedAllStructuralElements = [];

  get pages() {
    return this.pageDivider?.pages;
  }

  /**
   * The structural elements of the document.
   * @return {AnnotatedStructuralElement[]}
   */
  get topLevelStructuralElements() {
    return this.document.body.content;
  }

  get flattenedStructuralElements() {
    return this.flattenedAllStructuralElements;
  }




  /**
   * The indexed paragraph from the document.
   * @param index
   * @return {AnnotatedStructuralElement}
   */
  structuralElementFromIndex(index){
    return this.flattenedAllStructuralElements[index - 1];
  }




  /**
   * A Trie object with all the text indexed in each paragraph.
   * @type {Trie}
   */
  searchTrie = null;


  /**
   * The detail summary for this content;
   * @type {Summary}
   */
  detailSummary = null;

  /**
   * The document to annotate;
   * @type {GDocDocument}
   */
  document;

  /**
   * The command tags in the document.
   * @type {CommandTagManager}
   */
  commandTagManager;

  /**
   *
   * @param {GDocDocument} document
   */
  constructor({document}) {
    this.document = document;
    this.document.setBulletPointsPositions();
  }


  setDetailSummary(detailSummary) {
    this.detailSummary = detailSummary;
    if (this.detailSummary) {
      this.generateChapters();
      //this.generateHtmlContent();
    }
  }


  /**
   * Generates the chapters for the document.
   */
  generateChapters() {
    let chapterId = '';
    let paragraphIndex = 0;
    let structuresNeedingAChapter = [];


    const labelAndIndexElementWithChapter = (structuralElement) => {
      structuralElement.setIndex(++paragraphIndex);
      this.flattenedAllStructuralElements.push(structuralElement);

      if(structuralElement.table){
        structuralElement.table.tableRows.forEach(tableRow => {
          tableRow.tableCells.forEach(tableCell => {
            tableCell.content.forEach(structuralElement => {
              labelAndIndexElementWithChapter(structuralElement);
            })
          })
        });
      }if(structuralElement.tableOfContents){
        structuralElement.tableOfContents.content.forEach(structuralElement => {
          labelAndIndexElementWithChapter(structuralElement);
        });
      }

      if(this.chapters.length > 0){
        structuralElement.setContainingChapter(this.chapters.slice(-1)[0]);
        structuresNeedingAChapter.forEach(structuralElement => {
          structuralElement.setContainingChapter(this.chapters.slice(-1)[0]);
        });
        structuresNeedingAChapter = [];
      }else{
        structuresNeedingAChapter.push(structuralElement);
      }
    }


    this.document.body.content.forEach(structuralElement => {

      if(structuralElement.paragraph){

        //Check if we've got a new heading Id.
        const paragraph = structuralElement.paragraph;
        const paragraphStyle = paragraph.paragraphStyle;
        // Inner text of paragraph.
        const fullParagraphText = structuralElement.fullText;


        const headingObject = this.getHeadingObject(paragraphStyle);


        if(paragraphStyle.headingId  && paragraph.getHeadingTag() !== "div"){ //We are starting a new chapter.
          const newChapterId = paragraphStyle.headingId;
          const newChapter = new Chapter(paragraphStyle.headingId, fullParagraphText, headingObject?.priority ?? -1, structuralElement.startIndex, structuralElement.endIndex);
          if(newChapter.isValid()){
            this.chapters.push(newChapter);
          }
        }

      }



      labelAndIndexElementWithChapter(structuralElement);
    })

    this.removeDrawings();
    this.cleanChapters();
    this.cleanChapterText();
    this.createChapterTree();
    this.extractCommandsFromTheDocument();

    this.searchTrie = this.generateSearchTrie(3);
    this.generatePages();
  }

  /**
   * Generate a indexed trie based on all the text in the paragraphs.
   * @param {number} maxDepth
   */
  generateSearchTrie(maxDepth = 3) {
    const trie = new Trie(maxDepth);
    this.document.body.content.forEach(structuralElement => {
      let paragraph = structuralElement.paragraph;
      if(paragraph == null){
        return;
      }
      let textToAdd = paragraph.fullText;
      textToAdd = textToAdd.toLowerCase();
      for (let i = 0; i < textToAdd.length - maxDepth; i++) {
        const word = textToAdd.substring(i, i + maxDepth);
        trie.insert(word, structuralElement);
      }
    });
    return trie;
  }



  /**
   * Search the document for a word.
   * @param {String} searchWord the search to find.
   * @returns {Array<SearchResult>} the results of the search.
   */
  search(searchWord) {
    const structuralElements = this.searchTrieForWord(searchWord);
    const results = [];
    structuralElements.forEach(structuralElement => {
      const toCheck = structuralElement.fullText.toLowerCase();
      let index = -1;
      while ((index = toCheck.indexOf(searchWord, index + 1)) > -1) {
        const searchResult = new SearchResult(structuralElement, index, index + searchWord.length);
        results.push(searchResult);
      }

    });

    return results;
  }


  /**
   * Iterate through the paragraphs and extract any commands that are in the document.
   */
  extractCommandsFromTheDocument(){
    const openCommands = [];
    const commands = [];
    this.document.body.content.forEach(structuralElement => {
      if(structuralElement.paragraph){
        const text = structuralElement.paragraph.fullText;
        //add to all open commands.
        openCommands.forEach(openCommand => {
          openCommand.text += text;
        });
        const startCommand = CommandTag.getCommandTagIfExists(text);
        const endCommands = CommandTag.testStringContainsEndCommandTag(text);
        if(startCommand){
          openCommands.push({
            command: CommandTag.extractCommandNameFromTag(startCommand),
            startIndex: structuralElement.index,
            text: ''
          });
        }if(endCommands.length > 0){
          endCommands.forEach(endCommand => {
            const endTag = endCommand[0];
            const endTagIndex = endCommand.index;
            const command = CommandTag.extractCommandNameFromTag(endTag);
            const openCommand = openCommands.find(openCommand => openCommand.command === command);
            if(openCommand){
              const commandTag = new CommandTag({
                command: command,
                startIndex: openCommand
                .startIndex,
                endIndex: structuralElement
                .index,
                argument: openCommand
                .text + text.substring(0, endTagIndex)
              });
              commands.push(commandTag);
              openCommands.splice(openCommands.indexOf(openCommand), 1);
            }
          })
        }
      }
    });
    this.commandTagManager = new CommandTagManager({commandTags: commands});
    console.log("Created command tags: ", this.commandTagManager);
  }

  /**
   * Given a word, find where in the tree it can be found.
   * @param {string} query
   * @returns {Array<AnnotatedStructuralElement>} the elements that contain the word.
   */
  searchTrieForWord(query) {
    if (this.searchTrie == null) {
      this.searchTrie = this.generateSearchTrie(3);
    }
    let trieSearch = query.toLowerCase();
    if (trieSearch.length > this.searchTrie.maxDepth) {
      trieSearch = trieSearch.substring(0, this.searchTrie.maxDepth);
    }
    const node = this.searchTrie.getNode(trieSearch);
    if (node == null) {
      return [];
    } else {
      return [...new Set(node.paragraphs)];
    }
  }


  /**
   * Remove all the chapters that are either a new line or a bold tag from the chapters list.
   * Also trims any leftovers.
   */
  cleanChapters() {
    this.chapters = this.chapters.filter(chapter => !chapter.title.trim().toLowerCase().includes(CommandTag.hideChapterTag));
    this.chapters = this.chapters.filter(chapter => chapter.title.trim() !== '<br>' && chapter.title.trim() !== '');
    this.chapters.forEach(chapter => chapter.title = chapter.title.replace(/(<([^>]+)>)/gi, ""));
    this.chapters.forEach(chapter => chapter.titletur = chapter.title.trim());
    this.chapters = this.chapters.filter(chapter => !chapter.title.trim().toLowerCase().includes("inhoudsopgave"));
    this.chapters = this.chapters.filter(chapter => !chapter.title.trim().toLowerCase().includes("table of contents"));
    //remove any html tags from the chapter titles.
  }
  
  cleanChapterText() {
    this.document.body.content.forEach(structuralElement => {
      if (structuralElement.paragraph !== null) {
        structuralElement.paragraph.cleanContent();
      }
      
      if (structuralElement.table !== null) {
        structuralElement.table.tableRows.forEach(tableRow => {
          tableRow.tableCells.forEach(tableCell => {
            tableCell.content.forEach(structuralElement => {
              if (structuralElement.paragraph !== null) {
                structuralElement.paragraph.cleanContent();
              }
            });
          });
        });
      }
      
      if (structuralElement.tableOfContents !== null) {
        structuralElement.tableOfContents.content.forEach(structuralElement => {
          if (structuralElement.paragraph !== null) {
            structuralElement.paragraph.cleanContent();
          }
        });
      }
    });
  }


  /**
   * Take the current chapters and convert them to a tree structure based on their priority.
   */
  createChapterTree() {
    const currentHighestChapters = [];
    this.chapterTree = [];

    for (let i = 0; i < this.chapters.length; i++) {
      const currentChapter = this.chapters[i];
      const chapterTreeDepth = currentChapter.priority - 1;
      const newTree = new Tree(currentChapter, null, []);

      if (currentHighestChapters.length < chapterTreeDepth) {
        currentHighestChapters.push(newTree);
      } else {
        currentHighestChapters[chapterTreeDepth] = newTree;
      }

      //now find if it has a parent, and add as required.
      if (chapterTreeDepth > 0) {
        const parent = currentHighestChapters[chapterTreeDepth - 1];
        parent.addChild(newTree);
      } else {
        this.chapterTree.push(newTree);
      }
    }
  }

  /**
   * Generate the pages, and use this generation to set on which page each chapter is found.
   * If your screen resolution changes or text size changes, you must regenerate the pages to get the correct page numbers.
   * @param containerElement the element that contains the text content in it.
   * @param scrollWrapperElement The element that is scrollable.
   * @param {Summary} summary  The summary of the document, used to get the images.
   */
  generatePages() {
    //Clear all chapters found page.
    this.chapters.forEach(chapter => chapter.setStartPage(null));
    this.pageDivider = new PageDivider(this.topLevelStructuralElements);
    return this.pageDivider.calculatePages(this);
  }


  defaultFontSize = 10;


  getHeadingObject(paragraphStyle) {
    return Headings[Object.keys(Headings).find(headingKey => Headings[headingKey].name === paragraphStyle.namedStyleType)];
  }

  /**
   * Given the question lists and summary chapters, match the question lists to the chapters.
   * @param {QuestionListDetails[]} questionListDetails
   * @param {SummaryChapter[]} summaryChaptersFlattened list, flattened so you don't have to search the sub chapters.
   */
  matchQuestionListsToChapters(questionListDetails, summaryChaptersFlattened) {

    for (let questionListDetail of questionListDetails) {
      const matchingChapterSummary = summaryChaptersFlattened.find((chapterSummary) => chapterSummary.id === questionListDetail.chapterId);
      if (matchingChapterSummary != null) {
        const relevantChapterIndex = this.chapters.findIndex((chapter) => chapter.id() === matchingChapterSummary.gDocsChapterId);
        if (relevantChapterIndex !== -1) {
          const relevantChapter = this.chapters[relevantChapterIndex];
          relevantChapter.setQuestionListDetails(questionListDetail);
          //Add the question chapter to the list of chapters.
          this.chapters.splice(relevantChapterIndex + 1, 0, questionListDetail.chapter);
        }
      }
    }
    this.createChapterTree();
  }

  removeDrawings() {
    this.topLevelStructuralElements.forEach(structuralElement => {
      if(structuralElement.paragraph){
        structuralElement.paragraph.elements.forEach(element => {
          if(element.inlineObjectElement){
            //check it exists in the document.
            /**  @type {InlineObject} */
            const inlineObject = this.document.inlineObjects[element.inlineObjectElement.inlineObjectId];

            if(!inlineObject || !inlineObject.inlineObjectProperties.embeddedObject.imageProperties){
              element.inlineObjectElement = null;
            }
          }
        });
      }
    });
  }
}

const Headings = Object.freeze({
  H1: {
    name: 'HEADING_1',
    priority: 1,
    generateTag: (innerHtml, styling) => `<h1 style="${styling}">${innerHtml}</h1>`
  },
  H2: {
    name: 'HEADING_2',
    priority: 2,
    generateTag: (innerHtml, styling) => `<h2 style="${styling}">${innerHtml}</h2>`
  },
  H3: {
    name: 'HEADING_3',
    priority: 3,
    generateTag: (innerHtml, styling) => `<h3 style="${styling}">${innerHtml}</h3>`
  },
  H4: {
    name: 'HEADING_4',
    priority: 4,
    generateTag: (innerHtml, styling) => `<h4 style="${styling}">${innerHtml}</h4>`
  }
});

