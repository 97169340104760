import BaseGuard from "@/router/guards/base";
import AuthenticationManager from "@/utils/authenticationManager/AuthenticationManager";

class AuthGuard extends BaseGuard {
  constructor() {
    super();
  }

  async handleRouteEnter() {
    if (AuthenticationManager.isAuthenticated) {
      return new Promise(resolve => resolve(true));
    }

    if (!AuthenticationManager.providerInstance) {
      return this.routeOnFailure;
    }

    const refreshed = await AuthenticationManager.refreshTokens();
    return refreshed ? true : this.routeOnFailure;
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new AuthGuard();
    }
    return this.instance;
  }
}

export default AuthGuard.getInstance();
