<template>
  <router-view/>

  <!-- Loading indicator -->
  <LoadingSpinner v-if="!hideLoadingSpinner" :is-loading="isLoading"/>

  <!-- Toasts -->
  <ToastManager/>
</template>

<script>
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import {useRootStore} from "@/store";
import {useRoute} from "vue-router";
import {computed} from "vue";
import ToastManager from "@/components/toast/ToastManager";

export default {
  components: {
    LoadingSpinner,
    ToastManager
  },
  setup() {
    const store = useRootStore();
    const route = useRoute();

    /** Loading indicator **/
    const isLoading = computed(() => store.appIsLoading);
    const hideLoadingSpinner = computed(() => route.meta.hideLoadingSpinner);

    return {
      /** Loading indicator **/
      isLoading,
      hideLoadingSpinner
    }
  }
}
</script>

<style lang="scss">
</style>
