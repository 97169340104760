import {ERROR_CODES} from "@/utils/handlers/errorHandler/errorCodes";
import ErrorBase from "@/utils/handlers/errorHandler/errorList/ErrorBase";

export default class FailedSummaryFetch extends ErrorBase {
    constructor(error, message) {
        super(error, message);
        this.code = ERROR_CODES.SUMMARY_FETCH_FAILED;
        this.message = {
            nl: 'Deze productcode wordt niet herkend',
        }
    }
}
