import errorHandler from "@/utils/handlers/errorHandler/ErrorHandler";

export const actions = {
  /** Loading spinner **/
  setLoading(isLoading) {
    this.isLoading = isLoading;
  },

  /** API Error **/
  setApiError(error) {
    this.apiError = errorHandler.getError(error?.response?.data)
  },

  /** Sorting **/
  setSortingOptions(sortingOptions) {
    this.sortingOptions = sortingOptions;
  },

  /** Pagination **/
  setPaginationData(data) {
    this.paginationData = data;
  },

  resetPaginationData() {
    this.paginationData = null;
  },

  /** Breadcrumbs **/
  setBreadcrumbName(name) {
    this.breadcrumbName = name;
  },

  setBreadcrumbParams(name) {
    this.breadcrumbParams = name;
  },

  resetBreadcrumbName() {
    this.breadcrumbName = null;
    this.breadcrumbParams = null;
  },

  /** Modal **/
  setActiveModal(modalName) {
    this.activeModal = modalName;
  },

  closeActiveModal() {
    this.activeModal = '';
  }
}
