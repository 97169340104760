import {defineStore} from 'pinia'
import {actions} from './actions';
import {apiActions} from './apiActions';
import {getters} from './getters';

const state = () => ({
  /**
   * The current content being read.
   * @type {SummaryContent}
   */
  content: null,
  /**
   * The current page being read.
   * @type {number}
   */
  currentPageIndex: 1,

  searchString: null,

  /**
   * The current results from searching
   * @type {SearchResult[]}
   */
  searchResults: [],

  /**
   * The notes in the document
   * @type {Note[]}
   */
  notes : [],
  /**
   * The currently viewed details summary.
   * @type {Summary}
   */
  detailSummary : null,


  /**
   * The loaded Question List for this book.
   * @type {QuestionListDetails[]}
   */
  loadedQuestionList : [],

  /**
   * The summary chapters for this book.
   * @type {SummaryChapter[]}
   */
  summaryChapters : [],
  /**
   * The selected search result which will be temporarily highlighted.
   */
  selectedSearchResult: null
});

export const useContentStore = defineStore('content', {
  state,
  getters,
  actions: {...actions, ...apiActions}
})
