import {ERROR_CODES} from '../errorCodes'
import ErrorBase from './ErrorBase'

export default class ConflictEmail extends ErrorBase {
    constructor(error, message) {
        super(error, message);
        this.code = ERROR_CODES.CONFLICT_EMAIL_IN_USE;
        this.message = {
            nl: 'Dit e-mailadres is al in gebruik',
        }
    }
}
