/**
 * A chapter in the summary.
 */
import TreeItem from "@/reader/model/tree/tree";

export default class Chapter extends TreeItem{
  /**
   * The page that this content is displayed on.
   * @type {number | null}
   */
  startPage;

  /**
   * the end page of this chapter.
   * @type {number | null}
   */
  endPage;

  /**
   * The matching question list details if one exists.
   * @type {QuestionListDetails | null}
   */
  questionList;

  /**
   * @param {String} chapterId
   * @param {String} title
   * @param {number} priority;
   * @param {number} startIndex
   * @param {number} endIndex
   */
  constructor(chapterId, title,priority,startIndex,endIndex) {
    super();
    this.chapterId = chapterId;
    this.title = title;
    this.priority = priority;
    this.startIndex = startIndex;
    this.endIndex = endIndex;
  }

  /**
   * Set the starting page of this chapter, where the device should start reading from.
   * @param {number | null} pageIndex the index of this page.
   */
  setStartPage(pageIndex){
    this.startPage = pageIndex;
  }

  /**
   * Set the end page of this chapter.
   * @param {number | null} pageIndex the index of this page.
   */
  setEndPage(pageIndex){
    this.endPage = pageIndex;
  }

  /**
   * Set the question list details for this chapter.
   * @param {QuestionListDetails} questionList
   */
  setQuestionListDetails(questionList){
    if(questionList.chapter){
      questionList.chapter.priority = this.priority;
    }
    this.questionList = questionList;
  }

  /**
   * @override
   * @returns {String} id of the item.
   */
  id() {
    return this.chapterId;
  }

  /**
   * Validate that the chapter is valid, and not an accidental pickup.
   * @returns {boolean}
   */
  isValid(){
    const trimmedTitle = this.title.replace(/(<([^>]+)>)/gi, "").trim();
    return trimmedTitle !== '';
  }
}
