export default class Page{

  /**
   * The page number of this page.
   * @type {number}
   */
  index;

  /**
   * If the page is a question page, then it is built of a list of question details.
   * @type {QuestionListDetails | null}
   */
  questionList;

  /**
   * The chapter this page is in.
   * If the chapter changes on this page, then we give it as the last chapter displayed on this page.
   * @type {Chapter} The chapter on this page.
   */
  containingChapter;


  /**
   * Paragraphs on this page.
   * @type {AnnotatedStructuralElement[]}
   */
  annotatedStructuralElements = [];

  /**
   * Create a new page in the book.
   * @param {number} index
   * @param {HTMLElement} content
   * @param {Chapter} containingChapter
   * @param {AnnotatedStructuralElement[]} paragraphs
   * @param {QuestionListDetails | null} questionList
   */
  constructor(index, containingChapter,annotatedStructuralElements,questionList = null){
    this.index = index;
    this.containingChapter = containingChapter;
    this.annotatedStructuralElements = annotatedStructuralElements;
    this.questionList = questionList;
    this.removeStartBreaks();
  }

  /**
   *
   * @param {Chapter} chapter
   */
  setContainingChapter(chapter){
    this.containingChapter = chapter;
  }

  /**
   * Avoid having whitespace on top of the page
   */
  removeStartBreaks() {
    let firstNonBreak = false;
    this.annotatedStructuralElements.forEach(element => {
      if (firstNonBreak) {
        return;
      }
      if (!element.paragraph?.elements.every(element => element.textRun?.content === '\n')) {
        firstNonBreak = true;
        return;
      }

      element.paragraph.elements.forEach(element => {
        element.textRun.content = '';
      });
    });
  }

  /**
   * estimate height
   * @param {number | null} clientWidth
   * @param {AnnotatedDocument} fullDocument
   * @return {number}
   */
  estimateHeight(clientWidth,fullDocument){
    let height = 0;
    this.annotatedStructuralElements.forEach(annotatedStructuralElement => {
      height += annotatedStructuralElement.estimateHeight(clientWidth,fullDocument);
    })
    return height;
  }
}
