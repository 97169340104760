import {actions} from './actions';
import {getters} from './getters';
import {apiActions} from './apiActions';
import {defineStore} from "pinia";
import UsersTableFilter from "@/models/tables/UserTableFilter";

export const state = () => ({
    /** Users **/
    selectedUser: null,
    conflictEmailError: null,
    
    /** @type {FeedbackTableFilter} */
    currentUserFilter: UsersTableFilter.empty(),
    
    /**
     * The data returned from the API call, will contain feedback items
     * @type {PaginationData}
     * **/
    paginationDataResponse: null,
});

export const useUserStore = defineStore('user', {
    state,
    getters,
    actions: {...actions, ...apiActions}
});
