import {formatDateIntoDMYHM} from "@/utils/helpers/Date";

export const feedbackDetailNames = [
    {
        value: 'Samenvatting',
        id: 'summary.title'
    },
    {
        value: 'Productcode',
        id: 'summary.productCode'
    },
    {
        value: 'Hoofdstuk',
        id: 'chapterTitle'
    },
    {
        value: 'Geselecteerde tekst',
        id: 'selectedText'
    },
    {
        value: 'Feedback',
        id: 'feedbackText'
    },
    {
        value: 'Datum',
        id: 'createdAt'
    },
    {
        value: 'Naam gebruiker',
        id: 'user.name'
    },
    {
        value: 'E-mailadres',
        id: 'user.email'
    },
    {
        value: 'Status',
        id: 'status'
    }
]

export function displayStatusMsg(selectedFeedback){
    if (selectedFeedback.isFeedbackRepliedTo){
        return`Beantwoord op ${formatDateIntoDMYHM(selectedFeedback.repliedAt)} door ${selectedFeedback.closedByUser.email}`
    }
    return 'Niet beantwoord'
}