<template>
  <div class="auth-wrapper" v-if="!confirmationSent">
    <h2 class="title-text">Wachtwoord vergeten?</h2>
    <p>Voer je E-mail adres in om een link te ontvangen om je wachtwoord opnieuw in te stellen.</p>
    <FormWrapper :send-form-data="validateForm">
      <template #form-content>
        <BasicInputField label="E-mailadres"
                         placeholder="Voer je e-mailadres in"
                         field-name="email"
                         rules="required"
                         type="email"/>
      </template>

      <template #button-submit>
        <DButton button-type="submit"
                 button-text="Verstuur link"/>
      </template>
    </FormWrapper>
    <DLink :router-link="{name: ROUTE_NAMES_AUTH.LOGIN}"
           text="Ga terug naar inloggen"
           route-active-class="d-link-orange"
           class="back-login"/>
  </div>

  <div class="auth-wrapper confirmation-wrapper-text" v-else>
    <ForgotPasswordConfirmation :entered-email="enteredEmail"/>
  </div>
</template>

<script>
import BasicInputField from "@/components/yo-form/BasicInputField";
import FormWrapper from "@/components/yo-form/FormWrapper";
import DButton from "@/components/elements/DButton";
import {ROUTE_NAMES_AUTH} from "@/router/modules/auth";
import {ref} from "vue";
import ForgotPasswordConfirmation from "@/components/partials/auth/ForgotPasswordConfirmation";
import DLink from "@/components/elements/basicUI/DLink";
import {useAuthStore} from "@/store/auth";

export default {
  name: "ForgotPassword",
  components: {
    DLink,
    ForgotPasswordConfirmation,
    DButton,
    FormWrapper,
    BasicInputField,
  },
  setup() {
    const confirmationSent = ref(false);
    const enteredEmail = ref('');
    const authStore = useAuthStore();

    function validateForm(formData){
      enteredEmail.value = formData.email;

      authStore.sendForgotPassword({email: formData.email}).then((response) => {
        if (response) {
          confirmationSent.value = true;
        }
      })
    }

    return {
      ROUTE_NAMES_AUTH,
      validateForm,
      enteredEmail,
      confirmationSent,
    }
  }
}
</script>
<style scoped lang="scss">
.title-text {
  margin-bottom: rem(16);
}

.input-wrapper {
  margin: rem(24) 0 rem(82);
}

.back-login{
  display: flex;
  justify-content: center;
  margin-top: rem(32);
}

.confirmation-wrapper-text {
  padding-bottom: rem(89);
}
</style>