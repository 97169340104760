export const getters = {
    /** summaries **/
    getSummaries: (state) => {
        console.log("getting summaries", state.paginationDataResponse ?? []);
        return state.paginationDataResponse?.data ?? [];
    },

    getSelectedSummary: (state) => state.selectedSummary,

    getChapters: (state) => state.summaryChapters,

    /** universities **/
    getUniversities: (state) => state.universities,
    
    /** filter **/
    getCurrentListFilter: (state) => state.currentListFilter,
    
    getTotalItemCount: (state) => state.paginationDataResponse?.totalItemCount ?? 0,
    getPaginationDataResponse: (state) => state.paginationDataResponse,
}