<template>
  <div class="details-info-wrapper">
    <p class="details-info-title">Details</p>
    <div v-for="name in detailNames" :key="name.id" class="details-title-text">
      <p class="detail-names">
        {{ name.value }}
      </p>
      <p v-if="selectedSummary" class="detail-values" @click="isEqual(name.id, 'googleDocsUrl') ? isHyperlink(name.id) : null">
        <DisplayedIcon v-if="isEqual(name.id, 'isPublished')"
                       :active-src="require('../../../assets/icons/ic_published.svg')"
                       :displayed-src="require('../../../assets/icons/ic_unpublished.svg')"
                       :is-active="selectedSummary.getIsPublished"
                       class="img"/>
        {{ displayDetailsValue(name.id) }}
      </p>
    </div>
  </div>
</template>

<script>
import DisplayedIcon from "@/components/elements/DisplayedIcon";
import {formatDateIntoYFMD} from "@/utils/helpers/Date";
import {detailNames, formatCollegeYear} from "@/utils/helpers/DetailsHelper";
import {ref, watch} from "vue";

export default {
  name: "SummaryDetailsInfo",
  components: {
    DisplayedIcon
  },
  props: {
    summary: {
      required: true,
    }
  },
  setup(props) {
    const selectedSummary = ref(null);

    watch(() => props.summary, (newVal) => {
      selectedSummary.value = newVal;
    },{deep:true})

    function displayDetailsValue(id) {
      if (isEqual(id, 'examDate') || isEqual(id, 'availableDate')) {
        return formatDateIntoYFMD(selectedSummary.value[id]);
      }
      if (isEqual(id, 'isPublished')) {
        return selectedSummary.value.getIsPublished ? 'Gepubliceerd' : 'Niet gepubliceerd';
      }
      if (isEqual(id, 'collegeStartYear')) {
        return formatCollegeYear(selectedSummary.value[id], selectedSummary.value.bachelorYearNumber);
      }
      if (isEqual(id, 'university')) {
        return selectedSummary.value[id].name;
      }
      if (isEqual(id, 'periods')) {
        return selectedSummary.value.periodsString;
      }
      return selectedSummary.value[id] ? selectedSummary.value[id] : ' ';
    }

    function isEqual(id, name) {
      return id === name;
    }

    function isHyperlink(id) {
      window.open(selectedSummary.value[id],'popUpWindow');
    }

    return {
      detailNames,
      displayDetailsValue,
      selectedSummary,
      isHyperlink,
      isEqual
    }
  }
}
</script>

<style scoped lang="scss">
.details-info-wrapper {
  margin-top: rem(43);

  .details-info-title {
    margin: 0;
    font-size: rem(20);
    color: var(--main-font-color);
    font-weight: bold;
    letter-spacing: 0;
    line-height: rem(25);
  }

  .details-title-text {
    display: flex;
    gap: rem(34);
    margin-top: rem(20);

    .detail-names {
      color: var(--main-font-color);
      font-size: rem(16);
      font-weight: 500;
      letter-spacing: 0;
      line-height: rem(20);
      margin: 0;
      min-width: rem(134);
    }

    .detail-values {
      color: var(--main-font-color);
      font-size: rem(16);
      letter-spacing: 0;
      line-height: rem(20);
      margin: 0;
      display: flex;
      align-items: center;

      .img {
        margin-right: rem(8);
      }
    }
  }

  .details-title-text:nth-child(5) {
    .detail-values {
      @include hover-active-pointer;
      color: var(--orange-main);
      font-size: rem(16);
      font-weight: bold;
      letter-spacing: 0;
      line-height: rem(19);
      text-decoration: underline;
    }
  }
}

</style>
