export default class ParagraphStyle {

  /**
   * The alignment of the paragraph. The value must be one of: START, CENTER, END, JUSTIFIED.
   * @type {string}
   */
  alignment;

  /**
   * The id of the heading of this paragraph.
   * @type {string}
   */
  headingId;


  /**
   * The named style type of the paragraph.
   * @type {string}
   */
  namedStyleType;

  /**
   * The style of the paragraph.
   * @param alignment
   * @param headingId
   */
  constructor(alignment, headingId, namedStyleType) {
    this.alignment = alignment;
    this.headingId = headingId;
    this.namedStyleType = namedStyleType
  }


}
