import {computed} from "vue";
import {useRootStore} from "@/store";

export function getModalHelpers() {
    function setActiveModal(modalName) {
        useRootStore().setActiveModal(modalName);
    }

    function closeActiveModal() {
        useRootStore().closeActiveModal();
    }

    const activeModalName = computed(() => useRootStore().getActiveModal);

    function isModalActive(modalName) {
        return activeModalName.value === modalName;
    }

    return {activeModalName, setActiveModal, closeActiveModal, isModalActive};
}
