import Feedback from "@/models/feedback/Feedback";
import Summary from "@/models/summary/Summary";
import User from "@/models/users/User";
import FeedBackClosedByUser from "@/models/feedback/FeedbackClosedByUser";

export default class FeedbackDetails extends Feedback{
    constructor({id, createdAt, userId, status, chapterTitle, selectedText, feedbackText, replyText,  closedAt,
                summary, user, paragraphStartIndex, selectedStartIndex, closedByUser}) {
        super({id, createdAt, closedAt, feedbackText, status})
        this.userId = userId;
        this.chapterTitle = chapterTitle;
        this.selectedText = selectedText;
        this.replyText = replyText;
        this.paragraphStartIndex = paragraphStartIndex;
        this.selectedStartIndex = selectedStartIndex;
        this.summary = Summary.parseDataFromObject(summary);
        this.user = User.parseDataFromObject(user);
        this.closedByUser = FeedBackClosedByUser.parseDataFromObject(closedByUser);
    }

    static parseDataFromObject(data) {
        return new FeedbackDetails(data);
    }
}
