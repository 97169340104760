export function getReplyMessage(feedback, processed, notProcessed) {
    const headerProcessed = `Thanks voor jouw feedback op de samenvatting ${feedback.summary.title}. We zijn direct met jouw feedback aan de slag gegaan en hebben dankzij jouw input de samenvatting kunnen verbeteren! Namens Slim Academy en al jouw medestudenten, danku, danku en danku 😃 \n\nDe feedback die je hebt achtergelaten was:\n\n${feedback.feedbackText}`;
    const headerNotProcessed = `Bedankt voor de feedback die je hebt gegeven op de samenvatting ${feedback.summary.title}. De feedback die je hebt achtergelaten was:\n\n${feedback.feedbackText} \n\nWij hebben de feedback niet verwerkt in de samenvatting. De reden hiervoor is [Reden niet verwerkt]`;
    const headerNotNot = `Bedankt voor de feedback die je hebt gegeven op de samenvatting ${feedback.summary.title}. De feedback die je hebt achtergelaten was:\n\n${feedback.feedbackText} \n\n[Bericht manager]`;

    const bodyProcessed = `Mocht je nog meer opmerkingen, tips of verbeterpunten hebben, mail ons dan op klantenservice@slimacademy.nl of stuur een berichtje via Instagram chat, dan gaan wij hier mee aan de slag!`;
    const bodyNotProcessed = `Mocht je hier toch anders over denken, dan horen we het natuurlijk graag. Ook als je andere opmerkingen, tips of verbeterpunten hebt, mail ons dan op klantenservice@slimacademy.nl of stuur een berichtje via Instagram chat, dan gaan wij hier verder mee aan de slag!`;
    const bodyNotNot = `Mocht je verder opmerkingen, tips of verbeterpunten hebben, mail ons dan op klantenservice@slimacademy.nl of stuur een berichtje via Instagram chat, dan gaan wij hier mee aan de slag!`;

    if (feedback){ // Build string message depending on 'Feedback verwerkt','Niet verwerkt' or 'Anders'
        const messageTopSection = `${ processed == true && notProcessed == false ? headerProcessed : processed == false && notProcessed == true ? headerNotProcessed : headerNotNot }`
        return `Beste ${feedback.user.name},\n\n${messageTopSection}\n\n${ processed == true && notProcessed == false ? bodyProcessed : processed == false && notProcessed == true ? bodyNotProcessed : bodyNotNot } \n\nWe wensen je veel succes met studeren en het halen van jouw tentamens!\n\nTeam Slim Academy`
    }

}