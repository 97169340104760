<template>
<!--  TODO Refactor logic to input type=radio-->
  <div class="form-group-wrapper">
    <p class="radio-title">{{ label }}</p>
    <div class="form-group-inner">
      <div v-for="option in options" :key="option.id" class="radio-btn-wrapper">
        <div v-if="input !== option.id" @click="onClickBtn(option)" class="radio-btn">
        </div>
        <div v-else class="radio-btn-active radio-btn">
          <div class="radio-btn-active-inner">
          </div>
        </div>
        <p v-if="option.name" class="text">{{ option.name }}</p>
        <p v-else class="text">{{ option.id }}</p>
      </div>
      <input v-model="input"
             :id="fieldName"
             :disabled="isReadOnly"
             type="radio"
             class="hidden-input-field">
    </div>
    <p v-for="errorMessage of errorMessages" :key="errorMessage" class="error-message">{{ errorMessage }}</p>
  </div>
</template>

<script>
import {ref, watch} from 'vue';
import Validator from "@/utils/yo-validator/YoValidator";

export default {
  name: 'RadioGroup',
  props: {
    label: {
      required: false
    },
    fieldName: {
      required: true
    },
    options: {
      required: true
    },
    rules: {
      default: 'required'
    },
    selectDefault: {
      type: Boolean,
      required: false,
      default: false
    },
    value: {
      required: false
    },
    isReadOnly: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const {field, errorMessages, setFieldData} = Validator.register(props);
    const input = ref('');

    function onClickBtn(option) {
      if (!props.isReadOnly) {
        updateValues(option);
      }
    }

    watch(() => props.selectDefault, (newVal) => {
      if (newVal){
        updateValues(props.options[0])
      }
    }, {immediate:true})

    function updateValues(option) {
      input.value = option.id;
      setFieldData(option);
    }

    setValueOnCreate();

    function setValueOnCreate() {
      if (props.value) {
        updateValues({id: props.value})
      }
    }

    return {
      input,
      errorMessages,
      field,
      onClickBtn
    }
  }
}
</script>

<style lang="scss" scoped>
.form-group-wrapper {
  .radio-title {
    margin: 0 0 rem(16) 0;
    color: var(--main-font-color);
    font-size: rem(14);
    letter-spacing: 0;
    line-height: rem(16);
  }

  .form-group-inner {
    display: flex;
    gap: rem(40);

    .radio-btn-wrapper {
      display: flex;
      align-items: center;

      .radio-btn {
        @include hover-active-pointer;
        width: rem(16);
        height: rem(16);
        border: rem(1) solid #D7DEE3;
        background-color: var(--grey-light-02);
        border-radius: 50%;
        margin-right: rem(12);
      }

      .radio-btn-active {
        border: rem(0.89) solid #F0900A;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;

        .radio-btn-active-inner {
          height: rem(8);
          width: rem(8);
          background-color: var(--orange-main);
          border-radius: 50%;
        }
      }

      .text {
        margin: 0;
        opacity: 0.8;
        color: var(--main-font-color);
        font-size: rem(14);
        letter-spacing: 0;
        line-height: rem(16);
      }
    }
  }
}

.radio-group {
  .form-group-inner {
    .radio-btn-wrapper {
      .text {
        opacity: 1;
      }
    }
  }
}

.hidden-input-field {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
  z-index: z('below');
}

</style>
