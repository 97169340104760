export default class TableRowStyle {


  /**
   * The minimum height of the row.
   * @type {Dimension}
   */
  minRowHeight;

  /**
   * If the row is a header row.
   */
  tableHeader;

  /**
   * If we should prevent overflow
   * @type {boolean}
   */
  preventOverflow;

  constructor({minRowHeight, tableHeader, preventOverflow}) {
    this.minRowHeight = minRowHeight;
    this.tableHeader = tableHeader;
    this.preventOverflow = preventOverflow;
  }


}
