import QuestionList from "@/models/question/QuestionList";
import Question from "@/models/question/Question";
export default class QuestionListDetails extends QuestionList{
    constructor({id, chapterId, title, description, answerDisplaying, questions, summaryId}) {
        super({id, summaryId,chapterId, title, description});
        this.answerDisplaying = answerDisplaying;
        this.questions = questions.map(question => Question.parseDataFromObject(question));
    }

    static parseDataFromObject(data) {
        return new QuestionListDetails(data);
    }
}