<template>
  <div :class="['checkbox', {'checked' : isCheckboxChecked}]" @click="onClickCheckbox">

  </div>
</template>

<script>
import {ref, watch} from "vue";

export default {
  name: "Checkbox",
  emits: ['checked'],
  props: {
    select: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props, {emit}) {
    const isCheckboxChecked = ref(false);

    function onClickCheckbox() {
      isCheckboxChecked.value = !isCheckboxChecked.value;
      emit('checked');
    }

    watch(() => props.select, (newVal) => {
      onClickCheckbox();
    },{deep:true});

    return {
      onClickCheckbox,
      isCheckboxChecked
    }
  }
}
</script>

<style lang="scss" scoped>
.checkbox {
  margin-right: rem(12);
  height: rem(18);
  width: rem(18);
  border: rem(1) solid #D7DEE3;
  background-color: var(--white-checkbox);

  &.checked {
    background-size: rem(18);
    background: url("../../assets/icons/ic_checkbox.svg") top;
  }
}
</style>