
export default class SummaryEmbeddedImage {
  /**
   * @type number
   */
  id;
  /**
   * @type number
   */
  summaryId;
  /**
   * @type string
   */
  createdAt
  /**
   * @type {string}
   */
  imageUrl;

  constructor({id, summaryId, createdAt, imageUrl}) {
    this.id = id;
    this.summaryId = summaryId;
    this.createdAt = createdAt;
    this.imageUrl = imageUrl;
  }

  static parseDataFromObject(data) {
    return new SummaryEmbeddedImage(data);
  }

  get fullUrl() {
    return process.env.VUE_APP_API_URL + this.imageUrl;
  }
}
