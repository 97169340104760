<template>
<Modal v-if="content" :is-visible="isVisible">
  <div class="modal-content-wrapper">
    <p class="modal-title">{{ content.title }}</p>
    <p class="modal-text">{{ content.text }}</p>
    <DButton @click="$emit('on-click-btn')"
             :button-text="content.buttonText"
             button-type="button"
             :class="['modal-button', {'modal-button-red': content.isRedButton}]"/>
    <p @click="$emit('close-modal')" class="close-modal">Annuleren</p>
  </div>
</Modal>
</template>

<script>
import Modal from "@/components/partials/Modal";
import DButton from "@/components/elements/DButton";

export default {
  name: "SummaryDetailModal",
  components: {
    DButton,
    Modal
  },
  props: {
    isVisible: {
      required: true,
      type: Boolean,
      default: false
    },
    content: {
      required: true,
    },
  }
}
</script>

<style scoped lang="scss">
.modal-content-wrapper {
  padding: rem(40) rem(64) rem(32);
  max-width: rem(493);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .modal-title {
    font-size: rem(18);
    font-weight: bold;
    letter-spacing: 0;
    line-height: rem(23);
    color: var(--main-font-color);
    margin: 0 0 rem(20) 0;
  }

  .modal-text {
    color: var(--main-font-color);
    font-size: rem(14);
    letter-spacing: 0;
    line-height: rem(24);
    margin: 0 0 rem(32) 0;
  }

  .close-modal {
    @include hover-active-pointer;
    color: var(--orange-main);
    font-size: rem(16);
    font-weight: bold;
    letter-spacing: 0;
    line-height: rem(19);
    text-decoration: underline;
    margin: rem(28.5) 0 0 0;
  }
}
</style>