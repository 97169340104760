/**
 * The parsing of all documents files is placed in a single class to avoid multiple cirucular dependencies.
 * As the underlying data structure is circular, (e.g. a structuredElement contains a paragraph which contains a structuredElement).
 */
import CropProperties from "./InlineObject/CropProperties";
import EmbeddedObject from "./InlineObject/EmbeddedObject";
import ImageProperties from "./InlineObject/ImageProperties";
import InlineObject from "./InlineObject/InlineObject";
import InlineObjectElement from "./InlineObject/InlineObjectElement";
import Bullet from "./lists/Bullet";
import InlineObjectProperties from "./InlineObject/InlineObjectProperties";
import PageBreak from "./Paragraph/PageBreak";
import ParagraphElement from "./Paragraph/ParagraphElement";
import Paragraph from "./Paragraph/Paragraph";
import ParagraphStyle from "./Paragraph/ParagraphStyle";
import WeightedFontFamily from "./Paragraph/WeightedFontFamily";
import PositionedObject from "./PositionedObject/PositionedObject";
import PositionedObjectPositioning from "./PositionedObject/PositionedObjectPositioning";
import PositionedObjectProperties from "./PositionedObject/PositionedObjectProperties";
import Dimension from "./Size/Dimension";
import Size from "./Size/Size";
import Table from "./table/Table";
import TableCell from "./table/TableCell";
import TableRow from "./table/TableRow";
import Body from "./Body";
import Footer from "./Footer";
import GDocDocument from "./GDocDocument";
import AnnotatedStructuralElement from "../content/AnnotatedStructuralElement";
import Header from "./Header";
import SectionBreak from "./SectionBreak";
import SectionStyle from "./SectionStyle";
import TableCellStyle from "./TableCellStyle";
import TableOfContents from "./TableOfContents";
import TableRowStyle from "./TableRowStyle";
import NestingLevel from "./lists/NestingLevel";
import ListProperties from "./lists/ListProperties";
import List from "./lists/List";
import TextStyle from "./Paragraph/TextStyle";
import TextRun from "../content/TextRun";
import StructuralElement from "./StructuralElement";
import Equation from "./Paragraph/Equation";

export class DocumentParser {
  
  static parseCropPropertiesDataFromObject(data) {
    if (data == null) {
      return null;
    }
    return new CropProperties({
      offsetLeft: data.offsetLeft,
      offsetTop: data.offsetTop,
      offsetRight: data.offsetRight,
      offsetBottom: data.offsetBottom,
      angle: data.angle,
    });
  }
  
  
  static parseEmbeddedObjectDataFromObject(data) {
    if (data == null) {
      return null;
    }
    
    return new EmbeddedObject({
      title: data.title,
      description: data.description,
      size: this.parseSizeDataFromObject(data.size),
      marginTop: this.parseDimensionDataFromObject(data.marginTop),
      marginBottom: this.parseDimensionDataFromObject(data.marginBottom),
      marginLeft: this.parseDimensionDataFromObject(data.marginLeft),
      marginRight: this.parseDimensionDataFromObject(data.marginRight),
      linkedContentReference: null, //Todo, if you either want to add in linked sheets docuemnts
      imageProperties: this.parseImagePropertiesDataFromObject(data.imageProperties),
    });
  }
  
  
  static parseImagePropertiesDataFromObject(data) {
    if (data == null) {
      return null;
    }
    
    return new ImageProperties({
      contentUri: data.contentUri,
      sourceUri: data.sourceUri,
      brightness: data.brightness,
      contrast: data.contrast,
      transparency: data.transparency,
      cropProperties: this.parseCropPropertiesDataFromObject(data.cropProperties),
      angle: data.angle,
    });
  }
  
  
  static parseInlineObjectDataFromObject(data) {
    if (data == null) {
      return null;
    }
    
    return new InlineObject({
      objectId: data.objectId,
      inlineObjectProperties: this.parseInlineObjectPropertiesDataFromObject(data.inlineObjectProperties),
    });
  }
  
  static parseInlineObjectElementDataFromObject(data) {
    if (data == null) {
      return null;
    }
    return new InlineObjectElement({
      inlineObjectId: data.inlineObjectId,
      textStyle: this.parseTextStyleDataFromObject(data.textStyle),
    });
  }
  
  static parseInlineObjectPropertiesDataFromObject(data) {
    if (data == null) {
      return null;
    }
    
    return new InlineObjectProperties({
      embeddedObject: this.parseEmbeddedObjectDataFromObject(data.embeddedObject),
    });
  }
  
  
  static parseBulletDataFromObject(data) {
    if (data == null) {
      return null;
    }
    return new Bullet({
      listId: data.listId,
      nestingLevel: data.nestingLevel,
      textStyle: this.parseTextStyleDataFromObject(data.textStyle),
    });
  }
  
  static parseListDataFromObject(data,id) {
    if (data == null) {
      return null;
    }
    return new List({
      listId: id,
      listProperties: this.parseListPropertiesDataFromObject(data.listProperties),
    });
  }
  
  static parseListPropertiesDataFromObject(data) {
    if (data == null) {
      return null;
    }
    return new ListProperties({
      nestingLevels: data.nestingLevels.map((nestingLevel) => this.parseNestingLevelDataFromObject(nestingLevel)),
    });
  }
  
  
  /**
   * Convert from a data object to a nesting level object.
   * @param data
   * @return {NestingLevel}
   */
  static parseNestingLevelDataFromObject(data) {
    if (data == null) {
      return null;
    }
    return new NestingLevel({
      bulletAlignment: data.bulletAlignment,
      glyphFormat: data.glyphFormat,
      glyphSymbol: data.glyphSymbol,
      glyphType: data.glyphType,
      indentFirstLine: this.parseDimensionDataFromObject(data.indentFirstLine),
      indentStart: this.parseDimensionDataFromObject(data.indentStart),
    });
  }
  
  
  static parsePageBreakDataFromObject(data) {
    if (data == null) {
      return null;
    }
    return new PageBreak({
      textStyle: this.parseTextStyleDataFromObject(data.textStyle),
    });
  }
  
  
  static parseParagraphDataFromObject(data) {
    if (data == null) {
      return null;
    }
    return new Paragraph({
      positionedObjectIds: data.positionedObjectIds,
      elements: data.elements.map(paragraphElement => this.parseParagraphElementDataFromObject(paragraphElement)),
      paragraphStyle: this.parseParagraphStyleDataFromObject(data.paragraphStyle),
      bullet: this.parseBulletDataFromObject(data.bullet)
    });
  }
  
  static parseParagraphElementDataFromObject(data) {
    if (data == null) {
      return null;
    }
    return new ParagraphElement({
      startIndex: data.startIndex,
      endIndex: data.endIndex,
      textRun: this.parseTextRunDataFromObject(data.textRun),
      pageBreak: this.parsePageBreakDataFromObject(data.pageBreak),
      inlineObjectElement: this.parseInlineObjectElementDataFromObject(data.inlineObjectElement),
      equation: this.parseEquationFromObject(data.equation)
    });
  }
  
  static parseTextRunDataFromObject(data) {
    if (!data) {
      return null;
    }
    return new TextRun(
        data.content,
        this.parseTextStyleDataFromObject(data.textStyle),
    );
  }
  
  /**
   * Convert from a data object to a paragraph style object.
   * @param data
   * @return {ParagraphStyle}
   */
  static parseParagraphStyleDataFromObject(data) {
    return new ParagraphStyle(data.alignment, data.headingId, data.namedStyleType);
  }
  
  static parseTextStyleDataFromObject(data) {
    if (!data) {
      return null;
    }
    return new TextStyle(
        data.bold,
        data.italic,
        data.smallCaps,
        data.strikethrough,
        data.underline,
        data.weightedFontFamily ? this.parseWeightedFontFamilyDataFromObject(data.weightedFontFamily) : null,
        data.baselineOffset,
    );
  }
  
  static parseWeightedFontFamilyDataFromObject(data) {
    return new WeightedFontFamily(
        data['fontFamily'],
        data.weight,
    );
  }
  
  
  static parsePositionedObjectDataFromObject(data) {
    if (data == null) {
      return null;
    }
    return new PositionedObject({
      objectId: data.objectId,
      positionedObjectProperties: this.parsePositionedObjectPropertiesDataFromObject(data.positionedObjectProperties),
    });
  }
  
  static parsePositionedObjectPositioningDataFromObject(data) {
    if (data == null) {
      return null;
    }
    return new PositionedObjectPositioning({
      layout: data.layout,
      leftOffset: this.parseDimensionDataFromObject(data.leftOffset),
      rightOffset: this.parseDimensionDataFromObject(data.rightOffset),
    });
  }
  
  
  static parsePositionedObjectPropertiesDataFromObject(data) {
    if (data == null) {
      return null;
    }
    
    return new PositionedObjectProperties({
      positioning: this.parsePositionedObjectPositioningDataFromObject(data.positioning),
      embeddedObject: this.parseEmbeddedObjectDataFromObject(data.embeddedObject),
    });
  }
  
  static parseDimensionDataFromObject(data) {
    if (data == null) {
      return null;
    }
    return new Dimension({
      magnitude: data.magnitude,
      unit: data.unit,
    });
  }
  
  static parseSizeDataFromObject(data) {
    if (data == null) {
      return null;
    }
    return new Size({
      height: this.parseDimensionDataFromObject(data.height),
      width: this.parseDimensionDataFromObject(data.width),
    });
  }
  
  static parseTableDataFromObject(data) {
    if (data?.tableRows == null) {
      return null;
    }
    return new Table(
        data.columns,
        data.rows,
        data.tableRows.map((tableRow) => this.parseTableRowDataFromObject(tableRow)));
  }
  
  
  static parseTableCellDataFromObject(data) {
    return new TableCell({
      content: data.content.map(content => this.parseAnnotatedStructuralElementDataFromObject(content)),
      tableCellStyle: this.parseTableCellStyleDataFromObject(data.tableCellStyle),
    });
  }
  
  
  static parseTableRowDataFromObject(data) {
    return new TableRow({
      startIndex: data.startIndex,
      endIndex: data.endIndex,
      tableCells: data.tableCells.map((tableCell) => this.parseTableCellDataFromObject(tableCell)),
      tableRowStyle: this.parseTableRowStyleDataFromObject(data.tableRowStyle),
    });
  }
  
  static parseEquationFromObject(data) {
    if (!data) {
      return null;
    }
    
    return Equation.parseDataFromObject(data);
  }
  
  /**
   * Parse the data from the object.
   */
  static parseBodyDataFromObject(data) {
    return new Body({
      content: data.content.map(content => this.parseAnnotatedStructuralElementDataFromObject(content)),
    });
  }
  
  
  /**
   * Parse the data from the object.
   */
  static parseFooterDataFromObject(data) {
    return new Footer({
      footerId: data.footerId,
      content: data.content.map(content => this.parseStructuralElementDataFromObject(content)),
    });
  }
  
  
  static parseGDocDocumentDataFromObject(data) {
    return new GDocDocument({
      documentId: data.documentId,
      title: data.title,
      body: this.parseBodyDataFromObject(data.body),
      headers: data.headers ? Object.fromEntries(Object.entries(data.headers).map(([key, header]) => [key, this.parseHeaderDataFromObject(header)])) : {},
      footers: data.footers ? Object.fromEntries(Object.entries(data.footers).map(([key, footer]) => [key, this.parseFooterDataFromObject(footer)])) : {},
      inlineObjects: data.inlineObjects ? Object.fromEntries(Object.entries(data.inlineObjects).map(([key, inlineObject]) => [key, this.parseInlineObjectDataFromObject(inlineObject)])) : {},
      lists: data.lists ? Object.keys(data.lists).map(list => this.parseListDataFromObject(data.lists[list],list)) : {},
      namedStyles: null, /// when used can implement data.namedStyles.map(namedStyle => this.parseNamedStyleDataFromObject(namedStyle)),
      positionedObjects: data.positionedObjects ?  Object.fromEntries(Object.entries(data.positionedObjects).map(([key, positionedObject]) => [key, this.parsePositionedObjectDataFromObject(positionedObject)])) : {},
      revisionId: data.revisionId,
      suggestedViewMode: data.suggestedViewMode,
    });
  }
  
  
  /**
   * Parse the data from the object.
   */
  static parseHeaderDataFromObject(data) {
    return new Header({
      headerId: data.headerId,
      content: data.content.map(content => this.parseStructuralElementDataFromObject(content)),
    });
  }
  
  static parseSectionBreakDataFromObject(data) {
    if (data == null) {
      return null;
    }
    
    return new SectionBreak({
      sectionStyle: this.parseSectionStyleDataFromObject(data.sectionStyle),
    });
  }
  
  
  static parseSectionStyleDataFromObject(data) {
    if (data == null) {
      return null;
    }
    return new SectionStyle();
  }
  
  /**
   * Parse the data from the object.
   */
  static parseAnnotatedStructuralElementDataFromObject(data) {
    
    if (data == null) {
      return null;
    }
    
    return new AnnotatedStructuralElement({
      startIndex: data.startIndex,
      endIndex: data.endIndex,
      paragraph: this.parseParagraphDataFromObject(data.paragraph),
      sectionBreak: this.parseSectionBreakDataFromObject(data.sectionBreak),
      table: this.parseTableDataFromObject(data.table),
      tableOfContents: this.parseTableOfContentsDataFromObject(data.tableOfContents),
    });
  }
  
  
  /**
   * Parse the data from the object.
   */
  static parseStructuralElementDataFromObject(data) {
    
    if (data == null) {
      return null;
    }
    
    return new StructuralElement({
      startIndex: data.startIndex,
      endIndex: data.endIndex,
      paragraph: this.parseParagraphDataFromObject(data.paragraph),
      sectionBreak: this.parseSectionBreakDataFromObject(data.sectionBreak),
      table: this.parseTableDataFromObject(data.table),
      tableOfContents: this.parseTableOfContentsDataFromObject(data.tableOfContents),
    });
  }
  
  
  static parseTableCellStyleDataFromObject(data) {
    if (data == null) {
      return null;
    }
    return new TableCellStyle({
      rowSpan: data.rowSpan,
      columnSpan: data.columnSpan,
      backgroundColor: data.backgroundColor,
      
      borderLeft: this.parseDimensionDataFromObject(data.borderLeft),
      borderRight: this.parseDimensionDataFromObject(data.borderRight),
      borderTop: this.parseDimensionDataFromObject(data.borderTop),
      borderBottom: this.parseDimensionDataFromObject(data.borderBottom),
      paddingLeft: this.parseDimensionDataFromObject(data.paddingLeft),
      paddingRight: this.parseDimensionDataFromObject(data.paddingRight),
      paddingTop: this.parseDimensionDataFromObject(data.paddingTop),
      paddingBottom: this.parseDimensionDataFromObject(data.paddingBottom),
      contentAlignment: data.contentAlignment,
    });
  }
  
  
  /**
   * Parse the data from the object.
   */
  static parseTableOfContentsDataFromObject(data) {
    if (data == null) {
      return null;
    }
    return new TableOfContents({
      content: data.content.map(content => this.parseAnnotatedStructuralElementDataFromObject(content)),
    });
  }
  
  
  static parseTableRowStyleDataFromObject(data) {
    if (data == null) {
      return null;
    }
    return new TableRowStyle({
      minRowHeight: this.parseDimensionDataFromObject(data.minRowHeight),
      tableHeader: data.tableHeader,
      preventOverflow: data.preventOverflow,
    });
  }
}
