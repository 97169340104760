export const getters = {
    getFeedbackList: (state) => {
        console.log("getting feedback", state.paginationDataResponse ?? []);
        return state.paginationDataResponse?.data ?? [];
    },
    getSelectedFeedback: (state) => state.selectedFeedback,
    /** filter **/
    getCurrentFeedbackFilter: (state) => state.currentFeedbackFilter,
    getTotalItemCount: (state) => state.paginationDataResponse?.totalItemCount ?? 0,
    getTotalOpenFeedbackPoints: (state) => state.paginationDataResponse?.totalOpenFeedbackPoints ?? 0,
    getPaginationDataResponse: (state) => state.paginationDataResponse,
}