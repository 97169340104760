export default class LocalDataHandler {
  constructor() {
    this.KEYS = Object.freeze({
      LOCAL_KEYS: 'local_keys',
      KEEP_ME_LOGGED_IN: 'keep_me_logged_in',
      IS_AUTHENTICATED: 'is_authenticated',
      USER_ROLE: 'user_role'
    });

    this.VALUES = {
      KEEP_ME_LOGGED_IN: '1',
      REFRESH_TOKEN_EXPIRE_TIME: 3600 * 24 * 14
    };
  }

  clearLocalData() {
    const keys = localStorage.getItem(this.KEYS.LOCAL_KEYS)
    if (keys == null) {
      return
    }
    const keyArray = JSON.parse(keys)
    for (const keyName of keyArray) {
      localStorage.removeItem(keyName)
      sessionStorage.removeItem(keyName)
    }
    localStorage.removeItem(this.KEYS.LOCAL_KEYS)
  }

  saveLocalKey(keyName) {
    let keys = localStorage.getItem(this.KEYS.LOCAL_KEYS)
    if (keys == null) {
      localStorage.setItem(this.KEYS.LOCAL_KEYS, JSON.stringify([keyName]))
      return
    }
    let keyArray = JSON.parse(keys)
    if (keyArray.indexOf(keyName) === -1) {
      keyArray.push(keyName)
      localStorage.setItem(this.KEYS.LOCAL_KEYS, JSON.stringify(keyArray))
    }
  }

  saveLocalData(keyName, data) {
    this.saveLocalKey(keyName)
    if (typeof data === 'string') {
      localStorage.setItem(keyName, data)
      return
    }
    localStorage.setItem(keyName, JSON.stringify(data))
  }

  persistLocalData(keyName, data) {
    if (typeof data === 'string') {
      localStorage.setItem(keyName, data)
      return
    }
    localStorage.setItem(keyName, JSON.stringify(data))
  }

  getLocalData(keyName) {
    let data = localStorage.getItem(keyName)
    if (data == null) return data
    try {
      // return the parsed json object
      return JSON.parse(data)
    } catch (e) {
      // error means this is not a json data, which is a saved string
      return data
    }
  }

  removeLocalKey(keyName) {
    let keys = localStorage.getItem(this.KEYS.LOCAL_KEYS)
    if (keys == null) {
      return
    }
    let keyArray = JSON.parse(keys)
    let index = keyArray.indexOf(keyName)
    if (index !== -1) {
      keyArray.splice(index, 1)
      localStorage.setItem(this.KEYS.LOCAL_KEYS, JSON.stringify(keyArray))
    }
  }

  removeLocalData(keyName) {
    this.removeLocalKey(keyName);
    localStorage.removeItem(keyName);
  }
}
