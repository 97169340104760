<template>
  <div class="auth-wrapper">
    <h2 class="title">Wachtwoord instellen</h2>
    <p class="description">Voer je nieuwe wachtwoord in en herhaal deze.</p>

    <FormWrapper :send-form-data="validateForm">
      <template #form-content>
        <PasswordConfirmation label="Wachtwoord"
                              label-confirm="Herhaal wachtwoord"
                              placeholder="Voer je wachtwoord in"
                              placeholder-confirm="Voer je wachtwoord nogmaals in"/>
      </template>

      <template #button-submit>
        <DButton button-text="Wijzig wachtwoord" button-type="submit" class="auth-button"/>
      </template>
    </FormWrapper>
  </div>
</template>

<script>
import FormWrapper from "@/components/yo-form/FormWrapper";
import DButton from "@/components/elements/DButton";
import PasswordConfirmation from "@/components/yo-form/PasswordConfirmation";
import {useAuthStore} from "@/store/auth";
import {useRouter} from "vue-router";
import {ROUTE_NAMES_AUTH} from "@/router/modules/auth";
import {TOAST_CONTENTS} from "@/utils/handlers/toastHandler/ToastContents";
import ToastHandler from "@/utils/handlers/toastHandler/ToastHandler";

export default {
  name: "CreateNewPassword",
  components: {
    PasswordConfirmation,
    FormWrapper,
    DButton,
  },
  setup() {
    const authStore = useAuthStore();
    const router = useRouter();

    function validateForm(formdata) {
      const params = router.currentRoute.value.params;
      authStore.resetPassword({id: params.id, token: params.token},{password: formdata.password}).then(() => {
        ToastHandler.addNew(TOAST_CONTENTS.PASSWORD_RESET_SUCCES)
        router.push({name: ROUTE_NAMES_AUTH.LOGIN})
      });
    }

    return {
      validateForm
    }
  }
}
</script>

<style lang="scss" scoped>
.auth-wrapper {
  padding-top: rem(34);

  .title {
    font-size: rem(28);
    font-weight: bold;
    letter-spacing: 0;
    line-height: rem(34);
    margin: 0;
  }

  .description {
    font-size: rem(16);
    letter-spacing: 0;
    line-height: rem(22);
    margin: rem(16) 0 rem(24) 0;
  }

  .input-wrapper:not(:last-of-type) {
    margin-bottom: rem(24);
  }

  .auth-button {
    margin-top: rem(40);
  }

}
</style>