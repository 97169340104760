<template>
  <div class="input-outer-wrapper">
    <div class="checkbox-input-wrapper">
      <div @click="onCheckCheckbox()" :class="['checkbox', {'checked': isCheckBoxChecked}]">
      </div>
      <div class="input-wrapper">

        <label :for="fieldName" class="label">{{ label }}
          <input ref="inputField"
                 :id="fieldName"
                 :name="fieldName"
                 :class="{'has-error': errorMessages.length}"
                 :placeholder="placeholder"
                 v-model="input"
                 :maxLength="maxLength"
                 @input="updateInput(true)"
                 class="input">
        </label>
      </div>
    </div>
    <div v-if="displayErrorMsg">
      <p v-for="errorMessage of errorMessages" :key="errorMessage" class="error-message">{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
import {ref, watch} from "vue";
import Validator from "@/utils/yo-validator/YoValidator";

export default {
  name: "CheckboxAndInput",
  props: {
    placeholder: {
      required: false,
      type: String
    },
    label: {
      required: false,
      default: '',
      type: String
    },
    fieldName: {
      type: String,
      required: true
    },
    rules: {
      type: String,
      default: ''
    },
    value: {
      required: false,
      default: ''
    },
    displayErrorMsg: {
      required: false,
      default: true,
      type: Boolean
    },
    unCheck: {
      required: false,
      default: false,
      type: Boolean
    },
    maxLength: {
      required: false,
      default: 512,
      type: Number
    }
  },
  emits: ['update-parent', 'error', 'error-cleared'],
  setup(props, {emit}) {
    const {handleInput, field, errorMessages, setFieldData} = Validator.register(props);
    const input = ref('');
    const isCheckBoxChecked = ref(false);

    function updateInput(isInput = false) {
      emit('update-parent', [isCheckBoxChecked.value, input.value, isInput]);
      setFieldData([isCheckBoxChecked.value, input.value]);
    }

    function onCheckCheckbox() {
      checked();
    }

    function checked() {
      isCheckBoxChecked.value = !isCheckBoxChecked.value;
      updateInput();
    }

    // Pre select
    watch(() => props.value, (newVal) => {
      if (!props.value.includes(undefined)) {
        isCheckBoxChecked.value = props.value[0];
        input.value = props.value[1];
        setFieldData([isCheckBoxChecked.value, input.value]);
      }
    }, {deep: true, immediate: true});

    watch(() => errorMessages.value.length, (newVal) => { // In case multiple Input fields have 1 error msg.
      if (!props.displayErrorMsg && errorMessages.value.length) {
        emit('error');
      } else {
        emit('error-cleared');
      }
    }, {deep: true});

    watch(() => props.unCheck, (newVal) => {
      if(props.unCheck) {
        isCheckBoxChecked.value = !isCheckBoxChecked.value;
      }
    },{deep:true})

    return {
      input,
      errorMessages,
      handleInput,
      field,
      onCheckCheckbox,
      isCheckBoxChecked,
      updateInput
    }
  }
}
</script>

<style lang="scss" scoped>
.checkbox-input-wrapper {
  display: flex;
  position: relative;

  .checkbox {
    position: absolute;
    box-sizing: border-box;
    bottom: rem(14);
    height: rem(18);
    width: rem(18);
    border: rem(1) solid var(--radio-btn-border);
    background-color: var(--white-checkbox);

    &.checked {
      background: url("@/assets/icons/ic_checkbox.svg");
      background-size: rem(18);
      border: none;
    }
  }

  .input-wrapper {
    flex-grow: 1;
    margin-left: rem(38);
  }
}

.has-error {
  border: 1px solid var(--red-error)
}

</style>