export default class TextStyle {

  /**
   * Whether or not the text is bold.
   * @type {boolean}
   */
  bold;

  /**
   * Whether or not the text is italic.
   * @type {boolean}
   */
  italic;

  /**
   * Whether or not the text is in small capital letters.
   * @type {boolean}
   */
  smallCaps;

  /**
   * Whether or not the text is strikethrough.
   * @type {boolean}
   */
  strikethrough;

  /**
   * Whether or not the text is underlined.
   * @type {boolean}
   */
  underline;


  /**
   * The baseline offset of the text run.
   */
  baselineOffset;

  /**
   * The weighted font family of the text run.
   * @type {WeightedFontFamily}
   */
  weightedFontFamily;

  constructor(bold, italic, smallCaps, strikethrough, underline, weightedFontFamily, baselineOffset) {
    this.bold = bold;
    this.italic = italic;
    this.smallCaps = smallCaps;
    this.strikethrough = strikethrough;
    this.underline = underline;
    this.weightedFontFamily = weightedFontFamily;
    this.baselineOffset = baselineOffset;
  }

  static get empty() {
    return new TextStyle(false, false, false, false, false, null);
  }


}
