export default class QuestionList {
  constructor({id, title, description}) {
    this.id = id;
    this.title = title;
    this.description = description;

  }

  static parseDataFromObject(data) {
    return new QuestionList({
      id: data.questionListId,
      title: data.questionListTitle,
      description: data.questionListDescription,
    });
  }
}
