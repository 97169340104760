import User from "@/models/users/User";
import errorHandler from "@/utils/handlers/errorHandler/ErrorHandler";
import UserTableFilter from "@/models/tables/UserTableFilter";

export const actions = {
    setUsersList(data) {
        this.usersList = data.map(user => User.parseDataFromObject(user))
    },
    setSelectedUser(data) {
        this.selectedUser = User.parseDataFromObject(data);
    },
    setConflictEmailError(error) {
        this.conflictEmailError = errorHandler.getError(error.response.data);
    },
    resetError() {
        this.conflictEmailError = null;
    },
    setDataResponse(paginationDataResponse) {
        this.paginationDataResponse = paginationDataResponse;
    },
    updateTableFilter(data){
        this.currentUserFilter = data;
    },
    updateSearchQuery(newQuery) {
        this.currentUserFilter.searchQuery = newQuery;
    },
    updatePageNumber(newPageNumber){
        this.currentUserFilter.pageNumber = newPageNumber;
    },
    setSortingOption(newSortingOption) {
        this.currentUserFilter.sortingOption = newSortingOption;
    },
    clearFilters(){
        this.currentUserFilter = UserTableFilter.empty();
    }
}