export default class CropProperties {

  /**
   * The offset left.
   * @type {number}
   */
  offsetLeft;

  /**
   * The offset top.
   * @type {number}
   */
  offsetTop;

  /**
   * The offset right.
   * @type {number}
   */
  offsetRight;

  /**
   * The offset bottom.
   * @type {number}
   */
  offsetBottom;

  /**
   * The angle.
   * @type {number}
   */
  angle;

  constructor({offsetLeft, offsetTop, offsetRight, offsetBottom, angle}) {
    this.offsetLeft = offsetLeft;
    this.offsetTop = offsetTop;
    this.offsetRight = offsetRight;
    this.offsetBottom = offsetBottom;
    this.angle = angle;
  }
  
  
  convertToCssStyleString(initalWidth, initialHeight) {
    return "background-position: " + (this.offsetLeft + this.offsetRight) * 2* 100 + "% " + (this.offsetTop + this.offsetBottom) * 2 *  100 + "%;" +
        " transform: rotate(" + this.angle + "rad); " +
        " background-size: " + (1 /  ( 1 - (this.offsetRight + this.offsetLeft))) * 100 + "% " + (1 / (1-(this.offsetBottom + this.offsetTop))) * 100 + "%;" +
        "max-width: +" + 100 + "%;"
  }
}
