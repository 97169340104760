import Answer from "@/reader/model/question/Answer";

export default class Question {
  /**
   *
   * @param {String} questionText
   * @param {String} pictureUrl
   * @param {String} feedbackText
   * @param {String} feedbackPictureUrl
   * @param {Answer[]} answers
   * @param {String} pictureReference
   * @param {String} feedbackPictureReference
   * @param {number} userAnswerId
   */
  constructor({questionText, pictureUrl = '', feedbackText, feedbackPictureUrl = '', answers, pictureReference, feedbackPictureReference,userAnswerId, questionId}) {
    this.questionText = questionText;
    this.pictureUrl = pictureUrl;
    this.feedbackText = feedbackText;
    this.feedbackPictureUrl = feedbackPictureUrl;
    this.answers = answers.map(answer => Answer.parseDataFromObject(answer));
    this.pictureReference = pictureReference;
    this.feedbackPictureReference = feedbackPictureReference;
    this.userAnswerId = userAnswerId;
    this.questionId = questionId;
  }

  setIsAnswered(answerId) {
    this.userAnswerId = answerId;
  }

  getIsAnswered(){
    return this.userAnswerId !== null;
  }

  static parseDataFromObject(data) {
    return new Question(data);
  }
}
