export default class SectionBreak {

  /**
   * The style of the section break.
   * @param {SectionStyle}
   */
  sectionStyle;


  constructor({sectionStyle}) {
    this.sectionStyle = sectionStyle;
  }


}
