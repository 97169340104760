export default class TableOfContents {

  /**
   * The structured elements of the table of contents.
   * @type {AnnotatedStructuralElement[]}
   */
  content;

  constructor({content}) {
    this.content = content;
  }

  get getChildStructuralElements() {
    if (this.content) {
      console.log("getting table of contents child structural elements");
      return this.content.map(content => content.getChildStructuralElements(true)).flat();
    }
    return [];
  }


  estimateHeight(clientWidth,fullDocument) {
    return this.content.reduce((total, element) => total + element.estimateHeight(clientWidth,fullDocument), 0);
  }

}
