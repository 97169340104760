import apiHandler from '@/utils/handlers/ApiHandler';
import {useContentStore} from "@/reader/store/content"
import {useRootStore} from '@/store'
import AnnotatedDocument from "@/reader/model/summary/content/AnnotatedDocument";
import {useSummaryStore} from "@/store/summary";
import Summary from "@/reader/model/summary/Summary";
import SummaryChapter from "@/reader/model/summary/SummaryChapter";
import QuestionListDetails from "@/reader/model/question/QuestionListDetails";
import {DocumentParser} from "@/reader/model/summary/document/DocumentParser";

export const apiActions = {

  /**
   * Fetch the content of a book based on its id.
   * and set it in the store.
   * @param {number} id the id of the book
   * @return {boolean} if was succesfull.
   */
  async fetchContent(id) {
    const store = useContentStore();
    const rootStore = useRootStore();

    console.log("fetching detail summary");
    //make sure we have the correct details summary as well.
    await store.fetchDetailSummary(id);

    console.log("finished fetching detail summary");

    return apiHandler.get(`summary/${id}/content`).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        response.data['id'] = id;
        const document = DocumentParser.parseGDocDocumentDataFromObject(response.data);
        console.log("document parsed");
        const summary = new AnnotatedDocument({document});
        //store.setContent(summary);
        return summary;
      }
      return null;
    }).catch(error => {
      console.log('error', error);
      rootStore.setApiError(error);
      return null;
    });
  },
  

  /**
   * Fetch the details of a summary, but checks if it's already cached or not.
   * @param id the id of the summary to get.
   * @param ignoreCache whether or not to ignore the cache, not retrieving if we already have it.
   * @return {Summary ? null}
   */
  async fetchDetailSummary(id,ignoreCache = false) {
    const store = useContentStore();


    console.log("had to search");

    ///If we don't have the summary, fetch it.
    const rootStore = useRootStore();

    if(store)

      return apiHandler.get(`summary/${id}`).then(response => {
        if (apiHandler.isSuccess(response.status)) {
          console.log("got and set summary");
          const summary = Summary.parseDataFromObject(response.data);
          store.setDetailSummary(summary);
          return summary;
        }
        return false;
      }).catch(error => {
        rootStore.setApiError(error);
        return false;
      });
  },


  fetchSummaryChapters(id){
    const store = useContentStore();
    const rootStore = useRootStore();

    return apiHandler.get(`summary/${id}/chapters`).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        const chapters = response.data.map(chapter => SummaryChapter.parseDataFromObject(chapter));
        store.setSummaryChapters(chapters);
        return chapters;
      }
      return [];
    }).catch(error => {
      console.log('error', error);
      rootStore.setApiError(error);
      return [];
    });
  },
  /**
   * Fetch the question lists details from a summary.
   * @param {Summary} summary
   * @constructor
   */
  async FetchQuestionListsFromSummary(summary){

    const rootStore = useRootStore();
    const store = useContentStore();

    const unloadedList = summary.questionLists;
    const loadedList = [];

    const promises = unloadedList.map(list => {
      return apiHandler.get(`/question-list/${list.id}`).then(response => {
        if (apiHandler.isSuccess(response.status)) {
          loadedList.push(QuestionListDetails.parseDataFromObject(response.data));
        }
      }).catch(error => {
        console.log('error', error);
        rootStore.setApiError(error);
      });
    });


    return Promise.all(promises).then(function(value){
      store.setLoadedQuestionList(loadedList);
      return loadedList;
    });
  },
  /**
   * Update read progress
   */
  updateReadProgress(id, percentage) {
    const rootStore = useRootStore();

    return apiHandler.post(`summary/${id}/update-progress`, percentage).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    }).catch(error => {
      rootStore.setApiError(error);
      return false;
    });
  },
}
