export default class Tree {


  /**
   * @param {TreeItem} content
   * @param {Tree} parent
   * @param {Tree[]}children
   */
  constructor(content,parent, children) {
    this.content = content;
    this.parent = parent;
    this.children = children;
  }


  /**
   * @param {Tree | null} parent
   */
  setParent(parent) {
    this.parent = parent;
  }


  /**
   * sets the children of the tree.
   * @param {Tree[]} children
   */
  setChildren(children) {
    this.children = children;
  }

  /**
   * Adds a child to the tree.
   * @param {Tree} child
   */
  addChild(child) {
    this.children.push(child);
    child.setParent(this);
  }

  /**
   *
   * @param {String} id
   */
  removeChild(id) {
    this.children = this.children.filter(c => c.content.id() !== id);
  }

  /**
   * search for a child with the given id.
   * @param {String} id
   * @param {boolean} deep
   * @return {Tree}
   */
  search(id,deep = false) {
    if(this.content.id() === id){
      return this;
    }
    if(deep) {
      for(let i = 0; i < this.children.length; i++) {
        const child = this.children[i];
        const foundChild = child.search(id,deep);
        if(foundChild) {
          return foundChild;
        }
      }
    }else{
      return this.children.find(c => c.content.id() === id);
    }
  }
}
