/**
 * We currently do not use this yet for styling, if you want to implement, then you can use this documentation
 * https://developers.google.com/docs/api/reference/rest/v1/documents#SectionStyle
 */
export default class SectionStyle {


  constructor() {
  }


}
