export default class Header {


  /**
   * The id of the header
   */
  headerId;


  /**
   * The content of the header
   * @type {StructuralElement[]}
   */
  content;

  constructor({headerId, content}) {
    this.headerId = headerId;
    this.content = content;
  }


}
