import {ERROR_CODES} from '../errorCodes'
import ErrorBase from './ErrorBase'

export default class InvalidGDocsUrl extends ErrorBase {
    constructor(error, message) {
        super(error, message);
        this.code = ERROR_CODES.INVALID_GDOCS_LINK;
        this.message = {
            nl: 'Voer een geldige Google Docs link in',
        }
    }
}
