<template>
  <Modal v-if="selectedQuestionList" :is-visible="isVisible">
    <div class="delete-question-list-wrapper">
      <p class="title">Oefenvragen Verwijderen</p>
      <p class="text">Weet je zeker dat je de oefenvragen <span class="title-bold">{{ selectedQuestionList.title }}</span>
        wil verwijderen?</p>
      <DButton @click="deleteList"
               button-text="Verwijder oefenvragen"
               button-type="button"
               class="modal-button modal-button-red"/>
      <p class="cancel-text" @click="$emit('close')">Annuleren</p>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/partials/Modal";
import DButton from "@/components/elements/DButton";

export default {
  name: "DeleteQuestionListModal",
  components: {
    DButton,
    Modal
  },
  props: {
    isVisible: {
      required: true,
      type: Boolean,
      default: false
    },
    selectedQuestionList: {
      required: true,
    }
  },
  emits: ['delete','close'],
  setup(props,{emit}) {
    function deleteList() {
      emit('delete',props.selectedQuestionList);
    }

    return {
      deleteList
    }
  }
}
</script>

<style lang="scss" scoped>
.delete-question-list-wrapper {
  padding: rem(40) rem(100) rem(32) rem(100);
  width: rem(493);
  display: flex;
  flex-direction: column;
  text-align: center;

  .title {
    color: var(--main-font-color);
    font-size: rem(18);
    font-weight: bold;
    letter-spacing: 0;
    line-height: rem(23);
    margin: 0 0 rem(20) 0;
  }

  .text {
    color: var(--main-font-color);
    font-size: rem(14);
    letter-spacing: 0;
    line-height: rem(24);
    margin: 0 0 rem(32) 0;
  }

  .title-bold {
    font-weight: bold;
  }

  .cancel-text {
    @include hover-active-pointer;
    color: var(--orange-main);
    font-size: rem(16);
    font-weight: bold;
    letter-spacing: 0;
    line-height: rem(19);
    text-decoration: underline;
    margin: rem(24) 0 0 0;
  }

}
</style>