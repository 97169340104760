import * as dayjs from 'dayjs'
import 'dayjs/locale/nl'
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');
dayjs.extend(utc);
dayjs.extend(timezone);
export const TIMEZONE = dayjs.tz.guess();

export function formatDateIntoYFMD(date) {
    return dayjs(date).locale('nl').format('D MMMM YYYY');
}

export function formatDateIntoDMY(date) {
    return dayjs(date).format('DD-MM-YYYY');
}

export function formatDateIntoYMD(date) {
    return dayjs(date).format('YYYY-MM-DD');
}

export function formatDateIntoDMYHM(date) {
    return dayjs.utc(date).tz(TIMEZONE).format('DD-MM-YYYY HH:mm');
}
