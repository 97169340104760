<template>
  <div class="content-wrapper">
    <p class="main-title">Details</p>

    <div v-if="selectedFeedback" class="info-wrapper-outer">
      <div v-for="name in feedbackDetailNames" :key="name" class="info-wrapper">
        <p class="title">{{ name.value }}</p>
        <div class="text-wrapper" @click="isEqual(name.id, 'summary.title') ? navigateToSummary() : null">
          <div v-if="isEqual(name.id, 'status')" class="status-wrapper">
            <DisplayedIcon :active-src="require('../../../assets/icons/ic_feedback-published.svg')"
                           :displayed-src="require('../../../assets/icons/ic_feedback-unpublished.svg')"
                           :is-active="selectedFeedback.isFeedbackRepliedTo"
                           class="icon"/>
            <p class="text">{{ displayStatusMsg(selectedFeedback) }}</p>
          </div>
          <p v-if="isEqual(name.id, 'createdAt')" class="text">{{ formatDateIntoDMYHM(selectedFeedback[name.id]) }}</p>
          <p v-else class="text">{{ displayDetailsValue(name) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {displayStatusMsg, feedbackDetailNames} from "@/utils/helpers/feedbackDetailsHelper";
import {ref, watch} from "vue";
import {formatDateIntoDMYHM} from "@/utils/helpers/Date";
import DisplayedIcon from "@/components/elements/DisplayedIcon";
import {useRouter} from "vue-router";
import {ROUTE_NAMES_SUMMARY} from "@/router/modules/summary";

export default {
  name: "FeedbackDetailsInfo",
  components: {DisplayedIcon},
  props: {
    feedback: {
      required: true,
    }
  },
  setup(props) {
    const selectedFeedback = ref(null);
    const router = useRouter();

    watch(() => props.feedback, (newVal) => {
      selectedFeedback.value = newVal;
    }, {deep: true})

    function displayDetailsValue(name) {
      if (["summary", "user"].some(item => name.id.includes(item))) { //e.g. id: summary.(variable)
        return getValueFromString(selectedFeedback.value, name.id); //Get value of e.g. user.email from the given obj.
      }
      if (!isEqual(name.id, 'status')) {
        return selectedFeedback.value[name.id];
      }
    }

    function getValueFromString(obj, str) {
      return str.split(".").reduce(function (o, x) {
        return o[x]
      }, obj);
    }

    function isEqual(id, name) {
      return name === id;
    }

    function navigateToSummary() {
      router.push({name: ROUTE_NAMES_SUMMARY.DETAILS, params: {summaryId: selectedFeedback.value.summary.id}})
    }

    return {
      feedbackDetailNames,
      displayDetailsValue,
      selectedFeedback,
      isEqual,
      formatDateIntoDMYHM,
      displayStatusMsg,
      navigateToSummary
    }
  }
}
</script>

<style lang="scss" scoped>
.content-wrapper {
  margin-top: rem(40);

  .main-title {
    color: var(--main-font-color);
    font-size: rem(20);
    font-weight: bold;
    letter-spacing: 0;
    line-height: rem(25);
    margin: 0 0 rem(20) 0;
  }

  .info-wrapper {
    display: flex;
    gap: rem(20);

    .title {
      color: var(--main-font-color);
      font-size: rem(16);
      font-weight: 500;
      letter-spacing: 0;
      line-height: rem(20);
      margin: 0 0 rem(20) 0;
      min-width: rem(148);
    }

    .text-wrapper {
      .status-wrapper {
        display: flex;
        align-items: center;
      }

      .text {
        color: var(--main-font-color);
        font-size: rem(16);
        letter-spacing: 0;
        line-height: rem(20);
        margin: 0 0 rem(20) 0;
      }

      .icon {
        margin: 0 rem(8) rem(20) 0
      }
    }

    &:nth-child(1) {
      .text {
        @include hover-active-pointer;
        color: var(--orange-main);
        font-size: rem(16);
        font-weight: bold;
        letter-spacing: 0;
        line-height: rem(19);
        text-decoration: underline;
      }
    }
  }
}
</style>