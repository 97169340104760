export default class Footer {


  /**
   * The id of the footer
   */
  footerId;


  /**
   * The content of the header
   * @type {StructuralElement[]}
   */
  content;

  constructor({footerId, content}) {
    this.footerId = footerId;
    this.content = content;
  }

}
