
export default class Equation {
  /**
   * @type number[]
   */
  suggestedDeletionIds;
  /**
   * @type number[]
   */
  suggestedInsertionIds;

  constructor({suggestedDeletionIds, suggestedInsertionIds}) {
    this.suggestedInsertionIds = suggestedDeletionIds;
    this.suggestedInsertionIds = suggestedInsertionIds;
  }

  static parseDataFromObject(data) {
    return new Equation(data);
  }
}
