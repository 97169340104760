export default class NestingLevel {

  /**
   * Where to align the bullet points. either "START" or "END".
   * @type {string}
   */
  bulletAlignment;

  /**
   * The indentFirstLine dimenstion of the bullet point.
   * @type {Dimension}
   */
  indentFirstLine;

  /**
   * The indent start of the bullet point.
   * @type {Dimension}
   */
  indentStart;


  /**
   * which depth the glyph is at. denoted by "$N" where N is the depth.
   * @type {string}
   */
  glyphFormat;

  /**
   * The symbol of the bullet point.
   */
  glyphSymbol;

  /**
   * The type of the bullet point, either ROMAN, ALPHA, UPPER_ALPHA or DECIMAL.
   */
  glyphType;


  constructor({bulletAlignment, glyphFormat, glyphSymbol, glyphType, indentFirstLine, indentStart}) {
    this.bulletAlignment = bulletAlignment;
    this.glyphFormat = glyphFormat;
    this.glyphSymbol = glyphSymbol;
    this.glyphType = glyphType;
    this.indentFirstLine = indentFirstLine;
    this.indentStart = indentStart;
  }

}
