import {useContentStore} from "@/reader/store/content";
import Summary from "@/reader/model/summary/Summary";

export const actions = {

  /**
   * Set a new search string.
   * @param {String} searchString
   */
  setCurrentSearchString(searchString) {
    this.searchString = searchString;
    this.searchResults =  this.content.search(searchString);
  },
  /**
   * Set the current page
   * @param {number} page
   */
  setCurrentPageIndex(page) {
    this.currentPageIndex = page;
  },


  /**
   * Load a book into the content store.
   * @param bookId
   */
  async loadBook(bookId){
    //First we get the details summary.
    const store = useContentStore();

    // TODO this is a hack to prevent the book from reloading.
    // Make a better way of checking all the content is up to date, such as notes and questions.
    if(store.content && store.content?.detailSummary?.id?.toString() === bookId.toString()){
      return
    }

    // Clear old books values.
    store.content = null;
    store.detailSummary = null;
    store.notes = [];


    const promises = [
      store.fetchDetailSummary(bookId),
      store.fetchContent(bookId),
      store.fetchSummaryChapters(bookId),
    ]

    const [summary, content, chapters] = await Promise.all(promises);

    if(content === null || summary === null || chapters === null){
      console.error("was unable to load the book, missing sections are: ", content === null ? "content" : "", summary === null ? "summary" : "", chapters === null ? "chapters" : "");

      return;
    }

    content.setDetailSummary(summary);



    
    store.setContent(content);

    /** @type {QuestionListDetails[]} */
    const loadedQuestions = await store.FetchQuestionListsFromSummary(summary);
    content.matchQuestionListsToChapters(loadedQuestions,store.getSummaryChaptersFlattened);



    // set the questions in the document;


    store.setLoadedQuestionList(loadedQuestions);
  },

  /**
   * Set a new summary as the one currently being read.
   * @param {SummaryContent} data the new summary content that should be read as a document.
   */
  setContent(data) {
    this.content = data;
  },


  /**
   * Set the currently viewed details summary.
   * @param data
   */
  setDetailSummary(data) {
    console.log("setting detail summary", data);
    this.detailSummary = data
  },


  /**
   * Set the notes in the document.
   * @param {Note[]} notes
   */
  setNotes(notes){
    if(this.content.flattenedStructuralElements){
      notes.forEach(note => {
        note.updatePageNumber(this.content.flattenedStructuralElements);
      })
    }

    this.notes = notes;

  },

  /**
   * Add a new note to the document.
   * @param {Note} note
   */
  addNote(note){
    if(this.content.paragraphs){
      note.updatePageNumber(this.content.paragraphs);
    }
    this.notes.push(note);
  },
  removeNote(id){
    this.notes = this.notes.filter(note => note.id !== id);
  },
  setLoadedQuestionList(loadedQuestionList){
    this.loadedQuestionList = loadedQuestionList;
  },
  /**
   * Set the summary chapters.
   * @param {SummaryChapter[]} summaryChapters
   */
  setSummaryChapters(summaryChapters){
    this.summaryChapters = summaryChapters;
  },
  setSelectedSearchResult(searchResult) {
    this.selectedSearchResult = searchResult;
  },
  // Todo: Use pinia instance for resetting
  resetData() {
    this.content = null;
    this.currentPageIndex = 1;
    this.searchString = null;
    this.searchResults = [];
  }
}
