export default class QuestionList {
    constructor({id, summaryId, chapterId, title, description, sortIndex}) {
        this.id = id;
        this.title = title;
        this.summaryId = summaryId;
        this.chapterId = chapterId;
        this.description = description;
        this.sortIndex = sortIndex;
    }
    static parseDataFromObject(data) {
        return new QuestionList(data);
    }
}