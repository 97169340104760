import QuestionList from "@/models/question/QuestionList";
import QuestionListDetails from "@/models/question/QuestionListDetails";

export const actions = {
    setListOfQuestionsList(data) {
        this.listOfQuestionsList = data.map(questionList => QuestionList.parseDataFromObject(questionList));
    },
    setSelectedQuestionList(data) {
        this.selectedQuestionList = QuestionListDetails.parseDataFromObject(data);
    },
    resetSelectedQuestionList() {
        this.selectedQuestionList = null;
    }
}