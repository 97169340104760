export const ERROR_CODES = {
  DEFAULT: 'default',
  INVALID_CREDENTIALS: 'invalid_credentials',
  INVALID_GDOCS_LINK: 'validation_error',
  CONFLICT_PRODUCT_CODE: 'conflict_productcode',
  SYNC_FAILED: 'sync_failed',
  TOO_LARGE: 'forbidden_size_limit_exceeded',
  CONFLICT_EMAIL_IN_USE: 'conflict_email_in_use',
  SUMMARY_FETCH_FAILED: 'summary_fetch_failed',
  INVALID_RIGHTS: 'forbidden_no_access',
  NOT_FOUND: 'not_found_file',
  FOLDER_NOT_SUPPORTED: 'Folder URL is not supported',
}