import {createRouter, createWebHistory} from 'vue-router';
import modules from '@/router/modules';
import {ROUTE_NAMES_AUTH} from "@/router/modules/auth";
import accessToken from "@/utils/authenticationManager/tokens/AccessToken";
import {ROUTE_NAMES_SUMMARY} from "@/router/modules/summary";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: modules,
  scrollBehavior() {
    // Always scroll to the top of the page.
    return {top: 0};
  },
});

router.beforeEach(async (to, from, next) => {
  // User enters an invalid URL.
  if (to.matched.length === 0) {
    if (accessToken.value) {
      return next({name: ROUTE_NAMES_SUMMARY.OVERVIEW})
    }
    return next({name: ROUTE_NAMES_AUTH.LOGIN});
  }

  // User enters a valid URL.
  for (const route of to.matched) {
    if (route.meta && route.meta.guard) {
      /** Let each guard handle route access **/
      const result = await route.meta.guard.handleRouteEnter();
      if (result !== true) {
        // TODO: store.commit(SET_LOGOUT);
        return next({name: result});
      }
    }
  }

  return next();
});

export default router;
