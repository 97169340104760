export default class Answer {
    constructor({sortIndex, answerText, isCorrect}) {
        this.sortIndex = sortIndex;
        this.answerText = answerText;
        this.isCorrect = isCorrect;
    }

    setIsCorrect(value) {
        this.isCorrect = value;
    }

    setAnswerText(value) {
        this.answerText = value;
    }

    getIsCorrect() {
        return this.isCorrect;
    }

    static parseDataFromObject(data) {
        return new Answer(data);
    }
}