<template>
  <div v-if="user" class="user-details-wrapper">
    <PageTitle button-text="Wijzig gegevens"
               has-button
               has-return-button
               title="Gebruiker details"
               @on-click-btn="navigateToEdit"/>

    <p class="title">Details</p>

    <div v-for="name in userDetailNames" :key="name" class="info-wrapper">
      <p class="name">{{ name.value }}</p>
      <div class="text-wrapper">
        <p class="text">{{ displayDetailValues(name) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import {useUserStore} from "@/store/user";
import {useRoute, useRouter} from "vue-router";
import PageTitle from "@/components/partials/summary/PageTitle";
import {computed} from "vue";
import {ROUTE_NAMES_USERS} from "@/router/modules/users";
import {useRootStore} from "@/store";

export const userDetailNames = [
  {
    value: 'Voornaam',
    id: 'firstName'
  },
  {
    value: 'Achternaam',
    id: 'lastName'
  },
  {
    value: 'E-mailadres',
    id: 'email'
  },
  {
    value: 'Gebruikersrol',
    id: 'roleId'
  },
]

export default {
  name: "UserDetailOverview",
  components: {PageTitle},
  setup() {
    const userStore = useUserStore();
    const rootStore = useRootStore();
    const route = useRoute();
    const router = useRouter();
    const userId = route.params.userId;

    /** User **/
    const user = computed(() => userStore.getUser)
    getUser();

    function getUser() {
      userStore.getUserById(userId);
    }

    function displayDetailValues(value) {
      if (value.id === 'roleId') {
        return user.value.getRoleIdText();
      }
      return user.value[value.id];
    }

    function navigateToEdit() {
      router.push({name: ROUTE_NAMES_USERS.EDIT, params: {userId: userId}});
    }

    /** Breadcrumbs **/
    setBreadCrumbData();

    function setBreadCrumbData() {
      rootStore.setBreadcrumbName(ROUTE_NAMES_USERS.OVERVIEW);
    }

    return {
      navigateToEdit,
      user,
      displayDetailValues,
      userDetailNames
    }
  }
}
</script>

<style lang="scss" scoped>
.user-details-wrapper {
  margin-top: rem(54);

  .title {
    color: var(--main-font-color);
    font-size: rem(20);
    font-weight: bold;
    letter-spacing: 0;
    line-height: rem(25);
    margin: rem(40) 0 rem(20) 0;
  }

  .info-wrapper {
    display: flex;
    gap: rem(68);

    .name {
      color: var(--main-font-color);
      font-size: rem(16);
      font-weight: 500;
      letter-spacing: 0;
      line-height: rem(20);
      margin: 0 0 rem(20) 0;
      width: rem(96);
    }

    .text-wrapper {
      .text {
        color: var(--main-font-color);
        font-size: rem(16);
        letter-spacing: 0;
        line-height: rem(20);
        margin: 0 0 rem(20) 0;
      }
    }
  }
}
</style>