import University from "@/models/summary/University";
import SummaryDetails from "@/models/summary/SummaryDetails";
import SummaryChapter from "@/models/summary/SummaryChapter";
import SummaryChapterCluster from "@/models/summary/SummaryChapterCluster";
import SummaryTableFilter from "@/models/tables/SummaryTableFilter";

export const actions = {
    setDataResponse(paginationDataResponse) {
        this.paginationDataResponse = paginationDataResponse;
    },
    updateTableFilter(data){
        this.currentListFilter = data;
    },
    updateSelectedFilters(data){
        this.currentListFilter.bachelorYearNumber = data.bachelorYearNumber;
        this.currentListFilter.isPublished = data.isPublished;
    },
    updateSearchQuery(newQuery){
        this.currentListFilter.searchQuery = newQuery;
    },
    setSortingOption(newSortingOption){
        this.currentListFilter.sortingOption = newSortingOption;
    },
    updatePageNumber(newPageNumber){
      this.currentListFilter.pageNumber = newPageNumber;
    },
    clearFilters(){
        this.currentListFilter = SummaryTableFilter.empty();
    },
    setSelectedSummary(data) {
        this.selectedSummary = SummaryDetails.parseDataFromObject(data);
    },
    resetSelectedSummary() {
        this.selectedSummary = null;
    },
    setUniversities(data) {
        this.universities = data.map(university => University.parseDataFromObject(university));
    },
    setSummaryChapters(data) {
        this.summaryChapters = data.map(chapter => SummaryChapter.parseDataFromObject(chapter));

        this.summaryChapters.forEach((chapter, index) => this.summaryChapters[index].subChapters =
            chapter.subChapters.map(subChapter => SummaryChapterCluster.parseDataFromObject(subChapter)));

    }
}