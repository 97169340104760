export default class Dimension {

  /**
   * The magnitude of the dimension.
   * @type {number}
   */
  magnitude;


  /**
   * The unit of the dimension.
   * @type {string}
   */
  unit;


  constructor({magnitude, unit}) {
    this.magnitude = magnitude;
    this.unit = unit;
  }


}
