<template>
  <div v-if="selectedSummary" class="wrapper">
    <PageTitle :title="selectedSummary.title"
               has-return-button
               has-button
               button-text="Sla wijzigingen op"
               @on-click-btn="saveFreeFragments"/>
    <SummaryChapterList :is-draggable="false"
                        :is-droppable="false"
                        title="Gratis fragmenten selecteren"
                        has-checkbox
                        fragments
                        @checkbox-selected="checkboxSelected"/>
  </div>
</template>

<script>
import PageTitle from "@/components/partials/summary/PageTitle";
import {useRoute, useRouter} from "vue-router";
import {useSummaryStore} from "@/store/summary";
import {computed} from "vue";
import {ROUTE_NAMES_SUMMARY} from "@/router/modules/summary";
import {useRootStore} from "@/store";
import SummaryChapterList from "@/components/partials/summary/SummaryChapterList";

export default {
  name: "SummaryFragmentsOverview",
  components: {
    SummaryChapterList,
    PageTitle
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const summaryId = route.params.summaryId;
    const summaryStore = useSummaryStore();
    const rootStore = useRootStore();
    const chapterIds = [];

    /** Summary **/
    const selectedSummary = computed(() => summaryStore.getSelectedSummary);
    getSummaryDetails();

    function getSummaryDetails() {
      summaryStore.getSummaryDetails(summaryId);
    }

    function saveFreeFragments() {
      summaryStore.setFreeFragments({chapterIds: chapterIds}, summaryId).then((response) => {
        if (response) {
          router.push({name: ROUTE_NAMES_SUMMARY.DETAILS, params: {summaryId: summaryId}});
        }
      });
    }

    function checkboxSelected(chapterId) {
      if (chapterIds.includes(chapterId)) {
        chapterIds.splice(chapterIds.indexOf(chapterId), 1);
        return;
      }
      chapterIds.push(chapterId);
    }

    /** Breadcrumbs **/
    setBreadcrumbs();

    function setBreadcrumbs() {
      rootStore.setBreadcrumbParams({summaryId: summaryId});
      rootStore.setBreadcrumbName(ROUTE_NAMES_SUMMARY.DETAILS);
    }

    return {
      selectedSummary,
      saveFreeFragments,
      checkboxSelected
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  margin-top: rem(54);
}
</style>