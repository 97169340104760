import defaultMessageLangs from "@/utils/yo-validator/messages";
import RuleRequired from "@/utils/yo-validator/rules/RuleRequired";
import RuleEmail from "@/utils/yo-validator/rules/RuleEmail";
import RuleMaxSize from "@/utils/yo-validator/rules/RuleMaxSize";
import RuleMaxLength from "@/utils/yo-validator/rules/RuleMaxLength";
import RuleMax from "@/utils/yo-validator/rules/RuleMax";
import RuleMin from "@/utils/yo-validator/rules/RuleMin";
import RulePasswordConfirmation from "@/utils/yo-validator/rules/RulePasswordConfirmation";
import RuleExtension from "@/utils/yo-validator/rules/RuleExtension";
import RuleMinSize from "@/utils/yo-validator/rules/RuleMinSize";
import RuleMinLength from "@/utils/yo-validator/rules/RuleMinLength";
import RuleAlpha from "@/utils/yo-validator/rules/RuleAlpha";
import RuleAlphaNumeric from "@/utils/yo-validator/rules/RuleAlphaNumeric";
import RuleNumeric from "@/utils/yo-validator/rules/RuleNumeric";
import RulePositiveNumeric from "@/utils/yo-validator/rules/RulePositiveNumeric";
import RuleTelephoneNumber from "@/utils/yo-validator/rules/RuleTelephoneNumber";
import RulePasswordStrength from "@/utils/yo-validator/rules/RulePasswordStrength";

const DEFAULT_LANG = 'nl';
const DEFAULT_ERROR = 'Your {fieldName} is invalid';
const FIELD_NAME_PLACEHOLDER = '{fieldName}';

class ValidationRules {
  constructor() {
    this.selectLanguage = DEFAULT_LANG;
    this.messageLangs = defaultMessageLangs;
    this.ruleList = {
      required: new RuleRequired(),
      alpha: new RuleAlpha(),
      alphaNumeric: new RuleAlphaNumeric(),
      numeric: new RuleNumeric(),
      positiveNumeric: new RulePositiveNumeric(),
      email: new RuleEmail(),
      maxSize: new RuleMaxSize(),
      minSize: new RuleMinSize(),
      ext: new RuleExtension(),
      maxLength: new RuleMaxLength(),
      minLength: new RuleMinLength(),
      max: new RuleMax(),
      min: new RuleMin(),
      passwordConfirm: new RulePasswordConfirmation(),
      telephoneNumber: new RuleTelephoneNumber(),
      passwordStrength: new RulePasswordStrength(),
    };
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new ValidationRules();
    }
    return this.instance;
  }

  setSelectedLanguage(lang) {
    this.setValidationMessages(this.messageLangs, lang);
  }

  setValidationMessages(messageLangs, lang) {
    if (!lang || typeof lang !== 'string' || !this.messageLangs[lang.toLowerCase()]) {
      console.error('Invalid or not supported language passed, switched to default messages');
      return;
    }
    lang = lang.toLowerCase();
    /** check if the messages are valid, there must be at least 1 language **/
    if (!messageLangs || typeof messageLangs !== 'object' || Object.keys(messageLangs).length < 1) {
      console.error('Invalid messages passed, switched to default messages');
      this.selectLanguage = DEFAULT_LANG;
      this.messageLangs = defaultMessageLangs;
      return;
    }
    /** check if lang doesn't exist in passed messages **/
    if (!Object.keys(messageLangs).map(name => name.toLowerCase()).includes(lang)) {
      console.error(`Language doesn't exist in passed messages, switched to default messages`);
      this.selectLanguage = DEFAULT_LANG;
      this.messageLangs = defaultMessageLangs;
      return;
    }
    /** check if there is any unsupported language **/
    this.selectLanguage = lang;
    this.messageLangs = messageLangs;
  }

  getErrorMessage(fieldName, ruleName, ruleLimit) {
    /** define select and default packages **/
    const selectedPackage = this.messageLangs[this.selectLanguage];
    const defaultPackage = defaultMessageLangs[this.selectLanguage]
      ? defaultMessageLangs[this.selectLanguage]
      : defaultMessageLangs[DEFAULT_LANG];
    /** check if messages and rule name are defined **/
    if (!selectedPackage.messages || !selectedPackage.messages[ruleName]) {
      console.error(`Messages or rule name isn't defined, switched to default/general error`);
      const error = defaultPackage.messages[ruleName].default;
      return this.parseMessage(error, fieldName, ruleName, ruleLimit);
    }
    /** get error user defined: required.username > required.default > default: default.required **/
    let error = selectedPackage.messages[ruleName][fieldName]
      || selectedPackage.messages[ruleName].default
      || defaultPackage.messages[ruleName].default;
    return this.parseMessage(error, fieldName, ruleName, ruleLimit);
  }

  parseMessage(message, fieldName, ruleName, ruleLimit) {
    const selectedPackage = this.messageLangs[this.selectLanguage];
    // get defined username or just use the key name to replace
    fieldName = selectedPackage.names && selectedPackage.names[fieldName] ? selectedPackage.names[fieldName] : fieldName;
    // replace {fieldName}, for instance {fieldName} -> username
    message = message.replace(FIELD_NAME_PLACEHOLDER, fieldName);
    // replace {max} by 8 for instance, if needed
    const ruleValidator = this.ruleList[ruleName];
    // parse message according to different rules
    message = ruleValidator.parseMessage(message, ruleName, ruleLimit);
    return message;
  }

  getValidator(ruleName) {
    return this.ruleList[ruleName];
  }
}

export default ValidationRules.getInstance();
