export default class InlineObjectProperties {

  /**
   * The embedded object of this inline object.
   * @type {EmbeddedObject}
   */
  embeddedObject;

  constructor({embeddedObject}) {
    this.embeddedObject = embeddedObject;
  }


}
