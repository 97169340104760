<template>
  <div class="modal-wrapper" v-if="isVisible">
    <div class="backdrop"></div>
    <div class="modal">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    closeIcon: {
      type: String,
      default: ''
    },
    isVisible: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style scoped lang="scss">
.modal-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.backdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(rem(2));
}

.modal {
  background-color: var(--white-main);
  border-radius: rem(12);
  position: relative;
}
</style>
