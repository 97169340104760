import AdminGuard from '@/router/guards/admin';
import CreateUser from "@/views/manager/users/CreateUser";
import UserDetailOverview from "@/views/manager/users/UserDetailOverview";
import EditUser from "@/views/manager/users/EditUser";

const ManagerLayout = () => import('../../views/manager/Layout');
const Overview = () => import('../../views/manager/users/UsersOverview');


export const ROUTE_NAMES_USERS = Object.freeze({
    OVERVIEW: 'UsersOverview',
    CREATE: 'UserCreate',
    DETAILS: 'UserDetails',
    EDIT: 'UserEdit'
});

export const users = {
    path: '/users',
    meta: {
        guard: AdminGuard
    },
    component: ManagerLayout,
    children: [
        {
            path: 'overview',
            name: ROUTE_NAMES_USERS.OVERVIEW,
            component: Overview
        },
        {
            path: 'create',
            name: ROUTE_NAMES_USERS.CREATE,
            component: CreateUser
        },
        {
          path: 'edit/:userId',
          name: ROUTE_NAMES_USERS.EDIT,
          component: EditUser
        },
        {
            path: 'details/:userId',
            name: ROUTE_NAMES_USERS.DETAILS,
            component: UserDetailOverview
        }
    ]
}