import SummaryImage from "@/reader/model/summary/SummaryImage";
import QuestionList from "@/reader/model/question/QuestionList";
import SummaryEmbeddedImage from "@/reader/model/summary/SummaryEmbeddedImage";

export default class Summary {
  constructor({id, title, availableDate, bachelorYearNumber, periodNumber, periods, collegeStartYear, isPurchased, lastOpenedAt, lastPageId,images, embeddedImages, readPercentage, pageCount, examDate, description, hasFreeChapters, supplements, questionLists, readPageCount, readProgress,formulasImages}) {
    this.id = id
    this.title = title
    this.availableDate = availableDate
    this.bachelorYearNumber = bachelorYearNumber
    this.periodNumber = periodNumber
    this.periods = periods
    this.periodsString = periods.join(', ')
    this.collegeStartYear = collegeStartYear
    this.isPurchased = isPurchased
    this.lastOpenedAt = lastOpenedAt
    this.lastPageId = lastPageId
    this.images = images ? images.map((e) => SummaryImage.parseDataFromObject(e)) : [];
    this.embeddedImages = embeddedImages ? embeddedImages.map(SummaryEmbeddedImage.parseDataFromObject) : [];
    this.readPercentage = readPercentage
    this.readProgress = readProgress
    this.pageCount = pageCount
    this.examDate = examDate
    this.description = description
    this.hasFreeChapters = hasFreeChapters
    this.supplements = supplements
    /**
     * @type {QuestionList[]}
     */
    this.questionLists = questionLists ? questionLists.map((e) => QuestionList.parseDataFromObject(e)) : [];
    this.readPageCount = readPageCount;
    this.formulasImages = formulasImages ? formulasImages.map((e) => SummaryEmbeddedImage.parseDataFromObject(e)) : [];
  }

  static parseDataFromObject(data) {
    return new Summary(data);
  }
}
