import {ref} from "vue";
import Toast from "@/utils/handlers/toastHandler/Toast";

class ToastHandler {
  constructor() {
    this.toastQueue = ref([]); // All the toasts that are open.
  }

  get toasts() {
    return this.toastQueue.value;
  }

  /**
   * @param {ToastContent} content - The toast content.
   */
  addNew(content) {
    this.toastQueue.value.push(new Toast(content));
  }

  /**
   * Closes a toast based on the passed ID.
   * @param {Number} id - The ID of the toast.
   */
  closeToastById(id) {
    const indexRemoveElement = this.toastQueue.value.findIndex((item) => item.id === id);
    this.toastQueue.value.splice(indexRemoveElement, 1);
  }

  closeAll() {
    this.toastQueue.value = [];
  }

  static getInstance() {
    if (this.instance == null) {
      this.instance = new ToastHandler();
    }
    return this.instance;
  }
}

export default ToastHandler.getInstance();
