import {defineStore} from 'pinia'
import {actions} from './actions';
import {apiActions} from './apiActions';
import {getters} from './getters';

const state = () => ({
    listOfQuestionsList: [],
    selectedQuestionList: null
});

export const useQuestionStore = defineStore('question', {
    state,
    getters,
    actions: {...actions, ...apiActions}
})
