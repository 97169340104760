<template>
  <div v-if="summaryChapters" class="content-wrapper">
    <div v-if="title" class="top-section">
      <p class="page-title">{{ title }}</p>
      <p class="text">(De)selecteer alles</p>
      <Checkbox @checked="selectOrDeselectAll"/>
    </div>
    <!--Loop through chapters-->
    <div v-for="chapter in summaryChapters" :key="chapter.id" class="chapters-wrapper">
      <ChapterListItem ref="chapterListItemRef"
                       :chapter="chapter"
                       :has-checkbox="hasCheckbox"
                       :is-checkbox-checked="isCheckboxChecked(chapter.id)"
                       :is-droppable="isDroppable"
                       @lock-chapter="lockChapter"
                       @on-drop="onDrop"
                       @checkbox-selected="checkboxSelected"/>

      <div v-if="chapter.hasCluster"> <!--Check if this chapter has sub chapter(s)-->
        <div v-for="subChapter in chapter.subChapters" :key="subChapter.id">    <!--Loop through sub chapters-->
          <ChapterListItem ref="chapterListItemRef"
                           :chapter="subChapter"
                           :has-checkbox="hasCheckbox"
                           :is-checkbox-checked="isCheckboxChecked(subChapter.id)"
                           :is-droppable="isDroppable"
                           class="cluster-item"
                           @lock-chapter="lockChapter"
                           @on-drop="onDrop"
                           @checkbox-selected="checkboxSelected"/>

          <div v-if="subChapter.hasCluster">  <!--Check if this subchapter has subchapter(s)-->
            <div v-for="subChapterNested in subChapter.subChapters" :key="subChapterNested.id">
              <!-- Loop through nested subchapters-->
              <ChapterListItem ref="chapterListItemRef"
                               :chapter="subChapterNested"
                               :has-checkbox="hasCheckbox"
                               :is-checkbox-checked="isCheckboxChecked(subChapterNested.id)"
                               :is-droppable="isDroppable"
                               class="cluster-item-nested"
                               @lock-chapter="lockChapter"
                               @on-drop="onDrop"
                               @checkbox-selected="checkboxSelected"/>
              <div v-if="checkQuestionList(subChapterNested.id)">
                <!--Check if nested subchapter contains question list-->
                <ChapterListQuestionItem :is-draggable="isDraggable"
                                         :list-question-lists="getQuestionListsFor(subChapterNested.id)"
                                         class="cluster-item-nested"
                                         @start-drag="startDrag"/>
              </div>
            </div>
          </div>
          <div v-if="checkQuestionList(subChapter.id)"> <!--Check if subchapter contains question list-->
            <ChapterListQuestionItem :is-draggable="isDraggable"
                                     :list-question-lists="getQuestionListsFor(subChapter.id)"
                                     class="cluster-item"
                                     @start-drag="startDrag"/>
          </div>
        </div>
      </div>
      <div v-if="checkQuestionList(chapter.id)"> <!--Check if chapter contains question list-->
        <ChapterListQuestionItem :is-draggable="isDraggable"
                                 :list-question-lists="getQuestionListsFor(chapter.id)"
                                 @start-drag="startDrag"/>
      </div>
    </div>
    <!--    Loop through list of question lists to find a questionlist that is not assigned to a chapter-->
    <div v-for="questionList in listOfQuestionLists" :key="questionList.id">
      <ChapterListQuestionItem ref="chapterQuestionListRef"
                               :default-item="!questionList.chapterId ? questionList : null"
                               :is-draggable="isDraggable"
                               @start-drag="startDrag($event, questionList.id)"/>
    </div>
  </div>
</template>

<script>
import {useSummaryStore} from "@/store/summary";
import {useRoute}
  from "vue-router";
import {computed, ref} from "vue";
import {useQuestionStore} from "@/store/question";
import ChapterListQuestionItem from "@/components/partials/summary/chapterListItems/ChapterListQuestionItem";
import ChapterListItem from "@/components/partials/summary/chapterListItems/ChapterListItem";
import Checkbox from "@/components/elements/Checkbox";

export default {
  name: "SummaryChapterList",
  components: {
    Checkbox,
    ChapterListItem,
    ChapterListQuestionItem
  },
  props: {
    isDraggable: {
      required: false,
      default: true,
      type: Boolean,
    },
    isDroppable: {
      required: false,
      default: true,
      type: Boolean
    },
    hasCheckbox: {
      required: false,
      default: false,
      type: Boolean
    },
    title: {
      required: false,
      type: String
    },
    supplements: {
      required: false,
      type: Boolean,
      default: false
    },
    fragments: {
      required: false,
      type: Boolean,
      default: false
    },
  },
  emits: ['checkbox-selected'],
  setup(props, {emit}) {
    const summaryStore = useSummaryStore();
    const questionStore = useQuestionStore();
    const route = useRoute();
    const summaryId = route.params.summaryId;
    const chapterListItemRef = ref(null);


    /** Get summary chapters **/
    const summaryChapters = computed(() => summaryStore.getChapters);
    getSummaryChapters();
    const selectedCheckboxesIds = ref([]);

    function getSummaryChapters() {
      summaryStore.getSummaryChapters(summaryId).then(() => {
        if (props.supplements) {
          summaryChapters.value.forEach(chapter => findIds(chapter, 'isSupplement'));
        } else if (props.fragments) {
          summaryChapters.value.forEach(chapter => findIds(chapter, 'isFree'));
        } else {
          summaryChapters.value.forEach(chapter => findIds(chapter, 'isLocked'));
        }
      });
    }

    //Recursive function to find all ids of free supplements or fragments.
    function findIds(chapter, selector) {
      Object.keys(chapter).forEach(key => {
        if (chapter[selector] === 1 && key === selector) {
          selectedCheckboxesIds.value.push(chapter.id);
        }
        if (chapter.subChapters && chapter.subChapters.length && key === 'subChapters') {
          chapter.subChapters.forEach(chapter => findIds(chapter, selector));
        }
      });
    }

    function isCheckboxChecked(id) {
      return selectedCheckboxesIds.value.includes(id);
    }

    /** Get question lists **/
    const listOfQuestionLists = computed(() => questionStore.getListOfQuestionsList);
    getListQuestionlists();

    function getListQuestionlists() {
      questionStore.fetchListOfQuestionsList(summaryId);
    }

    /** Drag and drop **/
    function startDrag(event, questionListId) {
      event.dataTransfer.setData('questionListId', questionListId);
    }

    function onDrop(event, item) {
      const id = event.dataTransfer.getData('questionListId');

      if (checkQuestionListIdInItem(id, item.id)) {
        updateQuestionListPosition({questionListId: id, chapterId: (item.id - 1)});
        return;
      }

      updateQuestionListPosition({questionListId: id, chapterId: item.id});
    }

    function updateQuestionListPosition(payload) {
      questionStore.updateQuestionListPosition({questionListChapters: [payload]}, summaryId).then((response) => {
        if (response) {
          getSummaryChapters();
          getListQuestionlists();
        }
      });
    }

    function checkQuestionList(chapterId) {
      return listOfQuestionLists.value.filter(questionList => questionList.chapterId === chapterId).length > 0;
    }

    function checkQuestionListIdInItem(id, itemId) {
      return listOfQuestionLists.value.filter(questionList => questionList.id.toString() === id && questionList.chapterId === itemId).length === 1;
    }

    function getQuestionListsFor(chapterId) {
      return listOfQuestionLists.value.filter(questionList => questionList.chapterId === chapterId);
    }

    function checkboxSelected(chapterId) {
      emit('checkbox-selected', chapterId);
    }

    function selectOrDeselectAll() {
      chapterListItemRef.value.forEach(chapter => chapter.selectCheckbox());
    }

    function lockChapter(chapterId) {
      if (selectedCheckboxesIds.value.includes(chapterId)) {
        selectedCheckboxesIds.value.splice(selectedCheckboxesIds.value.indexOf(chapterId), 1);
      } else {
        selectedCheckboxesIds.value.push(chapterId);
      }

      summaryStore.setLockedChapter({chapterIds: selectedCheckboxesIds.value}, summaryId).then((response) => {
        if (response) {
          selectedCheckboxesIds.value = [];
          getSummaryChapters();
        }
      });
    }

    return {
      chapterListItemRef,
      summaryChapters,
      listOfQuestionLists,
      startDrag,
      onDrop,
      checkQuestionList,
      getQuestionListsFor,
      checkboxSelected,
      selectOrDeselectAll,
      isCheckboxChecked,
      selectedCheckboxesIds,
      lockChapter
    }
  }
}
</script>

<style lang="scss" scoped>
.content-wrapper {
  margin-bottom: rem(24);

  .top-section {
    display: flex;
    align-items: center;

    .page-title {
      color: var(--main-font-color);
      font-size: rem(20);
      font-weight: bold;
      letter-spacing: 0;
      line-height: rem(25);
      margin: rem(40) 0 rem(20) 0;
      flex-grow: 1;
    }

    .text {
      color: var(--blue-dark);
      font-size: rem(16);
      letter-spacing: 0;
      line-height: rem(18);
      margin-right: rem(16);
    }
  }
}
</style>
