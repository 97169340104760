export const USER_ROLE_NAMES = Object.freeze({
    ADMIN: 'admin',
    STUDY_MANAGER: 'Studiemanager',
    STUDY_HERO: 'Studieheld'
});

export default class User{
    constructor({id, email, name, firstName, middleName, lastName, roleId}) {
        this.id = id;
        this.email = email;
        this.name = name;
        this.firstName = firstName;
        this.middleName = middleName;
        this.lastName = lastName;
        this.roleId = roleId;
    }

    getRoleIdText() {
        if (this.roleId === 3) {
            return USER_ROLE_NAMES.ADMIN
        } else if (this.roleId === 4) {
            return USER_ROLE_NAMES.STUDY_MANAGER
        }
        return USER_ROLE_NAMES.STUDY_HERO
    }

    static parseDataFromObject(data) {
        return new User(data);
    }
}
