<template>
<div class="detail-overview-wrapper">
  <PageTitle title="Feedback details"
            is-white-button
            has-return-button
            has-button
            button-text="Bekijk pagina"
            @on-click-btn="navigateToRead"/>
  <FeedbackDetailsInfo :feedback="selectedFeedback"/>
  <ReplyToFeedback :feedback="selectedFeedback" @reply-message="sendFeedbackReply"/>
</div>
</template>

<script>
import PageTitle from "@/components/partials/summary/PageTitle";
import {useRoute, useRouter} from "vue-router";
import {useFeedbackStore} from "@/store/feedback";
import {computed} from "vue";
import {useRootStore} from "@/store";
import FeedbackDetailsInfo from "@/components/partials/feedback/FeedbackDetailsInfo";
import ReplyToFeedback from "@/components/partials/feedback/ReplyToFeedback";
import {ROUTE_NAMES_SUMMARY} from "@/router/modules/summary";
import {ROUTE_NAMES_FEEDBACK} from "@/router/modules/feedback";
import {useContentStore} from "@/reader/store/content";
export default {
  name: "FeedbackDetailOverview",
  components: {
    ReplyToFeedback,
    FeedbackDetailsInfo,
    PageTitle
  },
  setup () {
    const route = useRoute();
    const router = useRouter();
    const feedbackStore = useFeedbackStore();
    const feedbackId = route.params.feedbackId;
    const contentStore = useContentStore();
    const rootStore = useRootStore();

    /** Breadcrumbs **/
    setBreadCrumbData()
    function setBreadCrumbData() {
      rootStore.resetBreadcrumbName();
      rootStore.setBreadcrumbName(ROUTE_NAMES_FEEDBACK.OVERVIEW);
    }

    getFeedbackDetails();
    const selectedFeedback = computed(() => feedbackStore.getSelectedFeedback)

    function getFeedbackDetails() {
      feedbackStore.getFeedbackById(feedbackId).then(() => {
      })
    }

    function sendFeedbackReply(payload){
      feedbackStore.replyToFeedback(payload, selectedFeedback.value.id).then((response) => {
        if (response){
          getFeedbackDetails();
        }
      });
    }

    function navigateToRead() {
      contentStore.loadBook(selectedFeedback.value.summary.id);
      router.push({name: ROUTE_NAMES_SUMMARY.PREVIEW, params: {summaryId: selectedFeedback.value.summary.id}, query: {feedback: feedbackId}});
    }

    return {
      selectedFeedback,
      sendFeedbackReply,
      navigateToRead,
    }
  }
}
</script>

<style scoped lang="scss">
.detail-overview-wrapper {
  margin-top: rem(54);
}
</style>