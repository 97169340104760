///This is a representation of the paragraph json object gotten from google docs.
export default class Paragraph {


  /**
   *  The positioned object ids in the paragraph.
   *  @type {string[] | null}
   */
  positionedObjectIds;


  /**
   * The content of the paragraph.
   * @type {ParagraphElement[]}
   */
  elements;


  /**
   * The style of the paragraph
   * @type {ParagraphStyle}
   */
  paragraphStyle;


  /**
   * The bullet point of the paragraph.
   * @type {Bullet}
   */
  bullet;

  constructor({positionedObjectIds, elements, paragraphStyle, bullet}) {
    this.positionedObjectIds = positionedObjectIds;
    this.elements = elements;
    this.paragraphStyle = paragraphStyle;
    this.bullet = bullet;
  }

  get fullText() {
    return this.elements.map(element => element.textRun?.content ?? '').join('');
  }

  getHeadingTag() {
    const heading = this.paragraphStyle?.namedStyleType;
    if (heading) {
      return Headings[heading]?.tag ?? 'div';
    }
    return 'div';
  }
  
  /**
   * Clean content of unwanted characters
   */
  cleanContent() {
    this.elements.forEach(element => {
      if (element.textRun?.content && element.textRun.content.includes('\u000b')) {
        // \u000b is a page break
        element.textRun.content = element.textRun.content.replaceAll('\u000b', "<br>");
      }
    });
  }
  
  numberOfImages(){
    const imageCount =  this.elements.reduce((count, id) => {
      if (id.inlineObjectElement) {
        count++;
      }
      return count;
    }, 0) + (this.positionedObjectIds?.length ?? 0);
    return imageCount;
  }
  
  /**
   * get all inline images
   * @return {string[]}
   */
  allInlineImages(){
    return this.elements.map(element => element.inlineObjectElement?.inlineObjectId ?? null).filter(id => id !== null);
  }
  
  /**
   *
   * @param clientWidth
   * @param {AnnotatedDocument} fulldocument
   * @return {number}
   */
  estimateHeight(clientWidth,fulldocument) {
    const fullText = this.fullText;
    const fontSize = this.getHeadingTag() === 'div' ? 18 : 24;
    const fontHeight = fontSize;
    const fontWidth = fontSize / 1.8;
    const charactersPerLine = clientWidth / fontWidth;
    const lines = Math.ceil(fullText.length / charactersPerLine);
    const lineBreaks = 0;//fullText.split('\n').length - 1;
  
    const images = this.allInlineImages().concat(this.positionedObjectIds ?? []);
    const imageHeight = images.reduce((currentHeight,imageId) => {
      return Math.min(800, fulldocument.document.getObjectProperties(imageId)?.size?.height?.magnitude * 2 ?? 0);
    },0);
    
    const bulletScale = this.bullet ? 0.7 : 1;
    return (((lines + lineBreaks) * fontHeight) * bulletScale) + imageHeight;
  }
}


const Headings = Object.freeze({
  HEADING_1: {
    name: 'HEADING_1',
    priority: 1,
    tag: 'h1',
  },
  HEADING_2: {
    name: 'HEADING_2',
    priority: 2,
    tag: 'h2',
  },
  HEADING_3: {
    name: 'HEADING_3',
    priority: 3,
    tag: 'h3'
  },
  HEADING_4: {
    name: 'HEADING_4',
    priority: 4,
    tag: 'h4',
  }
});
