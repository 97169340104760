import StructuralElement from "../document/StructuralElement";

export default class AnnotatedStructuralElement extends StructuralElement {


  /**
   * @type {number} index of the structural element in document.
   */
  index;


  /**
   * The bullet point of the structural element.
   * @type {Bullet}
   */
  bullet;


  /**
   * The page the paragraph is on.
   * @type {Page} the page the paragraph is on.
   */
  page;

  /**
   * The containing chapter of the paragraph.
   * @type {Chapter}
   */
  containingChapter;


  constructor({startIndex, endIndex, paragraph, sectionBreak, table, tableOfContents, equation}) {
    super({startIndex, endIndex, paragraph, sectionBreak, table, tableOfContents, equation});
  }


  /**
   * Get the child structural elements of the structural element.
   * @return {AnnotatedStructuralElement[]}
   */
  getChildStructuralElements(includeThis = false) {
    if (includeThis) {
      return [this, ...this.getChildStructuralElements(false)];
    }
    if (this.table) {
      return this.table.getChildStructuralElements;
    } else if (this.tableOfContents) {
      return this.tableOfContents.getChildStructuralElements;
    }
    return [];
  }

  setContainingChapter(containingChapter) {
    this.containingChapter = containingChapter;
  }

  setIndex(index) {
    this.index = index;
  }


  /**
   * Sets the page the paragraph is on.
   * @param {Page} page
   */
  setPage(page) {
    this.page = page;
  }

  get fullText() {
    return this.paragraph?.fullText ?? '';
  }


}





