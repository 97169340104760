export default class ImageProperties {

  /**
   * The URI of the image with a default lifetime of 30 minutes.
   * This URI is tagged with the account of the requester.
   * Anyone with the URI effectively accesses the image as the original requester.
   * Access to the image may be lost if the document's sharing settings change.
   * @type {string}
   */
  contentUri;

  /**
   * The source URI of the image, can be empty
   */
  sourceUri;

  /**
   * The brightness of the image.
   * The value should be in the interval [-1.0, 1.0], where 0 means no change.
   * @type {number}
   */
  brightness;

  /**
   * The contrast of the image.
   * The value should be in the interval [-1.0, 1.0], where 0 means no change.
   */
  contrast;

  /**
   * The transparency of the image.
   * The value should be in the interval [0.0, 1.0], where 0 means no change.
   */
  transparency;

  /**
   * The crop properties of the image.
   * @type {CropProperties}
   */
  cropProperties;

  /**
   * The angle the image is rotated relative to the document.
   * The value should be in the interval [0, 2pi), where 0 means no rotation.
   * @type {number}
   */
  angle;

  constructor({contentUri, sourceUri, brightness, contrast, transparency, cropProperties, angle}) {
    this.contentUri = contentUri;
    this.sourceUri = sourceUri;
    this.brightness = brightness;
    this.contrast = contrast;
    this.transparency = transparency;
    this.cropProperties = cropProperties;
    this.angle = angle;
  }
  
  /**
   * Convert the image properties to a css style string, including the sizing, transparency and the cropping.
   */
  convertToStyle() {
    const cropStyleString = this.cropProperties.convertToCssStyleString();
    const styleString = cropStyleString +  "opacity: " + this.transparency + "; object-fit: cover; ";
    console.log(styleString);
    return styleString;
  }

}
