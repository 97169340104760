<template>
  <Modal :is-visible="isVisible">
    <div class="delete-user-wrapper">
      <p class="title">Gebruiker verwijderen</p>
      <p class="text">Weet je zeker dat je deze gebruiker wil verwijderen? De gebruiker verliest toegang tot het CMS.</p>
      <DButton @click="$emit('delete-user')"
               button-text="Verwijder gebruiker"
               button-type="button"
               class="modal-button modal-button-red"/>
      <p @click="$emit('close')" class="cancel-text">Annuleren</p>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/partials/Modal";
import DButton from "@/components/elements/DButton";

export default {
  name: "DeleteUserModal",
  components: {
    DButton,
    Modal
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  emits: ['delete-user', 'close']
}
</script>

<style scoped lang="scss">
.delete-user-wrapper {
  display: flex;
  flex-direction: column;
  padding: rem(45) rem(64) rem(32) rem(64);
  align-items: center;
  text-align: center;
  width: rem(493);

  .title {
    color: var(--main-font-color);
    font-size: rem(18);
    font-weight: bold;
    letter-spacing: 0;
    line-height: rem(23);
    margin: 0 0 rem(20) 0;
  }

  .text {
    color: var(--main-font-color);
    font-size: rem(14);
    letter-spacing: 0;
    line-height: rem(24);
    margin: 0 0  rem(24) 0;
  }

  .cancel-text {
    @include hover-active-pointer;
    color: var(--orange-main);
    font-size: rem(16);
    font-weight: bold;
    letter-spacing: 0;
    line-height: rem(19);
    text-decoration: underline;
    margin: rem(24) 0 0 0;
  }
}
</style>