export default class List {

  /**
   * The properties of the list.
   * @type {ListProperties}
   */
  listProperties;

  listId;


  constructor({listProperties,listId}) {
    this.listProperties = listProperties;
    this.listId = listId;
  }
}
