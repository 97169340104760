export default class TableCell {


  /**
   * The content of the table cells.
   * @type {StructuralElement[]}
   */
  content;


  /**
   * The style of the table cells.
   * @param {TableCellStyle[]} content
   */
  tableCellStyle;


  constructor({content, tableCellStyle}) {
    this.content = content;
    this.tableCellStyle = tableCellStyle;
  }

  get getChildStructuralElements() {
    if (this.content) {
      return this.content.map(content => content.getChildStructuralElements(true)).flat();
    }
    return [];
  }


  estimateHeight(clientWidth,fullDocument) {
    return this.content.reduce((total, element) => total + element.estimateHeight(clientWidth,fullDocument), 0);
  }


}
