export default class Bullet {


  /**
   * The ID of the list this paragraph belongs to.
   * @type {string}
   */
  listId;

  /**
   * The nesting levels of the bullet points.
   * @type {int}
   */
  nestingLevel;

  /**
   * The text style of the bullet point.
   * @type {TextStyle}
   */
  textStyle

  positionInList = 1;

  constructor({listId, nestingLevel, textStyle}) {
    this.listId = listId;
    this.nestingLevel = nestingLevel;
    this.textStyle = textStyle;
  }

  setPositionInList(positionInList) {
    this.positionInList = positionInList;
  }
}
