export default class InlineObject {

  objectId;


  /**
   * The properties of the inline object.
   * @type {InlineObjectProperties}
   */
  inlineObjectProperties;


  constructor({objectId, inlineObjectProperties}) {
    this.objectId = objectId;
    this.inlineObjectProperties = inlineObjectProperties;
  }


}
