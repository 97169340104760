export default class Feedback {
    constructor({id, createdAt, closedAt, feedbackText, summaryTitle, status, summaryProductCode}) {
        this.id = id;
        this.createdAt = createdAt;
        this.closedAt = closedAt;
        this.feedbackText = feedbackText;
        this.summaryTitle = summaryTitle;
        this.status = status;
        this.summaryProductCode = summaryProductCode;
    }

    get isFeedbackRepliedTo() {
        return !!this.status;
    }

    static parseDataFromObject(data) {
        return new Feedback(data);
    }
}
