<template>
  <img :src="img" alt="img" class="img">
</template>

<script>
import {computed} from "vue";

export default {
  name: "DisplayedIcon",
  props: {
    displayedSrc: {
      type: String,
      required: true
    },
    activeSrc: {
      type: String,
      required: true
    },
    isActive: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  setup(props) {
    return {
      /** Displayed image **/
      img: computed(() => props.isActive ? props.activeSrc : props.displayedSrc)
    }
  }
}
</script>

<style scoped>

</style>