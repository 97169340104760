export default class Table {

  /**
   * The number of columns in the table.
   * @type {number}
   */
  columns;

  /**
   * The number of rows in the table.
   * @type {number}
   */
  rows;

  /**
   * The contents and style of each row in the table.
   * @type {TableRow[]}
   */
  tableRows;

  get getChildStructuralElements() {
    if (this.tableRows) {
      return this.tableRows.map(row => row.getChildStructuralElements).flat();
    }
    return [];
  }

  constructor(columns, rows, tableRows) {
    this.columns = columns;
    this.rows = rows;
    this.tableRows = tableRows;
  }


  estimateHeight(clientWidth,fullDocument){
    return this.tableRows.reduce((total, row) => total + row.estimateHeight(clientWidth,fullDocument), 0);
  }

}




