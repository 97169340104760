export default class Size {


  /**
   * The height of the object.
   * @type {Dimension}
   */
  height;

  /**
   * The width of the object.
   * @type {Dimension}
   */
  width;

  constructor({height, width}) {
    this.height = height;
    this.width = width;
  }


}
