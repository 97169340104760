import {useRootStore} from "@/store/index";
import apiHandler from "@/utils/handlers/ApiHandler";

export const apiActions = {
    uploadPicture(payload) {
        const rootStore = useRootStore();
        const formData = new FormData();
        formData.append('file', payload)

        return apiHandler.post(`media/upload-picture`, formData).then(response => {
            if (apiHandler.isSuccess(response.status)) {
                return response.data;
            }
            return false;
        }).catch(error => {
            rootStore.setApiError(error);
            return false;
        });
    }
}
