<template>
  <button :type="buttonType"
          class="d-button button-main">
    <!-- Button icon -->
    <img v-if="icon" :src="icon" class="button-icon" alt=""/>

    <!-- Button text -->
    <span class="btn-text">{{ buttonText }}</span>
  </button>
</template>

<script>

export default {
  name: "DButton",
  props: {
    styleType: {
      default: 'primary'
    },
    buttonText: {
      required: true,
      type: String
    },
    buttonType: {
      required: true,
      type: String
    },
    icon: {
      type: String,
      required: false
    }
  }
}
</script>

<style scoped lang="scss">
.d-button {
  align-items: center;
  display: flex;
  justify-content: center;

  .button-icon {
    width: rem(16);
    margin-right: rem(10);
  }

  &.white-button {
    background-color: var(--white-main);
    border: rem(2) solid var(--orange-main);

    .btn-text {
      color: var(--orange-main);
    }
  }

  &.fail-sync {
    background-color: var(--red-modal);
  }

  &.success-sync {
    background-color: var(--green-light);

    .button-icon {
      width: rem(22);
    }
  }
}

.page-title-button {
  height: rem(41);
  width: rem(240);

  .btn-text {
    font-size: rem(14);
    letter-spacing: 0;
    line-height: rem(17);
  }
}

.no-results-btn {
  height: rem(41);
  width: rem(256);

  .btn-text {
    font-size: rem(14);
    letter-spacing: 0;
    line-height: rem(17);
  }
}

.create-new-button {
  height: rem(41);
  width: rem(350);

  .btn-text {
    font-size: rem(14);
    letter-spacing: 0;
    line-height: rem(17);
  }
}

.send-msg-btn {
  width: rem(240);
  height: rem(41);
}

.modal-button {
  height: rem(48);
  width: rem(294);
}

.show-example-btn {
  height: rem(41);
  width: rem(160);
}

.upload-btn {
  height: rem(41);
  width: rem(160);
}

.question-list-btn {
  height: rem(41);
  width: rem(220);

  .btn-text {
    font-size: rem(14);
    font-weight: bold;
    letter-spacing: 0;
    line-height: 17px;
  }
}

.sync-btn {
  opacity: 0.5;
}
</style>