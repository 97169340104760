export default class PaginatedResponse{

    /** @type {number} */
    totalItemCount;

    /** @type {number} */
    totalOpenFeedbackPoints;
    
    /** @type {number} */
    currentPage;
    
    /** @type {number} */
    totalPages;
    
    /** @type {any[]} */
    data;
    
    
    constructor(totalItemCount, totalOpenFeedbackPoints, currentPage, totalPages, data) {
        console.log("creating new ");
        this.totalItemCount = totalItemCount;
        this.totalOpenFeedbackPoints = totalOpenFeedbackPoints;
        this.currentPage = currentPage;
        this.totalPages = totalPages;
        this.data = data;
    }
    
    /**
     *
     * @param response {object} - response from api
     * @param dataParser {function} - function that parses data from response individually.
     * @return {PaginatedResponse} - parsed data
     */
    static parseDataFromApiResponse(response, dataParser){
        return new PaginatedResponse(response.totalItemCount, response.totalOpenFeedbackPoints, response.currentPage, response.totalPages, response.data.map(dataParser));
    }
    
}