<template>
  <div v-if="isCreate || selectedQuestionList" class="create-wrapper">
    <PageTitle has-return-button :title="title"/>
    <p class="title">Inleiding</p>
    <FormWrapper :send-form-data="validateForm">
      <template #form-content>
        <div class="form-top-section">
          <BasicInputField :value="selectedQuestionList?.title"
                           class="chapter-title form-top-field"
                           field-name="title"
                           label="Titel vragenlijst"
                           placeholder="Voer de titel van het hoofdstuk in"
                           rules="required"/>
          <BasicTextField :value="selectedQuestionList?.description"
                          class="form-top-field"
                          field-name="description"
                          label="Beschrijving"
                          placeholder="Voer de beschrijving in"
                          rules="required"/>
          <RadioGroup :value="selectedQuestionList?.answerDisplaying"
                      :options="radioOptions"
                      class="radio-group"
                      field-name="answerDisplaying"
                      select-default/>
        </div>

        <div v-for="amount in amountOfQuestions" :key="amount" class="questionnaire-wrapper">    
          <div class="title-wrapper">     
            <p class="question-title">Vraag {{ amount }}</p>
            <img v-if="amountOfQuestions > 1" src="@/assets/icons/ic_delete.svg" alt="icon" class="delete-question" @click="removeQuestion(amount-1)">
          </div>

          <BasicTextField :value="questions[amount - 1]?.questionText"
                          :field-name="`questionText-${amount}`"
                          class="question"
                          custom-error-msg="Het invoeren van de vraag is vereist."
                          :has-error="showQuestionTextErrorMsg"
                          label="Vraag"
                          placeholder="Voer de vraag in"
                          rules=""
                          @update-parent="questions[amount - 1].questionText = $event"/>

          <div class="file-input-wrapper">
            <div class="selected-img-wrapper" v-if="questions[amount-1].pictureUrl">
              <p class="label">Foto (optioneel)</p>
              <img :src="`${appHost}${questions[amount-1].pictureUrl}`" alt="img" class="img">
              <img src="@/assets/icons/ic_delete.svg" alt="icon" class="delete-icon"
                   @click="removeImg(amount-1, 'pictureUrl', 'pictureReference')">
            </div>

            <div class="file-wrapper" v-else>
              <FileUploader :field-name="`pictureUrl-${amount}`"
                            additional-text="Upload een .png of .jpg bestand van max. 1mb."
                            label="Foto (optioneel)"
                            @upload-file="uploadFile"
                            upload-on-selection
                            @has-errors="handleFileError"
              />  
            </div>

            <BasicInputField v-if="questions[amount-1][`pictureUrl`]"
                             :value="questions[amount-1]?.pictureReference"
                             :field-name="`pictureReference-${amount}`"
                             label="Bronvermelding (optioneel)"
                             placeholder="Voer een bronvermelding in"
                             class="reference-text"/>
          </div>
          <p v-if="showFileError" class="file-error-message">Upload een .png of .jpg bestand van max. 1mb.</p>

          <p class="answer-title">Antwoord</p>
          <p class="answer-description">Selecteer het juiste antwoord</p>

          <CheckboxAndInput v-for="(answer, index) in answers"
                            :key="index"
                            :un-check="unCheckCheckbox?.amount === amount && unCheckCheckbox?.key === index"
                            :display-error-msg="false"
                            :field-name="`Answer${answer}-${amount}`"
                            :label="`Antwoord ${answer}`"
                            :placeholder="`Voer antwoord ${answer} in`"
                            :value="getAnswerInfo(amount, index)"
                            rules=""
                            @error="displayAnswersErrorMsg"
                            @error-cleared="resetDisplayAnswersError"
                            @update-parent="setAnswerInfo($event, index, amount)"/>

          <p v-if="showAnswersErrorMsg || showError" class="error-message">Het invoeren van alle vier de antwoorden en het selecteren van het juiste antwoord is vereist.</p>
          <p class="explanation-title">Toelichting</p>

          <BasicTextField :value="questions[amount - 1]?.feedbackText"
                          :field-name="`feedbackText-${amount}`"
                          class="question"
                          custom-error-msg="Het invoeren van de toelichting is vereist."
                          label="Toelichting antwoord"
                          placeholder="Voer de toelichting van het antwoord in"
                          rules=""
                          :has-error="showFeedbackTextErrorMsg"
                          @update-parent="questions[amount-1].feedbackText = $event" />

          <div class="file-input-wrapper">
            <div class="selected-img-wrapper" v-if="questions[amount-1]?.feedbackPictureUrl">
              <p class="label">Foto (optioneel)</p>
              <img :src="`${appHost}${questions[amount-1].feedbackPictureUrl}`" alt="img" class="img">
              <img src="@/assets/icons/ic_delete.svg" alt="icon" class="delete-icon"
                   @click="removeImg(amount-1, 'feedbackPictureUrl', 'feedbackPictureReference')">
            </div>

            <div class="file-wrapper" v-else>
              <FileUploader :field-name="`feedbackPictureUrl-${amount}`"
                            additional-text="Upload een .png of .jpg bestand van max. 1mb."
                            label="Foto (optioneel)"
                            @upload-file="uploadFile"
                            upload-on-selection
                            @has-errors="handleFileError"
                            />
            </div>

            <BasicInputField v-if="questions[amount-1][`feedbackPictureUrl`]"
                             :value="questions[amount-1]?.feedbackPictureReference"
                             :field-name="`feedbackPictureReference-${amount}`"
                             label="Bronvermelding (optioneel)"
                             placeholder="Voer een bronvermelding in"
                             class="reference-text"/>
          </div>
          <p v-if="showFileError" class="file-error-message">Upload een .png of .jpg bestand van max. 1mb.</p>
        </div>
        <div class="line"></div>
      </template>

      <template #button-submit>
        <div :class="['btn-wrapper',{'sticky' : selectedQuestionList}]">
          <DButton button-text="Voeg nieuwe vraag toe" button-type="button" class="question-list-btn"
                   @click="addNewQuestion"/>
          <DButton button-text="Sla vragenlijst op" button-type="submit" class="question-list-btn white-button"/>
        </div>
      </template>
    </FormWrapper>
  </div>
</template>
<script>
import PageTitle from "@/components/partials/summary/PageTitle";
import FormWrapper from "@/components/yo-form/FormWrapper";
import DButton from "@/components/elements/DButton";
import BasicInputField from "@/components/yo-form/BasicInputField";
import BasicTextField from "@/components/yo-form/BasicTextField";
import FileUploader from "@/components/yo-form/FileUploader";
import CheckboxAndInput from "@/components/yo-form/CheckboxAndInput";
import {ref} from "vue";
import Answer from "@/models/question/Answer";
import RadioGroup from "@/components/yo-form/RadioGroup";
import Question from "@/models/question/Question";
import QuestionListDetails from "@/models/question/QuestionListDetails";
import {useRootStore} from "@/store";

export default {
  name: "CreateOrEditQuestionList",
  props: {
    title: {
      type: String,
      required: true,
    },
    isCreate: {
      type: Boolean,
      required: false,
      default: false
    },
    selectedQuestionList: {
      type: QuestionListDetails,
      required: false
    }
  },
  components: {
    FileUploader,
    PageTitle,
    FormWrapper,
    DButton,
    BasicTextField,
    BasicInputField,
    RadioGroup,
    CheckboxAndInput
  },
  emits: ['create', 'edit'],
  setup(props, {emit}) {
    const store = useRootStore();
    const radioOptions = [
      {
        id: 2,
        name: 'Toon antwoorden vragen tussendoor'
      },
      {
        id: 1,
        name: 'Toon antwoorden na beantwoorden alle vragen'
      }]
    const amountOfQuestions = ref(1);
    const answers = ['A', 'B', 'C', 'D'];
    const questions = ref([]);
    const appHost = process.env.VUE_APP_API_URL;
    const showError = ref(false);
    const hasFileError = ref(false);
    const showFileError = ref(false);
    const unCheckCheckbox = ref(null);

    /** Create **/
    if (props.isCreate) {//Create first question if not editable.
      createQuestionList();
    }

    function createQuestionList() { //Create a question list on click new question list.
      questions.value.push(Question.parseDataFromObject({
        questionText: '',
        feedbackText: '',
        answers: answers.map((answer, i) => Answer.parseDataFromObject({
          answerText: '',
          isCorrect: 0,
          sortIndex: i
        })),
        feedbackPictureUrl: '',
        pictureUrl: '',
        pictureReference: '',
        feedbackPictureReference: ''
      }));
    }

    /** Edit **/
    isEdit();

    function isEdit() {
      if (props.selectedQuestionList) {
        props.selectedQuestionList.questions.forEach(question => questions.value.push(question));
        amountOfQuestions.value = props.selectedQuestionList.questions.length;
      }
    }

    function getAnswerInfo(questionIndex, answerIndex) { //Checkbox value and input value of every answer
      return [
        questions.value[questionIndex - 1]?.answers[answerIndex].isCorrect,
        questions.value[questionIndex - 1]?.answers[answerIndex].answerText
      ]
    }

    function setAnswerInfo(value, key, selectedQuestion) {
      questions.value[selectedQuestion - 1].answers.forEach(answer => {//Get the right answers array from the map for the corresponding question
        if (answer.sortIndex === key) {
          if (Array.isArray(value)) { // checkbox value and input value
            answer.setIsCorrect(value[0] ? 1 : 0);
            answer.setAnswerText(value[1]);
          }
        }
      });

      const getOtherCorrect = questions.value[selectedQuestion - 1].answers.filter(answer => answer.isCorrect === 1 && answer.sortIndex !== key);
      if (getOtherCorrect.length && !value[2]) { //Only one correct answer
        getOtherCorrect[0].setIsCorrect(0);
        unCheckCheckbox.value = { amount: selectedQuestion, key: getOtherCorrect[0].sortIndex };
      }
    }

    function setFileInfo(fieldName, url) {
      if (url && fieldName) {
        questions.value[(fieldName.charAt(fieldName.length - 1)) - 1][fieldName.split('-')[0]] = url;
      }
    }

    function uploadFile(data) {
      store.uploadPicture(data.file).then((response) => {
        if (response) {
          setFileInfo(data.fieldName, response.fileName);
        }
      });
    }

    function removeImg(value, fieldName, reference) {
      questions.value[value][fieldName] = '';
      questions.value[value][reference] = '';
    }

    function removeQuestion(index) {
      questions.value = questions.value.filter((question, i) => i !== index);
      amountOfQuestions.value = questions.value.length;
    }

    function validateForm(formData) {
      if (allQuestionsCorrectSelected() && !hasFileError.value) {
        const payload = {
          title: formData.title,
          description: formData.description,
          answerDisplaying: formData.answerDisplaying.id
        };
        payload.questions = questions.value;
        payload.questions.forEach((question, i) => {
          question.questionText = formData[`questionText-${i + 1}`];
          question.feedbackText = formData[`feedbackText-${i + 1}`];
          question.pictureReference = formData[`pictureReference-${i + 1}`] ? formData[`pictureReference-${i + 1}`] : '';
          question.feedbackPictureReference = formData[`feedbackPictureReference-${i + 1}`] ? formData[`feedbackPictureReference-${i + 1}`] : '';
        });

        if (props.isCreate) {
          emit('create', payload);
        } else {
          emit('edit', payload);
        }
      } else if(hasFileError.value) {
        showFileError.value = true;
      } else {
        showError.value = true;
      }
    }

    function handleFileError(value) {
      if(value) {
        // Hide error message, because the fileuploader has it's own message
        // Message is only shown after submitting the form
        showFileError.value = false;
        hasFileError.value = true;
      } 
    }

    function allQuestionsCorrectSelected() {
      // Set errors on false
      showQuestionTextErrorMsg.value = false;
      showFeedbackTextErrorMsg.value = false;
      // Check questionText
      const hasQuestionText = questions.value.every(question => question.questionText);
      if(!hasQuestionText) {
        showQuestionTextErrorMsg.value = true;
        return false;
      }
      // Check answers
      let index = 0;
      questions.value.forEach(question => question.answers.filter(answer => answer.getIsCorrect() ? index = index + 1 : null));
      if(index !== questions.value.length) {
        return false;
      } 
      // Check feedbackText
      const hasFeedbackText = questions.value.every(question => question.feedbackText);
      if(!hasFeedbackText) {
        showFeedbackTextErrorMsg.value = true;
        return false;
      }
      return true;
    }

    /** Add question list on click **/
    function addNewQuestion() {
      amountOfQuestions.value += 1;
      createQuestionList();
    }

    /** Question text error */
    const showQuestionTextErrorMsg = ref(false);

    /** Feedback text error */
    const showFeedbackTextErrorMsg = ref(false);

    /** Answers error **/
    const showAnswersErrorMsg = ref(false);

    function displayAnswersErrorMsg() {
      showAnswersErrorMsg.value = true;
    }

    function resetDisplayAnswersError() {
      showAnswersErrorMsg.value = false;
    }

    return {
      setAnswerInfo,
      validateForm,
      answers,
      addNewQuestion,
      removeQuestion,
      radioOptions,
      amountOfQuestions,
      showQuestionTextErrorMsg,
      showFeedbackTextErrorMsg,
      displayAnswersErrorMsg,
      showAnswersErrorMsg,
      resetDisplayAnswersError,
      getAnswerInfo,
      questions,
      appHost,
      removeImg,
      uploadFile,
      showError,
      unCheckCheckbox,
      hasFileError,
      showFileError,
      handleFileError,
    }
  }
}
</script>
<style lang="scss" scoped>
.create-wrapper {
  margin-top: rem(54);

  .title {
    color: var(--main-font-color);
    margin: rem(43) 0 rem(20) 0;
    font-size: rem(20);
    font-weight: bold;
    letter-spacing: 0;
    line-height: rem(25);
  }

  .form-top-section {
    max-width: rem(350);

    .form-top-field:not(:last-of-type) {
      margin-bottom: rem(20);
    }

    ::v-deep .form-group-inner {
      flex-direction: column;
      gap: rem(20);
    }
  }

  .questionnaire-wrapper {
    width: rem(688);
    margin-top: rem(40);

    .title-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 0 rem(20) 0;
      width: rem(350);
    }

    .question-title {
      color: var(--main-font-color);
      font-size: rem(20);
      font-weight: bold;
      letter-spacing: 0;
      line-height: rem(25);
    }

    .delete-question {
      margin-left: rem(8);
    }

    .question {
      width: rem(350);
    }

    .file-input-wrapper {
      display: flex;
      align-items: center;
      gap: rem(40);
      margin: rem(20) 0 0 0;

      .file-wrapper {
        position: relative;
        height: rem(105);
        width: rem(600);
        margin-bottom: rem(50);
      }

      .reference-text {
        flex-grow: 1;
      }

      .selected-img-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;

        .delete-icon {
          @include hover-active-pointer;
          position: absolute;
          right: rem(16);
          top: rem(40);
          background: white;
          border-radius: 50%;
          padding: rem(5);
        }

        .img {
          height: rem(105);
          width: rem(350);
          object-fit: cover;
        }
      }
    }

    .answer-title, .explanation-title {
      color: var(--main-font-color);
      font-size: rem(16);
      font-weight: bold;
      letter-spacing: 0;
      line-height: rem(19);
    }

    .explanation-title {
      margin: 0 0 rem(20) 0;
    }

    .answer-title {
      margin: rem(20) 0 rem(8) 0;
    }

    .answer-description {
      opacity: 0.6;
      color: var(--main-font-color);
      font-size: rem(14);
      letter-spacing: 0;
      line-height: rem(16);
      margin: 0 0 rem(20) 0;
    }

    .input-outer-wrapper {
      margin-bottom: rem(20);
    }

    .error-message {
      margin: rem(-12) 0 rem(18) rem(54);
    }
    
    .file-error-message {
      color: var(--red-error);
      font-size: rem(14);
    }
  }

  .line {
    height: rem(8);
    transform: scaleY(-1);
    background-color: var(--grey-light-02);
    margin: rem(32) 0 rem(20) 0;
  }

  .btn-wrapper {
    display: flex;
    gap: rem(20);
    margin-bottom: rem(24);

    &.sticky {
      height: rem(81);
      background-color: var(--white-main);
      box-shadow: 0 rem(-4) rem(10) 0 rgba(70, 70, 70, 0.1);
      align-items: center;
      position: sticky;
      bottom: 0;
      margin: 0 rem(-28) 0 rem(-40);

      .question-list-btn:first-of-type {
        margin-left: rem(40);
      }
    }
  }
}
</style>