import BaseTableFilter from "@/models/tables/baseTableFilter";

export default class SummaryTableFilter extends BaseTableFilter {
    
    
    /** @type {number | null} */
    bachelorYearNumber = null;
    
    
    /** @type {number | null} */
    isPublished = null;
    

    
    constructor(pageNumber,bachelorYearNumber, isPublished, searchQuery, sortingOption) {
        super(pageNumber, searchQuery, sortingOption);
        this.bachelorYearNumber = bachelorYearNumber;
        this.isPublished = isPublished;
    }
    
    static empty() {
        return new SummaryTableFilter(1,null, null, null, null);
    }
    
    isEmpty(){
        return this.bachelorYearNumber === null && this.isPublished === null && super.isEmpty();
    }

    
    
    isEqual(other){
        return        this.bachelorYearNumber === other.bachelorYearNumber &&
            this.isPublished === other.isPublished && super.isEqual(other);
    }
    
}