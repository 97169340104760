export const getters = {
  appIsLoading: (state) => state.isLoading,

  getSortingOptions: (state) => state.sortingOptions,

  getPaginationData: (state) => state.paginationData,

  getBreadcrumbName: (state) => state.breadcrumbName,

  getApiError: (state) => state.apiError,

  getBreadcrumbParams: (state) => state.breadcrumbParams,

  getActiveModal: (state) => state.activeModal
}
