import apiHandler from '@/utils/handlers/ApiHandler';
import {useSummaryStore} from "@/store/summary/index";
import {useRootStore} from "@/store";
import summaryChapterActions from "@/components/partials/summary/SummaryChapterActions";
import PaginatedResponse from "@/models/PaginatedResponse";
import Summary from "@/models/summary/Summary";

export const apiActions = {
  /** Summaries **/
  getSummariesList() {
    

    const store = useSummaryStore();
    const rootStore = useRootStore();
    
    /** @type {SummaryTableFilter} */
    const currentSearchFilter = store.getCurrentListFilter;
  
    const page = currentSearchFilter.pageNumber ?? 1;
    const searchTerm = currentSearchFilter.searchQuery ?? '';
    
    
    let searchString = `page=${page}&searchTerm=${searchTerm}`

    if (currentSearchFilter.bachelorYearNumber) {
      searchString += `&bachelorYearNumber=${currentSearchFilter.bachelorYearNumber}`
    }
    if (currentSearchFilter.isPublished !== null) {
      searchString += `&isPublished=${currentSearchFilter.isPublished ? 1 : 0}`
    }
  
    if (currentSearchFilter.sortingOption) {
      searchString += currentSearchFilter.sortingOption.getQueryString();
    }

    return apiHandler.get(`summary/list?${searchString}`).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        const paginatedDataResponse = PaginatedResponse.parseDataFromApiResponse(response.data,Summary.parseDataFromObject);
        store.setDataResponse(paginatedDataResponse);
        rootStore.setPaginationData({totalPages: paginatedDataResponse.totalPages, currentPage:paginatedDataResponse.currentPage});
        return response.data;
      }
      return false;
    }).catch(error => {
      console.error(error);
      rootStore.setApiError(error);
      return false;
    });
  },
  /** Universities **/
  getUniversitiesList() {
    const rootStore = useRootStore();
    const summaryStore = useSummaryStore();

    return apiHandler.get(`university/list`).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        summaryStore.setUniversities(response.data);
        return true;
      }
      return false;
    }).catch(error => {
      rootStore.setApiError(error);
      return false;
    });
  },
  /** Create - summary **/
  createSummary(payload) {
    const rootStore = useRootStore();

    const data = {
      title: payload.title,
      description: payload.description,
      universityId: payload.university.id,
      collegeStartYear: payload.collegeStartYear.name,
      bachelorYearNumber: payload.bachelorYearNumber.id,
      periodNumber: payload.periodNumber.id,
      productCode: payload.productCode,
      googleDocsUrl: payload.googleDocsUrl,
      isPublished: payload.isPublished.id,
      availableDate: payload.availableDate,
      examDate: payload.examDate
    }

    return apiHandler.post(`summary/create`, data).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return response.data;
      }
      return false;
    }).catch(error => {
      rootStore.setApiError(error);
      return false;
    });
  },

  createSummaryByProductCode(payload) {
    return apiHandler.post('summary/create-external', payload).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return response.data;
      }
      return false;
    }).catch(error => {
      console.log('error', error);
      return false;
    });
  },

  /** Details **/
  getSummaryDetails(id) {
    const store = useSummaryStore();
    const rootStore = useRootStore();

    return apiHandler.get(`summary/${id}`).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        store.setSelectedSummary(response.data);
        return true;
      }
      return false;
    }).catch(error => {
      rootStore.setApiError(error);
      return false;
    });
  },
  deleteSummary(id) {
    const rootStore = useRootStore();

    return apiHandler.delete(`summary/${id}`).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    }).catch(error => {
      rootStore.setApiError(error);
      return false;
    });
  },
  editSummary(payload, id) {
    const rootStore = useRootStore();

    const data = {
      title: payload.title,
      description: payload.description,
      universityId: payload.university.id,
      collegeStartYear: payload.collegeStartYear,
      bachelorYearNumber: payload.bachelorYearNumber,
      periodNumber: payload.periodNumber,
      productCode: payload.productCode,
      googleDocsUrl: payload.googleDocsUrl,
      isPublished: payload.isPublished,
      availableDate: payload.availableDate,
      examDate: payload.examDate
    }
    
    if(!payload.googleDocsUrl) {
        delete data.googleDocsUrl;
    }
    
    
    return apiHandler.put(`summary/${id}`, data).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    }).catch(error => {
      rootStore.setApiError(error);
      return false;
    });
  },
  syncSummary(id) {
    const rootStore = useRootStore();

    return apiHandler.post(`summary/${id}/sync`).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    }).catch(error => {
      console.log("failed with error")
      console.log(error);
      rootStore.setApiError(error);
      return error?.response?.data ?? error?.message ?? "unknown error";
    });
  },
  getSummaryChapters(id) {
    const rootStore = useRootStore();
    const summaryStore = useSummaryStore();

    return apiHandler.get(`summary/${id}/chapters`).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        summaryStore.setSummaryChapters(response.data);
        return true;
      }
      return false;
    }).catch(error => {
      rootStore.setApiError(error);
      return error.response.data;
    });
  },
  setSupplements(payload, id) {
    const rootStore = useRootStore();

    return apiHandler.put(`summary/${id}/supplements`, payload).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    }).catch(error => {
      rootStore.setApiError(error);
      return error.response.data;
    });
  },
  setFreeFragments(payload, id) {
    const rootStore = useRootStore();

    return apiHandler.put(`summary/${id}/free-chapters`, payload).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    }).catch(error => {
      rootStore.setApiError(error);
      return error.response.data;
    });
  },
  setLockedChapter(payload, id) {
    const rootStore = useRootStore();

    return apiHandler.put(`summary/${id}/locked-chapters`, payload).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    }).catch(error => {
      rootStore.setApiError(error);
      return error.response.data;
    });
  },
  downloadPdf(id){
    const rootStore = useRootStore();
  
  
/*    var link = document.createElement('a');
    link.href = process.env.VUE_APP_API_URL +  `/api/summary/${id}/pdf`;
    link.download = 'file.pdf';
    link.dispatchEvent(new MouseEvent('click'));*/
    
    return apiHandler.get(`summary/${id}/pdf`,{},true).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        console.log("we got here ")
        //save the pdf to the user's computer
        const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
        const link = document.createElement('a');
        const fileName = 'summary.pdf';
        console.log("filename", fileName);
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        return response;
        //return response.data;
      }
      return false;
    }).catch(error => {
      
      try {
  
  
        console.log("we got here instead")
        var enc = new TextDecoder("utf-8");
        var arr = new Uint8Array(error.response.data);
        var str = enc.decode(arr);
        console.log(str);
        var jsonError = JSON.parse(str);
        //rootStore.setApiError(jsonError);
        return jsonError;
      }catch (e) {
        console.log("we got here instead 2")
        console.log(e);
        rootStore.setApiError(error);
        return {"error": "unknown_error"}
      }
    });
  }
}
