import apiHandler from '@/utils/handlers/ApiHandler';
import {useAuthStore} from '@/store/auth'
import AuthenticationManager from "@/utils/authenticationManager/AuthenticationManager";
import {useRootStore} from "@/store";
import AuthDataHandler from "@/utils/storage/AuthDataHandler";

export const apiActions = {
  sendLogin(data) {
    const store = useAuthStore();

    // Create the form data:
    const formData = new FormData();
    formData.append('grant_type', 'cms_password');
    formData.append('client_id', process.env.VUE_APP_AUTH_CLIENT_ID);
    formData.append('username', data.email);
    formData.append('password', data.password);
    formData.append('client_secret', '');
    if (data.keepMeLoggedIn) {
      formData.append('persistent', data.keepMeLoggedIn);
    }
    return apiHandler.post('auth/login', formData).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        store.loginSuccess();
        AuthenticationManager.setAuthenticated();
        return response.data;
      }
      return false;
    }).catch(error => {
      store.loginFailure(error);
      return false
    });
  },
  sendForgotPassword(payload) {
    const rootStore = useRootStore();

    return apiHandler.post('auth/forgot-password', payload).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    }).catch(error => {
      rootStore.setApiError(error);
      return false
    });
  },
  resetPassword(params, payload) {
    const rootStore = useRootStore();

    return apiHandler.post(`auth/reset-password/${params.id}/${params.token}`, payload).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    }).catch(error => {
      rootStore.setApiError(error);
      return false
    });
  },
  getUserProfile() {
    const rootStore = useRootStore();

    return apiHandler.get(`user/profile`).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        AuthDataHandler.setUserRole(response.data.roleId);
        return true;
      }
      return false;
    }).catch(error => {
      rootStore.setApiError(error);
      return false
    });
  }
}
