export default class Toast {
  /**
   * @param {Number} id
   * @param {String} title
   * @param {String} subtitle
   * @param {String} icon
   * @param {ToastOptions} options
   */
  constructor({id, title, subtitle, icon, options}) {
    this.id = id;
    this.title = title;
    this.subtitle = subtitle;
    this.icon = icon;
    this.options = options;
  }
}
