<template>
  <CreateOrEdit :read-only="true" :selected-summary="selectedSummary" button-text="Algemene gegevens" title="Bekijk samenvatting"/>
</template>

<script>
import {computed, ref} from "vue";
import {ROUTE_NAMES_SUMMARY} from "@/router/modules/summary";
import {useRoute} from "vue-router";
import {useSummaryStore} from "@/store/summary";
import {useRootStore} from "@/store";
import CreateOrEdit from "@/components/partials/summary/CreateOrEdit";

export default {
  name: "ViewSummary",
  components: {
    CreateOrEdit
  },
  setup() {
    const route = useRoute();
    const id = ref(route.params.summaryId);
    const summaryStore = useSummaryStore();
    const rootStore = useRootStore();

    const selectedSummary = computed(() => summaryStore.getSelectedSummary);
    getEditableSummary();

    function getEditableSummary() {
      if (id.value) {
        summaryStore.getSummaryDetails(id.value);
        rootStore.setBreadcrumbParams({summaryId: id.value});
        rootStore.setBreadcrumbName(ROUTE_NAMES_SUMMARY.DETAILS);
      }
    }

    return {
      selectedSummary
    }
  }
}
</script>

<style scoped>

</style>
