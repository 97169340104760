import Answer from "@/models/question/Answer";

export default class Question {
    constructor({questionText, pictureUrl = '', feedbackText, feedbackPictureUrl = '', answers, pictureReference, feedbackPictureReference}) {
        this.questionText = questionText;
        this.pictureUrl = pictureUrl;
        this.feedbackText = feedbackText;
        this.feedbackPictureUrl = feedbackPictureUrl;
        this.answers = answers.map(answer => Answer.parseDataFromObject(answer));
        this.pictureReference = pictureReference;
        this.feedbackPictureReference = feedbackPictureReference;
    }

    static parseDataFromObject(data) {
        return new Question(data);
    }
}