export const getters = {
  /**
   * Get the current content being read.
   * @param state
   * @return {SummaryContent|null|*}
   */
  getContent: (state) => state.content,
  /**
   * Get the current page being read.
   * @param state
   * @return {number}
   */
  getCurrentPageIndex: (state) => state.currentPageIndex,
  /**
   * Get the current chapters of the current book.
   * @param state
   * @return {Chapter[]  | null}
   */
  getChapters: (state) => state.content?.chapters,
  /**
   * Get the current paragraphs of the current book.
   * @param state
   * @return {Paragraph[]|*}
   */
  getParagraphs: (state) => state.content?.paragraphs,
  /**
   * Get the current page divider of the current book.
   * @param state
   * @return {*|null}
   */
  getCurrentPageContent: (state) => state.content?.pages[state.currentPage]?.content ?? null,
  /**
   * Get the current chapter of the current book.
   * @param state
   * @return {Chapter|*|null}
   */
  getCurrentChapter: (state) => state.content?.pages[state.currentPage]?.containingChapter ?? null,

  /**
   * Get the chapter tree of the current book.
   * @param state
   * @return {Tree[]|null}
   */
  getChapterTree: (state) => state.content?.chapterTree ?? null,

  /**
   * Get the current notes of the current book.
   * @param state
   * @return {Note[]|*}
   */
  getNotes: (state) => state.notes,

  /**
   * Get the current highlight colour
   * @param state
   * @return {NoteColor} the current highlight colour
   */
  getHighlightColor: (state) => state.highlightColor,
  /**
   * Get the search results.
   * @param state
   * @return {SearchResult|null}
   */
  getSearchResults: (state) => state.searchResults,

  getSelectedSearchResult: (state) => state.selectedSearchResult,
  /**
   * Get the currently viewed details summary.
   * @param state
   * @return {Summary|null}
   */
  getDetailSummary: (state) => state.detailSummary,
  /**
   * Get the loaded Question List for this book.
   * @param state
   * @return {QuestionListDetails[]|*}
   */
  getLoadedQuestionList: (state) => state.loadedQuestionList,
  /**
   * Get the summary chapters for this book.
   * @param state
   * @return {SummaryChapter[]|*}
   */
  getSummaryChapters: (state) => state.summaryChapters,
  /**
   * Get the summary chapters for this book flattened into a single list.
   * @param state
   * @return {SummaryChapter[]|*}
   */
  getSummaryChaptersFlattened: (state) => {
    const flattened = [];
    state.summaryChapters.forEach((chapter) => {
      flattened.push(...chapter.flattenSubChapters());
    });
    return flattened;
  },
}
