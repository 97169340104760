export default  class SummaryImage{

  /**
   * The id of the summary image.
   * @type {number}
   */
  id;

  /**
   * The object id of the summary image.
   * @type {string}
   */
  objectId;

  /**
   * The url of the summary image.
   * @type {string}
   */
  imageUrl;



  constructor({id, objectId, imageUrl}) {
    this.id = id;
    this.objectId = objectId;
    this.imageUrl = imageUrl;
  }

  static parseDataFromObject(data) {
    return new SummaryImage(data);
  }

  /**
   * Get the full url of the image.
   * @return {string}
   */
  get fullUrl(){
    return process.env.VUE_APP_API_URL + this.imageUrl;
  }

}
