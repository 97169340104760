export default class PositionedObjectProperties {

  /**
   * The positioning of this object.
   * @type {PositionedObjectPositioning}
   */
  positioning;

  /**
   * The embedded object
   * @type {EmbeddedObject}
   */
  embeddedObject;

  constructor({positioning, embeddedObject}) {
    this.positioning = positioning;
    this.embeddedObject = embeddedObject;
  }


}
