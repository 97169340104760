import {AuthenticationProviders} from "@/utils/authenticationManager/providers/providers";
import {EmailPasswordProvider} from "@/utils/authenticationManager/providers/EmailPasswordProvider";
import AccessToken from "@/utils/authenticationManager/tokens/AccessToken";
import AuthDataHandler from "@/utils/storage/AuthDataHandler";

export const USER_ROLES = Object.freeze({
  ADMIN: 3,
  MANAGER: 4,
  STUDY_HERO: 5
})

class AuthenticationManager {
  constructor() {
    this.providerInstance = this.getProviderByName(AuthDataHandler.getAuthenticationProvider());
  }

  /**
   * Authenticates the user, based on the specified provider.
   * @param {AuthenticationProvider} provider - The name of the provider, e.g. emailPassword
   * @param {Object} userData - The form data, e.g. email, password.
   * @returns {Promise<boolean>} - Whether the user was successfully logged in or not.
   */
  authenticate(provider, userData) {
    return new Promise((resolve) => {
      this.providerInstance = this.getProviderByName(provider.name);
      resolve(this.providerInstance.authenticate(userData));
    });
  }

  signOut() {
    return new Promise((resolve) => {
      resolve(this.providerInstance.signOut());
    });
  }

  getProviderByName(name) {
    AuthDataHandler.setAuthenticationProvider(name);
    if (name === AuthenticationProviders.emailPassword.name) {
      return new EmailPasswordProvider();
    }
    return null;
  }

  refreshTokens() {
    if (this.providerInstance) {
      return this.providerInstance.refreshAccessToken();
    }
    return false;
  }

  setAuthenticated() {
    AuthDataHandler.saveLocalData(AuthDataHandler.KEYS.IS_AUTHENTICATED, '1');
  }

  get isAuthenticated() {
    // Token not present.
    if (!AccessToken.value) {
      return false;
    }

    // Check if the token has expired.
    const expired = Number(AccessToken.expiration) > new Date().getTime();
    if (!expired) {
      return false;
    }

    // Access token is valid (exists and has not expired), so the user is considered authenticated.
    return true;
  }

  /** User scope **/
  isStudyHero() {
    return AuthDataHandler.getUserRole() === USER_ROLES.STUDY_HERO;
  }

  isAdmin () {
    return AuthDataHandler.getUserRole() === USER_ROLES.ADMIN;
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new AuthenticationManager();
    }
    return this.instance;
  }
}

export default AuthenticationManager.getInstance();
