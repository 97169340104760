<template>
  <label>{{ label }}
    <input :placeholder="placeholder"
           :class="{'has-error' : errorMessages.length && isErrorActive}"
           @input="handleInput"
           :id="FIELD_NAME_PASSWORD"
           :name="FIELD_NAME_PASSWORD"
           type="password"
           v-model="input"
           class="input first-input"
           autocomplete="new-password">
  </label>
  <label>{{ labelConfirm }}
    <input :placeholder="placeholderConfirm"
           :class="{'has-error' : errorMessages.length && isErrorActive}"
           @input="handleInput"
           v-model="inputConfirm"
           :id="FIELD_NAME_CONFIRMATION"
           :name="FIELD_NAME_CONFIRMATION"
           type="password"
           class="input"
           autocomplete="new-password">
  </label>
  <div v-if="isErrorActive">
    <p v-for="errorMessage of errorMessages" :key="errorMessage" class="error-message">{{ errorMessage }}</p>
  </div>
</template>

<script>
import Validator from "@/utils/yo-validator/YoValidator";
import {computed, ref} from "vue";

const FIELD_NAME_PASSWORD = 'password';
const FIELD_NAME_CONFIRMATION = 'passwordRepeat';
const RULE_NAME_CONFIRMATION = 'passwordConfirm';

export default {
  name: 'PasswordConfirmation',
  props: {
    placeholder: {
      type: String,
      required: true
    },
    placeholderConfirm: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false
    },
    labelConfirm: {
      type: String,
      required: false
    }
  },
  setup() {
    /** validator returned error messages **/
    const {errorMessages, handleInput} = Validator.register({
      fieldName: FIELD_NAME_PASSWORD,
      rules: `required|${RULE_NAME_CONFIRMATION}:@${FIELD_NAME_CONFIRMATION}|minPasswordLength:8|uppercase|specialChar`
    });
    const input = ref();
    const inputConfirm = ref();

    const isErrorActive = computed(() => {
      return !!input.value && !!inputConfirm.value
    })

    return {
      handleInput,
      FIELD_NAME_PASSWORD,
      FIELD_NAME_CONFIRMATION,
      errorMessages,
      input,
      inputConfirm,
      isErrorActive
    }
  }
}
</script>

<style scoped lang="scss">
.first-input {
  margin-bottom: rem(24);
}
</style>