<template>
  <div v-if="selectedSummary || isCreate" class="create-summary-wrapper">
    <PageTitle :title="title" has-return-button/>
    <p class="form-title">Algemene gegevens</p>

    <FormWrapper :send-form-data="createNewOrEditSummary" class="form-wrapper">
      <template #form-content>
        <div class="left-section">
          <BasicInputField :value="selectedSummary?.title"
                           class="input-create-new"
                           field-name="title"
                           label="Titel"
                           :is-read-only="readOnly"
                           placeholder="Voer de titel in"
                           rules="required"/>

          <BasicTextField :value="selectedSummary?.description"
                          field-name="description"
                          label="Beschrijving"
                          :is-read-only="readOnly"
                          placeholder="Voer de beschrijving in"
                          rules="required"/>

          <BasicDropdown :dropdown-options="universitiesList"
                         :value="selectedSummary?.university"
                         field-name="university"
                         label="Universiteit"
                         placeholder="Selecteer de universiteit"
                         :is-read-only="readOnly"
                         rules="required"/>

          <BasicDropdown :dropdown-options="years"
                         :value="{name: selectedSummary?.collegeStartYear}"
                         field-name="collegeStartYear"
                         label="Collegejaar"
                         placeholder="Selecteer het collegejaar"
                         :is-read-only="readOnly"
                         rules="required"/>

          <RadioGroup :options="bachelorYearsAndPeriods"
                      :value="selectedSummary?.bachelorYearNumber"
                      class="radio-group"
                      :is-read-only="readOnly"
                      field-name="bachelorYearNumber"
                      label="Bachelorjaar"/>

          <RadioGroup :options="bachelorYearsAndPeriods"
                      :value="selectedSummary?.periodNumber"
                      class="radio-group"
                      :is-read-only="readOnly"
                      field-name="periodNumber"
                      label="Periode"/>

          <BasicDatePicker :value-attr="selectedSummary?.examDate"
                           field-name="examDate"
                           label="Datum tentamen"
                           :is-read-only="readOnly"
                           placeholder="Selecteer de datum van het tentamen"
                           rules="required"/>
        </div>
        <div class="right-section">
          <BasicInputField :api-error="showProductCodeError ? null : apiError"
                           :value="selectedSummary?.googleDocsUrl"
                           class="input-create-new"
                           field-name="googleDocsUrl"
                           label="Google Docs link"
                           :is-read-only="readOnly"
                           placeholder="Voer de Google Docs link in"
                           rules="required"/>

          <BasicInputField :api-error="showProductCodeError ? apiError : null"
                           :value="selectedSummary?.productCode"
                           class="input-create-new"
                           field-name="productCode"
                           label="Productcode"
                           :is-read-only="readOnly"
                           placeholder="Voer de productcode in"
                           rules="required"/>

          <RadioGroup :options="statuses"
                      :value="selectedSummary?.isPublished"
                      class="radio-group"
                      field-name="isPublished"
                      :is-read-only="readOnly"
                      label="Status samenvatting"
                      select-default/>

          <BasicDatePicker :value-attr="selectedSummary?.availableDate"
                           field-name="availableDate"
                           label="Datum beschikbaar"
                           :is-read-only="readOnly"
                           placeholder="Selecteer de datum van beschikbaarheid"
                           rules="required"/>
        </div>
      </template>

      <template #button-submit>
        <DButton :button-text="buttonText"
                 v-if="!readOnly"
                 button-type="submit"
                 class="create-new-button"/>
      </template>
    </FormWrapper>
  </div>
</template>

<script>
import PageTitle from "@/components/partials/summary/PageTitle";
import FormWrapper from "@/components/yo-form/FormWrapper";
import BasicInputField from "@/components/yo-form/BasicInputField";
import DButton from "@/components/elements/DButton";
import BasicTextField from "@/components/yo-form/BasicTextField";
import BasicDropdown from "@/components/yo-form/BasicDropdown";
import {useSummaryStore} from "@/store/summary";
import {computed, ref} from "vue";
import RadioGroup from "@/components/yo-form/RadioGroup";
import BasicDatePicker from "@/components/yo-form/BasicDatePicker";
import {statuses} from "@/models/summary/Summary";
import {bachelorYearsAndPeriods} from "@/models/summary/Summary";
import {formatCollegeYear} from "@/utils/helpers/DetailsHelper";
import {useRootStore} from "@/store";
import {ERROR_CODES} from "@/utils/handlers/errorHandler/errorCodes";
import {isErrorActive} from "@/utils/handlers/errorHandler/ErrorHandler";

export default {
  name: "CreateOrEdit",
  components: {
    BasicDatePicker,
    RadioGroup,
    BasicDropdown,
    BasicTextField,
    PageTitle,
    FormWrapper,
    BasicInputField,
    DButton
  },
  props: {
    title: {
      required: true,
      type: String
    },
    selectedSummary: {
      required: false,
      type: Object
    },
    buttonText: {
      required: true,
      type: String
    },
    isCreate: {
      required: false,
      default: false,
      type: Boolean
    },
    readOnly: {
      default: false,
      type: Boolean
    }
  },
  emits: ['create-summary','edit-summary'],
  setup(props, {emit}) {
    const summaryStore = useSummaryStore();
    const rootStore = useRootStore();

    /** Api Errors **/
    const apiError = computed(() => rootStore.getApiError);
    const showProductCodeError = computed(() => {
      return isErrorActive(ERROR_CODES.CONFLICT_PRODUCT_CODE, apiError.value);
    })

    /** Universities **/
    getUniversities();
    const universitiesList = computed(() => summaryStore.getUniversities);

    function getUniversities() {
      summaryStore.getUniversitiesList();
    }

    /** College years **/
    const years = ref([]);
    getCollegeYears();

    function getCollegeYears() {
      const startYear = 2000;
      const endYear = new Date().getFullYear() + 1;

      for (let i = 0; i < endYear - startYear; i++) { //Put years in array as object, displayValue is e.g. 2022 -> 22/23
        const year = endYear - i;
        years.value.push({name: year, id: (i + 1), displayValue: formatCollegeYear(year)});
      }
    }


    function createNewOrEditSummary(formData) {
      if (props.selectedSummary) {
        emit('edit-summary', formData);
        return;
      }
      emit('create-summary', formData);
    }

    return {
      /** Create new/edit **/
      createNewOrEditSummary,

      /** Universities **/
      universitiesList,

      /** years - yearpicker **/
      years,

      /** Bacheloryear **/
      bachelorYearsAndPeriods,

      /** statuses **/
      statuses,

      /** Api error **/
      apiError,
      showProductCodeError,
    }
  }
}
</script>

<style lang="scss" scoped>
.create-summary-wrapper {
  margin-top: rem(60);

  .right-section, .left-section {
    width: rem(350);
  }

  .form-title {
    color: var(--main-font-color);
    font-size: rem(20);
    font-weight: bold;
    letter-spacing: 0;
    line-height: rem(25);
    margin-top: rem(40);
    margin-bottom: rem(20);
  }

  .form-wrapper {
    margin-bottom: rem(40);
  }

  ::v-deep .form-content-wrapper {
    display: flex;
    gap: rem(200);
    margin-bottom: rem(56);
  }

  .input-wrapper, .text-area-wrapper, .dropdown-wrapper, .form-group-wrapper {
    margin-bottom: rem(20);
  }
}
</style>
