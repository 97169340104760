<template>
  <div v-if="selectedUser || isCreate" class="wrapper">
    <PageTitle has-return-button
               :title="title"
               :has-button="hasButton"
               :button-text="additionalButtonText"
               is-red-button
               @on-click-btn="$emit('delete')"/>

    <p class="title">Gegevens</p>

    <div class="form-wrapper-outer">
      <FormWrapper :send-form-data="validateForm">
        <template #form-content>
          <BasicInputField :value="selectedUser?.firstName"
                           field-name="firstName"
                           label="Voornaam"
                           placeholder="Voer de voornaam in"
                           rules="required"
                           class="input-create-new"/>

          <BasicInputField :value="selectedUser?.middleName"
                           field-name="middleName"
                           label="Tussenvoegsel"
                           placeholder="Voer het tussenvoegsel in"
                           class="input-create-new"/>

          <BasicInputField :value="selectedUser?.lastName"
                           field-name="lastName"
                           label="Achternaam"
                           placeholder="Voer de achternaam in"
                           rules="required"
                           class="input-create-new"/>

          <BasicInputField :value="selectedUser?.email"
                           field-name="email"
                           :api-error="conflictEmailError"
                           label="E-mailadres"
                           placeholder="Voer het e-mailadres in"
                           :is-read-only="!!selectedUser"
                           rules="required"
                           :class="{'read-only':!!selectedUser}"
                           type="email"/>

          <BasicDropdown :value="selectedUser ? {id: selectedUser?.roleId, name: selectedUser?.getRoleIdText()} : null"
                         :dropdown-options="userRolesDropdown"
                         field-name="roleId"
                         label="Gebruikersrol"
                         placeholder="Selecteer de gebruikersrol"
                         rules="required"
                         class="users-form"/>
        </template>

        <template #button-submit>
          <DButton :button-text="buttonText" button-type="submit" class="create-new-button"/>
        </template>
      </FormWrapper>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/partials/summary/PageTitle";
import FormWrapper from "@/components/yo-form/FormWrapper";
import DButton from "@/components/elements/DButton";
import BasicInputField from "@/components/yo-form/BasicInputField";
import BasicDropdown from "@/components/yo-form/BasicDropdown";
import {useUserStore} from "@/store/user";
import {useRouter} from "vue-router";
import {computed, onUnmounted} from "vue";

export default {
  name: "CreateOrEditUser",
  components: {
    PageTitle,
    FormWrapper,
    DButton,
    BasicInputField,
    BasicDropdown,
  },
  props: {
    title: {
      required: true,
      type: String
    },
    hasButton: {
      required: false,
      type: Boolean,
      default: false
    },
    additionalButtonText: {
      required: false,
      type: String
    },
    buttonText: {
      required: true,
      type: String
    },
    selectedUser: {
      required: false
    },
    isCreate: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  emits: ['create','edit','delete'],
  setup(props,{emit}) {
    const userStore = useUserStore();

    const userRolesDropdown = [
      {
        id: 3,
        name: 'Admin'
      },
      {
        id: 4,
        name: 'Studiemanager'
      },
      {
        id: 5,
        name: 'Studieheld'
      }
    ]

    const conflictEmailError = computed(() => userStore.conflictEmailError)

    function validateForm(formData) {
      if(props.selectedUser) {
        emit('edit', formData)
      } else {
        emit('create', formData)
      }
    }

    onUnmounted(() => {
      userStore.resetError();
    })

    return {
      validateForm,
      userRolesDropdown,
      conflictEmailError
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  margin-top: rem(54);

  .title {
    color: var(--main-font-color);
    font-size: rem(20);
    font-weight: bold;
    letter-spacing: 0;
    line-height: rem(25);
    margin: rem(40) 0 rem(20) 0;
  }

  .form-wrapper-outer {
    width: rem(350);
    position: relative;

    .input-wrapper {
      margin-bottom: rem(20);
    }

    .dropdown-wrapper {
      margin-bottom: rem(45);
    }
  }

  .create-new-button {
    margin-bottom: rem(28);
  }
}
</style>