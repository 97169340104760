<template>
  <div class="form-group">
    <p v-if="label" class="label">{{ label }}</p>
    <p v-if="additionalText" class="additional-text">{{ additionalText }}</p>
    <label :class="['button-main', 'form-label-file', 'file-upload', {'selected': fileSelected}]"
           ref="btnFile"
           :for="fieldName">
      <span v-if="fileSelected" class="placeholder selected">{{ fileSelected }}</span>
      <span v-else class="placeholder">{{ placeholder }}</span>
      <img v-if="fileSelected" @click="removeFile($event)" src="../../assets/icons/ic_delete.svg" alt="icon" class="icon">
      <input :data-cy="cySelector || `${fieldName}-input`"
             :accept="accept"
             @change="loadFile($event)"
             @click="clearFile($event)"
             :id="fieldName"
             :name="fieldName"
             :multiple="isMultiple"
             type="file"
             class="form-input-file">
    </label>
    <div v-show="apiError || errorMessages.length > 0" class="error-wrapper">
      <!-- give priority to api error msg over vue validator error msg, until user inputs again -->
      <p v-if="apiError" class="error-message">{{ apiError }}</p>
      <p v-else v-for="(error, index) of errorMessages" :key=index class="error-message">{{ error }}</p>
    </div>
  </div>
</template>
<script>
import {ref, watch} from 'vue';
import Validator from "@/utils/yo-validator/YoValidator";
import {useRootStore} from "@/store";
import fieldManager from "@/utils/yo-validator/fields/FieldManager";

export default {
  name: 'FileUploader',
  props: {
    /** required **/
    fieldTitle: {
      required: false
    },
    fieldName: {
      required: true
    },
    /** not required **/
    isRequired: {
      default: false
    },
    // not required
    apiErrorText: {
      required: false,
      type: String
    },
    label: {
      type: String,
      required: false
    },
    additionalText: {
      type: String,
      required: false
    },
    /** add all possible rules here **/
    rules: {
      default: 'maxSize:1|ext:jpg,png'
    },
    accept: {
      default: 'image/*'
    },
    isMultiple: {
      default: false
    },
    placeholder: {
      default: 'Upload foto'
    },
    cySelector: {
      type: String,
      required: false
    },
    value: {
      required: false
    },
    uploadOnSelection: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  emits: ['update-parent', 'has-errors', 'upload-file'],
  setup(props, {emit}) {
    /** validator returned error messages **/
    const {errorMessages, validateFieldByData, setFieldData} = Validator.register(props);
    const fileSelected = ref(null);

    /** watch api error text from parent **/
    const apiError = ref('');
    watch(() => props.apiErrorText, (newVal) => {
      apiError.value = newVal.trim();
    });

    watch(() => props.value, () => {
      if (!props.value) {
        clearFile();
      }
    })

    /** Watch validator error messages. If there are any, inform the parent. **/
    watch(() => errorMessages.value, () => {
      emit('has-errors', errorMessages.value.length > 0)
    })

    /** watch input in the template **/
    const loadFile = async (event) => {
      console.log("we loaded file");
      // validate the image
      validateFieldByData(event.target.files);
      // define data
      const data = props.isMultiple ? event.target.files : event.target.files[0];
      if (!errorMessages.value.length) {
        fileSelected.value = data.name;

        if (props.uploadOnSelection) {
          emit('upload-file', {file: data, fieldName: props.fieldName});
        }
        // update form data
        setFieldData(data);
        // call the parent if needed
        updateParent(data);

      }

    };

    // update parent component
    const updateParent = ((data) => emit('update-parent', data));
    const clearFile = (event = '') => {
      if (event) {
        // clear the value to trigger change event for uploading the same image
        event.target.value = null;
      }
      setFieldData(null);
    };

    const removeFile = (event) => {
      event.preventDefault();
      fileSelected.value = null;
      setFieldData(null);
    }

    return {
      errorMessages,
      apiError,
      loadFile,
      clearFile,
      fileSelected,
      removeFile
    }
  }
}
</script>
<style scoped lang="scss">
@import "../../assets/css/base.variables";
@import "../../assets/css/base.mixins";

.form-label-file {
  height: rem(41);
  width: rem(160);
  position: absolute;
  bottom: 0;

  &:hover {
    cursor: pointer;
  }

  .placeholder {
    position: absolute;
    top: 25%;
    left: 25%;
    font-size: rem(14);
    font-weight: bold;
    letter-spacing: 0;
    line-height: rem(17);

    &.selected {
      color: var(--main-font-color);
      font-size: rem(14);
      font-weight: 500;
      letter-spacing: 0;
      line-height: rem(20);
    }
  }

  .icon {
    padding: rem(7) rem(7) rem(7) 0;
    position: absolute;
    right: 0;
    z-index: 1000;
  }

  &.selected {
    height: rem(40);
    width: rem(300);
    border: rem(1) solid var(--grey-light-02);
    border-radius: rem(8);
    bottom: rem(6);
  }
}

.form-input-file {
  display: none;
}

.label {
  margin: 0 0 rem(12) 0;
}

.additional-text {
  opacity: 0.6;
  color: var(--main-font-color);
  font-size: rem(14);
  letter-spacing: 0;
  line-height: rem(16);
  margin: 0;
}

.error-wrapper {
  margin-top: rem(65);
}

.error-message {
  margin-left: 0;
}
</style>