import {useRootStore} from "@/store";
import apiHandler from "@/utils/handlers/ApiHandler";
import {useQuestionStore} from "@/store/question/index";

export const apiActions = {
    /** Questions **/
    fetchListOfQuestionsList(id) {
        const store = useQuestionStore();
        const rootStore = useRootStore();

        return apiHandler.get(`question-list/${id}/list`).then(response => {
            if (apiHandler.isSuccess(response.status)) {
                store.setListOfQuestionsList(response.data);
                return true;
            }
            return false;
        }).catch(error => {
            rootStore.setApiError(error);
            return false;
        });
    },
    /** Questions - delete **/
    deleteQuestionList(id) {
        const rootStore = useRootStore();

        return apiHandler.delete(`question-list/${id}`).then(response => {
            if (apiHandler.isSuccess(response.status)) {
                return true;
            }
            return false;
        }).catch(error => {
            rootStore.setApiError(error);
            return false;
        });
    },
    /** Questions - create **/
    createQuestionList(payload, id) {
        const rootStore = useRootStore();

        return apiHandler.post(`question-list/${id}/create`,payload).then(response => {
            if (apiHandler.isSuccess(response.status)) {
                return true;
            }
            return false;
        }).catch(error => {
            rootStore.setApiError(error);
            return false;
        });
    },
    /** Questions - edit **/
    editQuestionList(payload, id) {
        const rootStore = useRootStore();

        return apiHandler.put(`question-list/${id}`, payload).then(response => {
            if (apiHandler.isSuccess(response.status)) {
                return true;
            }
            return false;
        }).catch(error => {
            rootStore.setApiError(error);
            return false;
        });
    },
    /** Questions - get by id **/
    getQuestionListById(id) {
        const rootStore = useRootStore();
        const questionStore = useQuestionStore();

        return apiHandler.get(`question-list/${id}`).then(response => {
            if (apiHandler.isSuccess(response.status)) {
                questionStore.setSelectedQuestionList(response.data);
                return true;
            }
            return false;
        }).catch(error => {
            rootStore.setApiError(error);
            return false;
        });
    },
    updateQuestionListPosition(payload, id) {
        const rootStore = useRootStore();

        return apiHandler.post(`question-list/${id}/set-chapters`, payload).then(response => {
            if (apiHandler.isSuccess(response.status)) {
                return true;
            }
            return false;
        }).catch(error => {
            rootStore.setApiError(error);
            return false;
        });
    }
}