/**
 * The data model of a books chapters as given by the api.
 * This allows for mapping between gDocs content and the api.
 */
export default class SummaryChapter{

  /**
   * The id of the chapter.
   * @type {number}
   */
  id;

  /**
   * The summaryId of the chapter.
   * @type {number}
   */
  summaryId;

  /**
   * The title of the chapter.
   * @type {string}
   */
  title;

  /**
   * If the chapter is free 1 is free, 0 is paid.
   * @type {number}
   */
  isFree;

  /**
   * If the chapter is a supliment
   * @type {number}
   */
  isSupplement;

  /**
   * If the chapter is locked.
   * @type {number}
   */
  isLocked;

  /**
   * The parent chapter id.
   * @type {number}
   */
  parentId;

  /**
   * The gDoc id of the chapter.
   * @type {string}
   */
  gDocsChapterId;

  /**
   * The sort index of the chapter.
   * @type {number}
   */
  sortIndex;

  /**
   * The sub chapters of the chapter.
   * @type {SummaryChapter[]}
   */
  subChapters;


  constructor({id, summaryId, title, isFree, isSupplement, isLocked, parentId, gDocsChapterId, sortIndex, subChapters}) {
    this.id = id;
    this.summaryId = summaryId;
    this.title = title;
    this.isFree = isFree;
    this.isSupplement = isSupplement;
    this.isLocked = isLocked;
    this.parentId = parentId;
    this.gDocsChapterId = gDocsChapterId;
    this.sortIndex = sortIndex;
    this.subChapters = subChapters.map(e => SummaryChapter.parseDataFromObject(e));
  }

  /**
   * Create a chapter from an object.
   */
  static parseDataFromObject(data){
    return new SummaryChapter(data);
  }

  /**
   * flatten the sub-chapters from a tree structure to a list.
   * @param {SummaryChapter[]} flattenedChapters the list of flattened chapters.
   */
  flattenSubChapters(flattenedChapters = []){
    flattenedChapters.push(this);
    this.subChapters.forEach((chapter) => chapter.flattenSubChapters(flattenedChapters));
    return flattenedChapters;
  }
}
