export default class TableRow {


  /**
   * The start index of the row.
   * @type {number}
   */
  startIndex;

  /**
   * The end index of the row.
   * @type {number}
   */
  endIndex;

  /**
   * The contents and style of each cell in the row.
   * @type {TableCell[]}
   */
  tableCells;


  /**
   * The style of the row.
   * @type {TableRowStyle}
   */
  tableRowStyle;

  get getChildStructuralElements() {
    if (this.tableCells) {
      return this.tableCells.map(cell => cell.getChildStructuralElements).flat();
    }
    return [];
  }


  constructor({startIndex, endIndex, tableCells, tableRowStyle}) {
    this.startIndex = startIndex;
    this.endIndex = endIndex;
    this.tableCells = tableCells;
    this.tableRowStyle = tableRowStyle;
  }

  estimateHeight(clientWidth,fullDocument) {
    // TODO get the minimum height of the row.
    return this.tableCells.reduce((total, cell) => Math.max(total, cell.estimateHeight(clientWidth,fullDocument)), 0);
  }


}
