<template>
  <div class="confirmation-wrapper">
    <h2 class="title-text">Link verstuurd!</h2>

    <p class="text">De link is verstuurd naar het opgegeven e-mailadres
      <span v-if="enteredEmail" class="entered-mail">{{ enteredEmail }}</span>.
      Ga naar je inbox en volg daar de instructies.
    </p>

    <p class="no-link-text">Geen link ontvangen?
      <span class="bottom-border" @click.native="resendConfirmationEmail">Verstuur link nogmaals</span>
    </p>

    <DButton button-type="button" class="back-login-btn" button-text="Ga terug naar inloggen" @click="navigateToLogin"/>
  </div>
</template>

<script>
import DButton from "@/components/elements/DButton";
import {ROUTE_NAMES_AUTH} from "@/router/modules/auth";
import {useRouter} from 'vue-router';
import {useAuthStore} from "@/store/auth";
import {TOAST_CONTENTS} from "@/utils/handlers/toastHandler/ToastContents";
import ToastHandler from "@/utils/handlers/toastHandler/ToastHandler";

export default {
  name: "ForgotPasswordConfirmation",
  components: {
    DButton
  },
  props: {
    enteredEmail: {
      type: String,
    }
  },
  setup(props) {
    const router = useRouter();
    const authStore = useAuthStore();

    function resendConfirmationEmail() {
      authStore.sendForgotPassword({email: props.enteredEmail}).then((response) => {
        if (response) {
          ToastHandler.addNew(TOAST_CONTENTS.LINK_SENT);
        }
      })
    }

    function navigateToLogin() {
      router.push({name: ROUTE_NAMES_AUTH.LOGIN})
    }

    return {
      resendConfirmationEmail,
      navigateToLogin
    }
  }
}
</script>

<style lang="scss" scoped>
.confirmation-wrapper {
  .title-text {
    margin-bottom: rem(15);
  }

  .text, .no-link-text, .bottom-border {
    font-size: rem(16);
  }

  .no-link-text {
    margin-top: rem(16);
    cursor: pointer;
  }

  .back-login-btn {
    margin-top: rem(111);
  }

  .entered-mail {
    font-weight: bold;
  }
}
</style>