/**
 * This wrapper allows for faster access to the command tags
 * when reading, to increase application performance.
 */
export default class CommandTagManager{


  /**
   * The command tags.
   * @type {CommandTag[]}
   */
  commandTags;


  /**
   * The active command tags, indexed by structural element.
   * @type {Object.<number, CommandTag[]>}
   */
  activeCommandTagsByStructuralElementIndex = {};

  /**
   * Take in the  command tags and set up the indexes.
   * @param commandTags
   */
  constructor({commandTags}) {
    commandTags.sort((a,b) => a.startIndex - b.startIndex);
    this.commandTags = commandTags;
    // Sort the command tags by start index, allowing them to be binary searched.
    this.indexTags();
  }


  /**
   * Get the command tags for a given structural element index.
   * @param {number} index
   * @return {CommandTag[]}
   */
  getTagsForStructuralElementIndex(index){
    return this.activeCommandTagsByStructuralElementIndex[index] || [];
  }


  /**
   * Get the nearest previous tag for a given index.
   * @param index {number} The index to search from.
   * @param tagName {string} The tag name to search for.
   * @param minimumThreshold {number} The minimum threshold to search from.
   * @param maximumThreshold {number} The maximum threshold to search from.
   * @return {CommandTag | undefined} The nearest previous tag, or undefined if none found.
   */
  getNearestPreviousTag(index,tagName,minimumThreshold = null, maximumThreshold = null){
    let minimumIndex = 0;
    let maximumIndex = 0;
    
    if(minimumThreshold && maximumThreshold) {
      minimumIndex = index - minimumThreshold;
      maximumIndex = index + maximumThreshold;
    } 

    for(let i = this.commandTags.length - 1; i >= 0; i--){
      if(this.commandTags[i].command === tagName) {
        if(this.commandTags[i].startIndex > maximumIndex) continue;
        if(this.commandTags[i].startIndex < minimumIndex) return undefined;
        return this.commandTags[i];
      }
    }
    return undefined;
  }



  /**
   * Index the command tags by structural element index.
   * @private
   */
  indexTags(){
    this.commandTags.forEach((commandTag) => {
      for(let i = commandTag.startIndex; i <= commandTag.endIndex; i++){
        if(!this.activeCommandTagsByStructuralElementIndex[i]){
          this.activeCommandTagsByStructuralElementIndex[i] = [];
        }
        this.activeCommandTagsByStructuralElementIndex[i].push(commandTag);
      }
    });
  }


}
