import {useRootStore} from "@/store";
import apiHandler from "@/utils/handlers/ApiHandler";
import {ERROR_CODES} from "@/utils/handlers/errorHandler/errorCodes";
import {useUserStore} from "@/store/user/index";
import PaginatedResponse from "@/models/PaginatedResponse";
import Feedback from "@/models/feedback/Feedback";
import User from "@/models/users/User";

export const apiActions = {
    /** Get users list **/
    getUsers() {
        const store = useUserStore();
        const rootStore = useRootStore();
    
        const currentSearchFilter = store.currentUserFilter;
    
        const page = currentSearchFilter.pageNumber ?? 1;
        const name = currentSearchFilter.searchQuery ?? '';
    
        let searchString = `page=${page}&searchTerm=${name}`;
    
        if (currentSearchFilter.sortingOption) {
            searchString += currentSearchFilter.sortingOption.getQueryString();
        }
        return apiHandler.get(`user/list?${searchString}`).then(response => {
            if (apiHandler.isSuccess(response.status)) {
                const data = PaginatedResponse.parseDataFromApiResponse(response.data,User.parseDataFromObject);
                store.setDataResponse(data);
                return data;
            }
            return false;
        }).catch(error => {
            rootStore.setApiError(error);
            return false;
        });
    },
    /** User - create **/
    createUser(payload) {
        const rootStore = useRootStore();
        const userStore = useUserStore();

        const data = {
            firstName: payload.firstName,
            lastName: payload.lastName,
            middleName: payload.middleName ? payload.middleName : '',
            email: payload.email,
            roleId: payload.roleId.id
        }

        return apiHandler.post(`user/create`, data).then(response => {
            if (apiHandler.isSuccess(response.status)) {
                return true;
            }
            return false;
        }).catch(error => {
            rootStore.setApiError(error);
            if (error.response.data.error === ERROR_CODES.CONFLICT_EMAIL_IN_USE) {
                userStore.setConflictEmailError(error);
            }
            return false;
        });
    },
    /** User - edit **/
    editUser(payload, id) {
        const rootStore = useRootStore();
        const data = {
            firstName: payload.firstName,
            lastName: payload.lastName,
            middleName: payload.middleName ? payload.middleName : '',
            email: payload.email,
            roleId: payload.roleId.id
        }

        return apiHandler.put(`user/${id}`, data).then(response => {
            if (apiHandler.isSuccess(response.status)) {
                return true;
            }
            return false;
        }).catch(error => {
            rootStore.setApiError(error);
            return false;
        });
    },
    /** User - delete **/
    deleteUser(id) {
        const rootStore = useRootStore();

        return apiHandler.delete(`user/${id}`).then(response => {
            if (apiHandler.isSuccess(response.status)) {
                return true;
            }
            return false;
        }).catch(error => {
            rootStore.setApiError(error);
            return false;
        });
    },
    getUserById(id) {
        const rootStore = useRootStore();
        const userStore = useUserStore();

        return apiHandler.get(`user/${id}`).then(response => {
            if (apiHandler.isSuccess(response.status)) {
                userStore.setSelectedUser(response.data);
                return true;
            }
            return false;
        }).catch(error => {
            rootStore.setApiError(error);
            return false;
        });
    }
}