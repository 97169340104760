import {actions} from './actions';
import {getters} from './getters';
import {apiActions} from './apiActions';
import {defineStore} from "pinia";
import FeedbackTableFilter from "@/models/tables/FeedbackTableFilter";

export const state = () => ({
    
    /** @type {FeedbackTableFilter} */
    currentFeedbackFilter: FeedbackTableFilter.empty(),
    

    selectedFeedback: null,
    
    /**
     * The data returned from the API call, will contain feedback items
     * @type {PaginationData}
     * **/
    paginationDataResponse: null,
});

export const useFeedbackStore = defineStore('feedback', {
    state,
    getters,
    actions: {...actions, ...apiActions}
});
