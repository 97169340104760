import Feedback from "@/models/feedback/Feedback";
import FeedbackDetails from "@/models/feedback/FeedbackDetails";
import FeedbackTableFilter from "@/models/tables/FeedbackTableFilter";

export const actions = {
    setFeedbackList(data) {
        this.feedbackList = data.map(feedback => Feedback.parseDataFromObject(feedback));
    },
    setSelectedFeedback(data) {
        this.selectedFeedback = FeedbackDetails.parseDataFromObject(data);
    },
    setDataResponse(paginationDataResponse) {
        this.paginationDataResponse = paginationDataResponse;
    },
    updateTableFilter(data){
        this.currentFeedbackFilter = data;
    },
    updateSearchQuery(newQuery) {
        this.currentFeedbackFilter.searchQuery = newQuery;
    },
    updatePageNumber(newPageNumber){
        this.currentFeedbackFilter.pageNumber = newPageNumber;
    },
    setSortingOption(newSortingOption) {
        this.currentFeedbackFilter.sortingOption = newSortingOption;
    },
    clearFilters(){
        this.currentFeedbackFilter = FeedbackTableFilter.empty();
    }
    
}