<template>
  <!--  show the proper button state depending on: syncing, success, failure -->
  <DButton v-if="activeButton"
           button-type="button"
           :button-text="activeButton.buttonText"
           :icon="activeButton.icon"
           :class="['page-title-button', activeButton.class]"/>

  <DButton v-else button-text="Synchroniseer samenvatting" button-type="button"
           class="page-title-button" @click="onClickSync"/>
</template>

<script>
import DButton from "@/components/elements/DButton";
import {ref, watch} from "vue";
import {SYNC_BUTTON, SYNC_STATUS} from "@/models/Sync";

export default {
  name: "SyncButton",
  components: {DButton},
  props: {
    syncStatus: {
      required: true,
      type: String
    }
  },
  emits: ['on-click-sync'],
  setup(props, {emit}) {
    const activeButton = ref(null);

    watch(() => props.syncStatus, (newVal) => {
      if (props.syncStatus === SYNC_STATUS.FAILURE) {
        activeButton.value = SYNC_BUTTON.FAILURE;
      } else if (props.syncStatus === SYNC_STATUS.AUTO_SYNC) {
        onClickSync();
        return; // When it's in auto_sync state we don't want the button to return to default, because the call has to finish and it may take longer than 3s
      } else {
        activeButton.value = SYNC_BUTTON.SUCCESS;
      }
      setTimeout(() => activeButton.value = null, 3000); //Button should return to default after 3 seconds
    })

    function onClickSync() {
      activeButton.value = SYNC_BUTTON.SYNCING;
      emit('on-click-sync');
    }

    return {
      onClickSync,
      activeButton
    }
  }
}
</script>

<style scoped>

</style>