

export default class CommandTag {

  static hideChapterTag = "<hidden>";

  /**
   * The command of the tag
   * @type {string}
   */
  command;


  /**
   * The (optional) argument of the tag.
   * @type {string}
   */
  argument;

  /**
   * Structured element index start.
   * @type {number}
   */
  startIndex;

  /**
   * Structured element index end.
   */
  endIndex;

  constructor({command, argument, startIndex, endIndex}) {
    this.command = command;
    this.argument = argument;
    this.startIndex = startIndex;
    this.endIndex = endIndex;
  }


  /**
   * Tests if a string is a command tag.
   * @param {String} string
   */
  static getCommandTagIfExists(string) {
    const match = string.match(/^<[a-zA-Z]+>/g);
    return match ? match[0] : null;
  }

  /**
   * Tests if a string is a command tag.
   * @param {String} string
   */
  static testStringContainsEndCommandTag(string){
    return Array.from(string.matchAll(/<\/[a-zA-Z]+>/g));
  }

  static extractCommandNameFromTag(tag){
    return tag.replace(/[<>/]/g,'');
  }

}
