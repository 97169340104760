let lastClientWidthMeasurment;

export default class StructuralElement {


  /**
   * The start Index of the structural element.
   * @type {number}
   */
  startIndex;

  /**
   * The end Index of the structural element.
   * @type {number}
   */
  endIndex;

  /**
   * The paragraph of the structural element.
   * @type {Paragraph}
   */
  paragraph;

  /**
   * The section break of the structural element.
   * @type {SectionBreak}
   */
  sectionBreak;


  /**
   * The table of the structural element.
   * @type {Table}
   */
  table;

  /**
   * The table of contents of the structural element.
   * @type {TableOfContents}
   */
  tableOfContents;





  constructor({startIndex, endIndex, paragraph, sectionBreak, table, tableOfContents}) {
    this.startIndex = startIndex;
    this.endIndex = endIndex;
    this.paragraph = paragraph;
    this.sectionBreak = sectionBreak;
    this.table = table;
    this.tableOfContents = tableOfContents;
  }


  /**
   * Get the full text of the structural element.
   * @return {String}
   */
  get fullText() {
    return this.paragraph?.fullText ?? '';
  }


  //TODO make this much better.
  /**
   * Estimate the height of the element.
   * @param {number | null} clientWidth
   * @return {number}
   */
  estimateHeight(clientWidth,fullDocument) {
    /// This trick allows us to get the measurements at any time, that were last calulated,
    /// by giving no width value.
    if (clientWidth) {
      lastClientWidthMeasurment = clientWidth;
    } else {
      clientWidth = lastClientWidthMeasurment;
    }
    if (this.paragraph) {
      return this.paragraph.estimateHeight(clientWidth,fullDocument);
    } else if (this.tableOfContents) {
      return this.tableOfContents.estimateHeight(clientWidth,fullDocument);
    } else if (this.table) {
      return this.table.estimateHeight(clientWidth,fullDocument);
    }
    if (this.sectionBreak) {
      return 50;
    }
    return 0;
  }


}
