export default class Body {
  /**
   * The contents of the body.
   * @type {AnnotatedStructuralElement[]}
   */
  content = [];


  constructor({content}) {
    this.content = content;
  }


}
