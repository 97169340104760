<template>
  <div v-if="modelValue">
    <p class="label">{{ label }}</p>
    <textarea v-if="showTextArea ? adjustHeight(myTextArea) :null"
              ref="myTextArea"
              :readonly="isReadOnly"
              :value="modelValue"
              class="text-area"
              @input="(event) => $emit('update:modelValue', event.target.value)">
    </textarea>
  </div>
</template>

<script>
import {nextTick, ref} from "vue";

export default {
  name: "ReplyTextArea",
  props: {
    label: {
      required: false,
      type: String
    },
    showTextArea: {
      required: true,
      default: false,
      type: Boolean
    },
    modelValue: {
      required: false,
      type: String,
      default: ''
    },
    isReadOnly: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  setup() {
    const myTextArea = ref(null);

    function adjustHeight(element) {
      if (element) {
        nextTick(() => {
          element.style.height = element.scrollHeight + 10 + "px";
        })
      }
      return true;
    }

    return {
      adjustHeight,
      myTextArea
    }
  }
}
</script>

<style lang="scss" scoped>
.text-area {
  border: rem(1) solid #EAEFF2;
  border-radius: 10px;
  background-color: var(--grey-light-02);
  padding: rem(11) rem(24) rem(11) rem(16);

}

.label {
  color: var(--main-font-color);
  font-size: rem(14);
  letter-spacing: 0;
  line-height: rem(16);
  margin: rem(20) 0 rem(8) 0;
}
</style>