<template>
  <div class="details-wrapper">
    <PageTitle :title="selectedSummary?.title"
               button-text="Synchroniseer samenvatting"
               class="page-title"
               :sync-status="syncStatus"
               :sync-error-message="syncErrorMessage"
               is-sync-button
               has-more-actions
               has-return-button
               @on-click-btn="syncSummary"
               @perform-action="performAction"/>
    <SummaryDetailsInfo :summary="selectedSummary"/>
    <SummaryDetailModal :content="modalContent"
                        :is-visible="isModalVisible"
                        @on-click-btn="onClickModalBtn"
                        @close-modal="closeActiveModal"/>
    <SummaryChapterActions :summary-id="summaryId"/>
    <SummaryChapterList/>
  </div>
</template>

<script>
import PageTitle from "@/components/partials/summary/PageTitle";
import {useSummaryStore} from "@/store/summary";
import {useRoute, useRouter} from "vue-router";
import {computed, nextTick, onUnmounted, ref} from "vue";
import SummaryDetailsInfo from "@/components/partials/summary/SummaryDetailsInfo";
import {useRootStore} from "@/store";
import {ROUTE_NAMES_SUMMARY} from "@/router/modules/summary";
import {getModalHelpers} from "@/utils/helpers/ModalHelper";
import {MODAL_DETAILS} from "@/models/ModalNames";
import SummaryDetailModal from "@/components/partials/summary/modals/SummaryDetailModal";
import SummaryChapterActions from "@/components/partials/summary/SummaryChapterActions";
import {SYNC_STATUS} from "@/models/Sync";
import {ERROR_CODES} from "@/utils/handlers/errorHandler/errorCodes";
import SummaryChapterList from "@/components/partials/summary/SummaryChapterList";
import DButton from "@/components/elements/DButton";
import {useContentStore} from "@/reader/store/content";

export default {
  name: "SummaryDetailOverview",
  components: {
    DButton,
    SummaryChapterList,
    SummaryDetailModal,
    SummaryChapterActions,
    SummaryDetailsInfo,
    PageTitle
  },
  setup() {
    const summaryStore = useSummaryStore();
    const route = useRoute();
    const router = useRouter();
    const rootStore = useRootStore();
    const contentStore = useContentStore();

    /** summary **/
    const summaryId = route.params.summaryId;
    const selectedSummary = computed(() => summaryStore.getSelectedSummary);
    getSummaryDetails();

    function getSummaryDetails() {
      summaryStore.getSummaryDetails(summaryId);
    }

    /** Breadcrumbs **/
    setBreadCrumbData()

    function setBreadCrumbData() {
      rootStore.resetBreadcrumbName();
      rootStore.setBreadcrumbName(ROUTE_NAMES_SUMMARY.OVERVIEW);
    }

    /** Modals **/
    const {setActiveModal, closeActiveModal, isModalActive} = getModalHelpers();
    const isModalVisible = computed(() => {
      //Check which modal is active
      return isModalActive(MODAL_DETAILS.UNPUBLISH_SUMMARY.id) ||
          isModalActive(MODAL_DETAILS.PUBLISH_SUMMARY.id) ||
          isModalActive(MODAL_DETAILS.DELETE_SUMMARY.id)
    });
    const modalContent = ref();

    function onClickModalBtn() { //Depending on which modal is active perform action
      if (isModalActive(MODAL_DETAILS.DELETE_SUMMARY.id)) {
        deleteSummary();
      }
      if (isModalActive(MODAL_DETAILS.PUBLISH_SUMMARY.id) ||
          isModalActive(MODAL_DETAILS.UNPUBLISH_SUMMARY.id)) {
        handlePublish(!selectedSummary.value.isPublished)
      }
    }

    function openModal(modalInfo, modalName) {
      setActiveModal(modalName);
      modalContent.value = modalInfo;
    }

    /** Actions **/
    function performAction(action) {// On click dropdown item

      console.log("action", action);
      if (action?.modalName) {
        for (let modaldetailsKey in MODAL_DETAILS) {
          if (MODAL_DETAILS[modaldetailsKey].id === action.modalName) {
            openModal(MODAL_DETAILS[modaldetailsKey], action.modalName);
            return;
          }
        }
      }
      if (action?.isEdit) {
        router.push({name: ROUTE_NAMES_SUMMARY.VIEW, params: {summaryId: summaryId}})
      }

      if(action.id === 3){
        //download pdf
        const result = summaryStore.downloadPdf(summaryId).then((result) => {
          console.log("result", result);

          if(result.error){
            switch (result.error) {
              case "not_found_file":
                syncErrorMessage.value =  "PDF niet gevonden, probeer opnieuw te synchroniseren, anders is het Googledoc te groot."
                    break
              case "unkown_error":
              default:
                 syncErrorMessage.value = "Er is een onbekende fout opgetreden, probeer het opnieuw."
                    break;
            }
          }else{
            syncErrorMessage.value = null;
          }
        })



        //goToConvertPage();
      }

    }

    function deleteSummary() {
      summaryStore.deleteSummary(summaryId).then((response) => {
        if (response) {
          router.push({name: ROUTE_NAMES_SUMMARY.OVERVIEW})
        }
      })
    }

    function handlePublish(value) {
      selectedSummary.value.setIsPublished(value ? 1 : 0);
      summaryStore.editSummary(selectedSummary.value, summaryId).then((response) => {
        if (response) {
          closeActiveModal();
        }
      });
    }

    onUnmounted(() => {
      closeActiveModal();
      summaryStore.resetSelectedSummary();
    })

    /** Syncing **/
    const syncStatus = ref('');
    const syncErrorMessage = ref('');

    function syncSummary() {
      syncErrorMessage.value = '';
      summaryStore.syncSummary(route.params.summaryId).then((response) => {
        if (response) {
          syncStatus.value = SYNC_STATUS.SUCCESS;
          // Reset cached content as it changed due to the sync
          contentStore.resetData();
        }

        if(response.error === ERROR_CODES.SYNC_FAILED) {
          syncStatus.value = SYNC_STATUS.FAILURE;
          syncErrorMessage.value = response.error
        }
        
        if(response.error === ERROR_CODES.TOO_LARGE){
          syncStatus.value = SYNC_STATUS.FAILURE;
          syncErrorMessage.value = "Google Document is te groot om te exporteren."
        } else if(response.error === ERROR_CODES.NOT_FOUND) {
          syncStatus.value = SYNC_STATUS.FAILURE;
          syncErrorMessage.value = "Samenvatting niet gevonden."
        } else if(response.error === ERROR_CODES.INVALID_RIGHTS) {
          syncStatus.value = SYNC_STATUS.FAILURE;
          syncErrorMessage.value = "Onvoldoende rechten."
        } else if(response.error === ERROR_CODES.FOLDER_NOT_SUPPORTED) {
          syncStatus.value = SYNC_STATUS.FAILURE;
          syncErrorMessage.value = "Map gevonden i.p.v. samenvatting."
        } else if(response.error){
          syncStatus.value = SYNC_STATUS.FAILURE;
          syncErrorMessage.value = "onbekende fout.";
        }
        getSummaryDetails();
      })
    }

    autoSyncOnCreate();

    function autoSyncOnCreate() {
      if (router.options.history.state.back === '/summary/create') {
        nextTick(() => {
          syncStatus.value = SYNC_STATUS.AUTO_SYNC;
        })
      }
    }


    function goToConvertPage(){
      // TODO: enable when converting is ready for tables and images
      router.push({name: ROUTE_NAMES_SUMMARY.CONVERT, params: {summaryId: summaryId}});
    }

    return {
      /** Selected summary **/
      selectedSummary,
      summaryId,
      goToConvertPage,

      /** Modal **/
      onClickModalBtn,
      isModalVisible,
      modalContent,
      closeActiveModal,

      /** Dropdown actions **/
      deleteSummary,
      performAction,
      syncSummary,

      syncStatus,
      syncErrorMessage
    }
  }
}
</script>

<style lang="scss" scoped>
.details-wrapper {
  padding-right: rem(22);
  width: 100%;
  position: relative;

  .page-title {
    margin-top: rem(50);
  }

  .wrapper {
    margin-bottom: rem(16);
  }

  .details-info-wrapper {
    margin-top: rem(43);

    .details-info-title {
      margin: 0;
      font-size: rem(20);
      color: var(--main-font-color);
      font-weight: bold;
      letter-spacing: 0;
      line-height: rem(25);
    }

    .details-title-text {
      display: flex;
      gap: rem(34);
      margin-top: rem(20);

      .detail-names {
        color: var(--main-font-color);
        font-size: rem(16);
        font-weight: 600;
        letter-spacing: 0;
        line-height: rem(20);
        margin: 0;
        min-width: rem(134);
      }

      .detail-values {
        color: var(--main-font-color);
        font-size: rem(16);
        letter-spacing: 0;
        line-height: rem(20);
        margin: 0;
        display: flex;
        align-items: center;

        .img {
          margin-right: rem(8);
        }
      }
    }

    .details-title-text:nth-child(5) {
      .detail-values {
        color: var(--orange-main);
        font-size: rem(16);
        font-weight: bold;
        letter-spacing: 0;
        line-height: rem(19);
        text-decoration: underline;
      }
    }
  }
}

</style>
