<template>
  <CreateOrEditQuestionList @create="createQuestionList" is-create title="Nieuwe vragenlijst"/>
</template>

<script>
import CreateOrEditQuestionList from "@/views/manager/summary/question/CreateOrEditQuestionList";
import {useQuestionStore} from "@/store/question";
import {useRoute, useRouter} from "vue-router";
import {ROUTE_NAMES_SUMMARY} from "@/router/modules/summary";
import {useRootStore} from "@/store";

export default {
  name: "CreateQuestionList",
  components: {
    CreateOrEditQuestionList
  },
  setup() {
    const questionStore = useQuestionStore();
    const rootStore = useRootStore();
    const route = useRoute();
    const router = useRouter();
    const summaryId = route.params.summaryId;

    function createQuestionList(payload) {
      console.log("pushing up");
      questionStore.createQuestionList(payload, summaryId).then((response) => {
        if (response) {
          router.push({name: ROUTE_NAMES_SUMMARY.QUESTION_LIST, params: {summaryId: summaryId}});
        }
      });
    }

    /** Breadcrumbs **/
    setBreadCrumbs();

    function setBreadCrumbs() {
      rootStore.setBreadcrumbParams({summaryId: summaryId});
      rootStore.setBreadcrumbName(ROUTE_NAMES_SUMMARY.QUESTION_LIST);
    }

    return {
      createQuestionList
    }
  }
}
</script>

<style scoped lang="scss">

</style>