import InvalidCredentials from './errorList/InvalidCredentials'
import Default from './errorList/Default'
import {ERROR_CODES} from './errorCodes'
import InvalidGDocsUrl from "@/utils/handlers/errorHandler/errorList/InvalidGDocsUrl";
import ConflictProductCode from "@/utils/handlers/errorHandler/errorList/ConflictProductCode";
import ConflictEmail from "@/utils/handlers/errorHandler/errorList/ConflictEmail";
import FailedSummaryFetch from "@/utils/handlers/errorHandler/errorList/FailedSummaryFetch";

const DEFAULT_LANG = 'nl';

const errors = {
  [ERROR_CODES.INVALID_CREDENTIALS]: new InvalidCredentials(),
  [ERROR_CODES.DEFAULT]: new Default(),
  [ERROR_CODES.INVALID_GDOCS_LINK]: new InvalidGDocsUrl(),
  [ERROR_CODES.CONFLICT_PRODUCT_CODE]: new ConflictProductCode(),
  [ERROR_CODES.CONFLICT_EMAIL_IN_USE]: new ConflictEmail(),
  [ERROR_CODES.SUMMARY_FETCH_FAILED]: new FailedSummaryFetch()
}

export function isErrorActive(errorCode, value) {
  return errors[errorCode].getMessage() === value;
}

class ErrorHandler {
  constructor() {
    this.selectedLanguage = DEFAULT_LANG;
  }

  setLanguage(lang = DEFAULT_LANG) {
    this.selectedLanguage = lang;
  }

  getError(errorObj) {
    if(!errorObj || !errorObj.error){
      return errors['default'].getMessage();
    }
    const { error } = errorObj;
    return errors[error] ? errors[error].getMessage() : errors['default'].getMessage();
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new ErrorHandler();
    }
    return this.instance;
  }
}

export default ErrorHandler.getInstance();
