<template>
  <div class="toast-manager">
    <TransitionGroup name="toast" tag="div">
      <Toast v-for="toast in ToastHandler.toasts" :key="toast.id" :toast="toast"/>
    </TransitionGroup>
  </div>
</template>

<script>
import ToastHandler from "@/utils/handlers/toastHandler/ToastHandler";
import Toast from "@/components/toast/Toast";

export default {
  name: "ToastManager",
  components: {
    Toast
  },
  setup() {
    return {
      ToastHandler,
    }
  }
}
</script>

<style scoped lang="scss">
.toast-manager {
  @include position-elevation(fixed, $right: 0, $bottom: 0, $elevation: z('toast'));
  display: flex;
  flex-direction: column;
  padding: rem(40) rem(40) rem(40) 0;
}

/** Toast animations **/
.toast-enter-active, .toast-leave-active {
  transition: all 0.4s;
}

.toast-enter, .toast-leave-to {
  opacity: 0;
  transform: translateY(100%);
}
</style>
