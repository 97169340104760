<template>
  <CreateOrEdit is-create button-text="Voeg samenvatting toe" title="Nieuwe samenvatting" @create-summary="create"/>
</template>

<script>
import CreateOrEdit from "@/components/partials/summary/CreateOrEdit";
import {useSummaryStore} from "@/store/summary";
import {ROUTE_NAMES_SUMMARY} from "@/router/modules/summary";
import {useRouter} from "vue-router";

export default {
  name: "CreateSummary",
  components: {CreateOrEdit},
  setup() {
    const summaryStore = useSummaryStore();
    const router = useRouter();

    function create(payload) {
      summaryStore.createSummary(payload).then((response) => {
        if (response) {
          router.push({name: ROUTE_NAMES_SUMMARY.DETAILS, params: {summaryId: response.id}});
        }
      })
    }

    return {
      create
    }
  }
}
</script>

<style scoped>

</style>