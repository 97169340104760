/** bacheloryears years **/
export const bachelorYearsAndPeriods = [
    {
        id: 1,
    },
    {
        id: 2,
    },
    {
        id: 3,
    },
    {
        id: 4,
    },
];

/** Statuses **/
export const statuses = [
    {
        id: 0,
        name: 'Niet gepubliceerd'
    },
    {
        id: 1,
        name: 'Gepubliceerd'
    }
]

export default class Summary {
    constructor({id, productCode, title, bachelorYearNumber, availableDate, isPublished}) {
        this.id = id;
        this.productCode = productCode;
        this.title = title;
        this.bachelorYearNumber = bachelorYearNumber;
        this.availableDate = availableDate;
        this.isPublished = isPublished;
    }

    static parseDataFromObject(data) {
        return new Summary(data);
    }

    get getIsPublished() {
        return this.isPublished === 1;
    }
}
