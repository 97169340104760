<template>
  <div v-if="selectedFeedback" class="wrapper">
    <div class="radio-button-wrapper">

      <div v-if="selectedFeedback.isFeedbackRepliedTo" class="input-text-outer-wrapper">
        <p class="title replied-title">Antwoord</p>
        <p class="status-text">{{ statusText }}</p>
        <ReplyTextArea v-if="selectedFeedback.isFeedbackRepliedTo && selectedFeedback.replyText"
                       :is-read-only="true"
                       :model-value="selectedFeedback.replyText"
                       :show-text-area="selectedFeedback.isFeedbackRepliedTo"
                       class="replied-text-area"
                       label="Bericht"/>
        <p v-else class="closed-feedback">
          <i class="closed-feedback-info">Afgesloten op {{formatDateIntoDMYHM(selectedFeedback.closedAt)}} door</i>
          <i class="closed-feedback-email">{{ selectedFeedback.closedByUser.email }}</i>
          
        </p>
      </div>

      <div v-if="!selectedFeedback.isFeedbackRepliedTo" class="input-text-outer-wrapper">
        <p class="title">Verstuur antwoord</p>

        <div v-for="option in info" :key="option.id" class="input-text-wrapper">
          <label :for="option.id"
                 class="check-label-round label-radio" @click="onClickRadioBtn(option)">
            <input :id="option.value"
                   :checked="isCheckboxChecked(option.id)"
                   :name="option.value"
                   :value="option.value"
                   class="input-radio"
                   type="radio">
            <span class="checkmark"></span>
            <span class="option-text">{{ option.text }}</span>
          </label>

          <div v-if="isCheckboxChecked(option.id) && option.textArea" class="text-area-wrapper">
            <ReplyTextArea :model-value="option.textArea"
                           :show-text-area="isCheckboxChecked(option.id)"
                           label="Bericht"
                           @update:modelValue="option.textArea = $event"/>
          </div>
        </div>
        <DButton v-if="isButtonVisible"
                 :button-text="checkedOption === 4 ? 'Bevestig' : 'Verstuur bericht'"
                 button-type="button"
                 class="send-msg-btn"
                 @click="submitMessage()"/>
      </div>

    </div>
  </div>
</template>

<script>
import {computed, ref, watch} from "vue";
import {getReplyMessage} from "@/utils/helpers/replyToFeedbackMsg";
import DButton from "@/components/elements/DButton";
import ReplyTextArea from "@/components/partials/feedback/ReplyTextArea";
import {formatDateIntoDMYHM} from "@/utils/helpers/Date";

export default {
  name: "ReplyToFeedback",
  components: {ReplyTextArea, DButton},
  props: {
    feedback: {
      required: true,
    }
  },
  emits: ['reply-message'],
  setup(props, {emit}) {
    const selectedFeedback = ref();
    const checkedOption = ref(null);
    const message = ref('');
    const statusText = ref('');
    const isButtonVisible = ref(false);

    watch(() => props.feedback, (newVal) => {
      selectedFeedback.value = newVal;
      if (selectedFeedback.value.isFeedbackRepliedTo) {
        statusText.value = info.value.map(option => option.text)[selectedFeedback.value.status - 1];
      }
    }, {deep: true})

    const info = computed(() => [
      {
        id: 1,
        text: 'Feedback wel verwerkt',
        textArea: getReplyMessage(selectedFeedback.value, true, false)
      },
      {
        id: 2,
        text: 'Feedback niet verwerkt',
        textArea: getReplyMessage(selectedFeedback.value, false, true)
      },
      {
        id: 3,
        text: 'Anders',
        textArea: getReplyMessage(selectedFeedback.value, false, false)
      },
      {
        id: 4,
        text: 'Afgesloten zonder klant te berichten',
        textArea: ''
      }
    
    ])

    function isCheckboxChecked(id) {
      return checkedOption.value === id;
    }

    function onClickRadioBtn(option) {
      if (isCheckboxChecked(option.id)){
        checkedOption.value = null;
        isButtonVisible.value = false;
        return;
      }
      checkedOption.value = option.id;
      isButtonVisible.value = true;
    }

    function submitMessage() {
      info.value.forEach(option => {
        if (isCheckboxChecked(option.id)) {
          emit('reply-message', {status: option.id, replyText: option.textArea});
        }
      })
    }

    return {
      info,
      onClickRadioBtn,
      checkedOption,
      submitMessage,
      isCheckboxChecked,
      message,
      selectedFeedback,
      statusText,
      isButtonVisible,
      formatDateIntoDMYHM
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {

  .input-text-outer-wrapper {
    width: rem(460);

    .replied-text-area {
      margin-bottom: rem(40);
    }

    .closed-feedback{
      font-size: rem(14);
      margin-top: rem(24);

      .closed-feedback-info {
        margin-right: rem(3);
        text-decoration: underline;
      }

      .closed-feedback-email {
        color: blue;
        text-decoration: underline;
      }
    }
  }

  .radio-button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .status-text {
      color: var(--main-font-color);
      font-size: rem(16);
      letter-spacing: 0;
      line-height: rem(20);
      margin: rem(10) 0 0 0;
    }

    .title {
      color: var(--main-font-color);
      font-size: rem(20);
      font-weight: bold;
      letter-spacing: 0;
      line-height: rem(25);
      margin: rem(20) 0 rem(20) 0;

      &.replied-title {
        margin-bottom: 0;
      }
    }

    .input-text-wrapper {
      margin-bottom: rem(20);

      .option-text {
        color: var(--main-font-color);
        font-size: rem(16);
        letter-spacing: 0;
        line-height: rem(20);
      }
    }

    .text-area {
      border: rem(1) solid #EAEFF2;
      border-radius: 10px;
      background-color: var(--grey-light-02);
      padding: rem(11) rem(24) rem(11) rem(16);

      &.replied-text-area {
        margin-bottom: rem(40);
      }
    }

    .label {
      color: var(--main-font-color);
      font-size: rem(14);
      letter-spacing: 0;
      line-height: rem(16);
      margin: rem(20) 0 rem(8) 0;

      &.repliedTo {
        margin: rem(24) 0 rem(8) 0;
      }
    }

    .send-msg-btn {
      margin: rem(20) 0 rem(40) 0;
    }
  }
}
</style>