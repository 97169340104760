<template>
  <CreateOrEditQuestionList v-if="selectedQuestionList"
                            @edit="editQuestionList"
                            :selected-question-list="selectedQuestionList"
                            title="Vragenlijst wijzigen"/>
</template>

<script>
import {useQuestionStore} from "@/store/question";
import {useRoute, useRouter} from "vue-router";
import {computed, onUnmounted} from "vue";
import CreateOrEditQuestionList from "@/views/manager/summary/question/CreateOrEditQuestionList";
import {ROUTE_NAMES_SUMMARY} from "@/router/modules/summary";
import {useRootStore} from "@/store";

export default {
  name: "EditQuestionList",
  components: {CreateOrEditQuestionList},
  setup() {
    const questionStore = useQuestionStore();
    const rootStore = useRootStore();
    const route = useRoute();
    const router = useRouter();
    const questionListId = route.params.questionListId;

    /** Get selected question list **/
    const selectedQuestionList = computed(() => questionStore.getSelectedQuestionList);
    getQuestionList();

    function getQuestionList() {
      questionStore.getQuestionListById(questionListId).then((response) => {
        if (response) {
          setBreadCrumbs();
        }
      });
    }

    /** Edit question list **/
    function editQuestionList(payload) {
      questionStore.editQuestionList(payload, questionListId).then((response) => {
        if (response){
          router.push({name: ROUTE_NAMES_SUMMARY.QUESTION_LIST, params: {summaryId: selectedQuestionList.value.summaryId}});
        }
      });
    }

    /** Breadcrumbs **/
    function setBreadCrumbs() {
      rootStore.setBreadcrumbName(ROUTE_NAMES_SUMMARY.QUESTION_LIST);
      rootStore.setBreadcrumbParams({summaryId: selectedQuestionList.value.summaryId});
    }

    onUnmounted(() => {
      questionStore.resetSelectedQuestionList();
    })

    return {
      selectedQuestionList,
      editQuestionList
    }
  }
}
</script>

<style scoped lang="scss">

</style>