<template>
  <div v-if="selectedSummary" class="question-list-wrapper">
    <PageTitle @on-click-btn="navigateToCreate"
               :title="selectedSummary.title"
               has-return-button
               has-button
               button-text="Maak nieuwe vragenlijst"/>

    <p class="title">Vragenlijsten beheren</p>
    <div v-for="question in listOfQuestionsList" :key="question.id" class="list-question-lists-wrapper">
      <p class="question-title">{{question.title}}</p>
      <img src="../../../../assets/icons/ic_edit.svg" class="icon" alt="icon" @click="navigateToEdit(question.id)">
      <img src="../../../../assets/icons/ic_delete.svg" class="icon" alt="icon" @click="openModal(question)">
    </div>

    <DeleteQuestionListModal @close="closeActiveModal"
                            @delete="deleteQuestionList"
                            :selected-question-list="selectedQuestionList"
                            :is-visible="isModalVisible"/>
  </div>
</template>

<script>
import {computed, ref} from "vue";
import {useSummaryStore} from "@/store/summary";
import {useRoute, useRouter} from "vue-router";
import PageTitle from "@/components/partials/summary/PageTitle";
import {useQuestionStore} from "@/store/question";
import DeleteQuestionListModal from "@/components/partials/summary/modals/DeleteQuestionListModal";
import {getModalHelpers} from "@/utils/helpers/ModalHelper";
import {MODAL_DETAILS} from "@/models/ModalNames";
import {ROUTE_NAMES_SUMMARY} from "@/router/modules/summary";
import {useRootStore} from "@/store";

export default {
  name: "SummaryQuestionListOverview",
  components: {DeleteQuestionListModal, PageTitle},
  setup() {
    const route = useRoute();
    const summaryId = route.params.summaryId;
    const summaryStore = useSummaryStore();
    const selectedQuestionList = ref();
    const questionStore = useQuestionStore();
    const rootStore = useRootStore();
    const router = useRouter();

    /** summary **/
    const selectedSummary = computed(() => summaryStore.getSelectedSummary);
    getSummaryDetails();

    function getSummaryDetails() {
      summaryStore.getSummaryDetails(summaryId);
    }

    /** Question list **/
    const listOfQuestionsList = computed(() => questionStore.getListOfQuestionsList)
    fetchListOfQuestionsList();

    function fetchListOfQuestionsList() {
      questionStore.fetchListOfQuestionsList(summaryId);
    }

    /** Modal **/
    const {setActiveModal, closeActiveModal, isModalActive} = getModalHelpers();
    const isModalVisible = computed(() => isModalActive(MODAL_DETAILS.DELETE_QUESTION_LIST.id));

    function openModal(questionList) {
      selectedQuestionList.value = questionList;
      setActiveModal(MODAL_DETAILS.DELETE_QUESTION_LIST.id);
    }

    /** Delete **/
    function deleteQuestionList(list){
      questionStore.deleteQuestionList(list.id).then((response) => {
        if (response){
          router.push({name: ROUTE_NAMES_SUMMARY.DETAILS, params: {summaryId: summaryId}})
        }
      })
    }

    /** Breadcrumbs **/
    setBreadCrumbs();

    function setBreadCrumbs() {
      rootStore.setBreadcrumbParams({summaryId: summaryId});
      rootStore.setBreadcrumbName(ROUTE_NAMES_SUMMARY.DETAILS);
    }

    function navigateToCreate(){
      router.push({name: ROUTE_NAMES_SUMMARY.QUESTION_LIST_CREATE, params: {summaryId: summaryId}});
    }

    function navigateToEdit(id){
      router.push({name: ROUTE_NAMES_SUMMARY.QUESTION_LIST_EDIT, params: {questionListId: id}});
    }

    return {
      selectedSummary,
      listOfQuestionsList,
      deleteQuestionList,
      openModal,
      isModalVisible,
      closeActiveModal,
      selectedQuestionList,
      navigateToCreate,
      navigateToEdit
    }
  }
}
</script>

<style scoped lang="scss">
.question-list-wrapper {
  margin-top: rem(54);

  .title {
    color: var(--main-font-color);
    font-size: rem(20);
    font-weight: bold;
    letter-spacing: 0;
    line-height: rem(25);
    margin: rem(43) 0 rem(21) 0;
  }

  .list-question-lists-wrapper{
    display: flex;
    align-items: center;
    padding: 0 rem(12);
    height: rem(42);
    border-radius: rem(6);
    background-color: var(--white-main);
    box-shadow: 0 2px 15px -3px var(--white-box-shadow);

    &:not(:last-of-type) {
      margin-bottom: rem(12);
    }

    .question-title{
      color: var(--main-font-color);
      flex-grow: 1;
      font-size: rem(14);
      font-weight: 500;
      line-height: rem(20);
      margin: 0;
    }

    .icon {
      @include hover-active-pointer;
      width: rem(24);
      height: rem(24);
      margin-left: rem(9);
    }
  }
}
</style>