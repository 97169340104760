import ManagerGuard from'@/router/guards/manager'
import FeedbackDetailOverview from "@/views/manager/feedback/FeedbackDetailOverview";

const ManagerLayout = () => import('../../views/manager/Layout');
const Overview = () => import('../../views/manager/feedback/FeedbackOverview');


export const ROUTE_NAMES_FEEDBACK = Object.freeze({
    OVERVIEW: 'FeedbackOverview',
    DETAILS: 'FeedbackDetails'
});

export const feedback = {
    path: '/feedback',
    meta: {
        guard: ManagerGuard
    },
    component: ManagerLayout,
    children: [
        {
            path: 'overview',
            name: ROUTE_NAMES_FEEDBACK.OVERVIEW,
            component: Overview
        },
        {
            path: 'details/:feedbackId',
            name: ROUTE_NAMES_FEEDBACK.DETAILS,
            component: FeedbackDetailOverview
        }
    ]
}