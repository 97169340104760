export const SYNC_BUTTON = Object.freeze({
    SYNCING: {
        class: 'sync-btn',
        buttonText: 'Synchroniseren...',
        icon: require('@/assets/icons/ic_sync.svg')
    },
    SUCCESS: {
        class: 'success-sync',
        buttonText: 'Gesynchroniseerd!',
        icon: require('@/assets/icons/ic_check_only.svg')
    },
    FAILURE: {
        class: 'fail-sync',
        buttonText: 'Synchronisatie mislukt',
        icon: require('@/assets/icons/ic_sync_fail.svg')
    },
});

export const SYNC_STATUS = Object.freeze({
    SUCCESS: 'Success',
    FAILURE: 'Failure',
    AUTO_SYNC: 'Auto Sync'
})