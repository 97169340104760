import SummaryChapter from "@/models/summary/SummaryChapter";

export default class SummaryChapterCluster extends SummaryChapter {
    constructor({id, gDocsChapterId, isFree, isLocked, isSupplement, parentChapterId, sortIndex, subChapters, summaryId, title}) {
        super({
            id,
            gDocsChapterId,
            isFree,
            isLocked,
            isSupplement,
            parentChapterId,
            sortIndex,
            subChapters,
            summaryId,
            title
        });
        this.isCluster = true;
    }

    static parseDataFromObject(data) {
        return new SummaryChapterCluster(data);
    }
}
